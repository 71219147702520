import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import logo from "assets/img/THC_Logo_Compact.png";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://totalhealthcheck.org/">
        THC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#62c5c4"
  },
  logoImage: {
    margin: theme.spacing(1)
  },
  img: {
    maxHeight: "150px"
  },
  errorMessage: {
    textAlign: "center",
    color: "#ff0033",
    width: "max-content"
  }
});

const title = {
  textAlign: "center",
  fontSize: "2em",
  padding: "0px",
  margin: "0px"
};
const subtitle = {
  textAlign: "center",
  fontSize: "1.5em",
  color: "#777",
  padding: "0px",
  margin: "0px"
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        gender: "Male",
        gender_other: "",
        height: 0,
        weight: 0
      },
      org: null,
      error: false
    };
  }

  handleChange = event => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  handleDate = date => {
    const { user } = this.state;
    user.date_of_birth = date;
    this.setState({ user });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { user, org } = this.state;

    user.account_type = "Player";
    user.organisation_id = org._id;

    if (user.gender_other !== "") {
      user.gender = user.gender_other;
    }
    delete user.gender_other;

    axios
      .post(
        "/api/users/register",
        { user: user },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(() => {
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("expiration", -1);
        localStorage.setItem("userID", "");
        this.props.history.push("/signup-confirmation");
      })
      .catch(error => {
        console.log(error.response);
        this.setState({ error: error.response.data.error });
      });
  };

  getOrgByInvite(invite) {
    axios
      .get(`/api/organisations/getByInvite/${invite}`)
      .then(response => {
        console.log(response);
        this.setState({ org: response.data, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getOrgByInvite(params.invite);
  }

  renderGenderInput() {
    const { user } = this.state;
    let inputs = [
      <Grid item xs={12} key="selector">
        <SelectValidator
          autoComplete="gender"
          name="gender"
          variant="outlined"
          required
          fullWidth
          id="gender"
          label="Gender"
          onChange={this.handleChange}
          value={user.gender}
          validators={["required"]}
          errorMessages={["This field is required"]}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Other">Other - Please specify</MenuItem>
          <MenuItem value="None">Prefer not to say</MenuItem>
        </SelectValidator>
      </Grid>
    ];
    if (user.gender === "Other") {
      inputs.push(
        <Grid item xs={12} key="other">
          <TextValidator
            variant="outlined"
            required
            fullWidth
            id="gender_other"
            label="Gender"
            name="gender_other"
            onChange={this.handleChange}
            value={user.gender_other}
            validators={["required"]}
            errorMessages={["This field is required"]}
          />
        </Grid>
      );
    }
    return inputs;
  }

  render() {
    const { classes } = this.props;
    const { error, isLoading, user, org } = this.state;

    if (!isLoading && !org) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    let errorMessage = "";
    if (error) {
      errorMessage = (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          {error}
        </Typography>
      );
    }

    return (
      <React.Fragment>
        <div>
          {!isLoading ? (
            <Container component="main" maxWidth="xs">
              <Helmet>
                <title>Sign Up | TotalHealthCheck</title>
              </Helmet>
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.logoImage}>
                  <img src={logo} alt="logo" className={classes.img} />
                </div>
                <h2 style={title} className={classes.cardTitleBlack}>
                  Sign Up
                </h2>
                <h4 style={subtitle} className={classes.cardTitleBlack}>
                  Organisation: {org.name}
                </h4>
                {errorMessage}
                <ValidatorForm
                  ref={ref => (this.form = ref)}
                  className={classes.form}
                  onSubmit={this.handleSubmit}
                  onError={errors => console.log(errors)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        autoComplete="fname"
                        name="first_name"
                        variant="outlined"
                        required
                        fullWidth
                        id="first_name"
                        label="First Name"
                        autoFocus
                        onChange={this.handleChange}
                        value={user.first_name}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        variant="outlined"
                        required
                        fullWidth
                        id="last_name"
                        label="Last Name"
                        name="last_name"
                        autoComplete="lname"
                        onChange={this.handleChange}
                        value={user.last_name}
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={this.handleChange}
                        value={user.email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "This field is required",
                          "Invalid email address"
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextValidator
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={this.handleChange}
                        value={user.password}
                        validators={["required", "minStringLength:3"]}
                        errorMessages={[
                          "This field is required",
                          "Must be more than 3 characters"
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Divider light />
                  <br></br>
                  <Grid container spacing={2}>
                    {this.renderGenderInput()}
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={12}>
                        <KeyboardDatePicker
                          value={user.date_of_birth}
                          onChange={this.handleDate}
                          label="Date of Birth"
                          format="dd/MM/yyyy"
                          inputVariant="outlined"
                          fullWidth
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    {/* <Grid item xs={12} sm={6}>
                      <TextValidator
                        autoComplete="height"
                        name="height"
                        variant="outlined"
                        required
                        fullWidth
                        id="height"
                        label="Height (cm)"
                        onChange={this.handleChange}
                        value={user.height}
                        validators={[
                          "required",
                          "matchRegexp:^[0-9]*$",
                          "minNumber:0",
                          "maxNumber:999"
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Invalid number",
                          "Invalid number",
                          "Invalid number"
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextValidator
                        autoComplete="weight"
                        name="weight"
                        variant="outlined"
                        required
                        fullWidth
                        id="weight"
                        label="Weight (kg)"
                        onChange={this.handleChange}
                        value={user.weight}
                        validators={[
                          "required",
                          "matchRegexp:^[0-9]*$",
                          "minNumber:0",
                          "maxNumber:999"
                        ]}
                        errorMessages={[
                          "This field is required",
                          "Invalid number",
                          "Invalid number",
                          "Invalid number"
                        ]}
                      />
                    </Grid> */}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign Up
                  </Button>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Link href="login" variant="body2">
                        Already have an account? Log in
                      </Link>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </div>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(withRouter(SignUp));
