import React from "react";
import axios from "axios";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { bloodPressureChart } from "variables/charts.js";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const metricCardTextAlign = {
  // margin: '40px',
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricCardMargin = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      isLoading: false,
      user: [],
      metricName: params.metricName,
      bloodPressureSYS: [],
      bloodPressureDYS: [],
      metricValuesDates: [],
      latestHealthCheck: null,
      rating: params.rating,
      subRating: params.subRating,
      error: null
    };
    this.getUser();
    this.getMetricBloodPressureDYSData();
    this.getMetricBloodPressureSYSData();
    this.getGraphMonthData("blood_pressure_diastolic");
    this.getLatestHealthCheckData(params.metricName);
  }

  getLatestHealthCheckData() {
    var userID = localStorage.getItem("userID");
    axios
      .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
      .then(response => {
        this.setState({
          latestHealthCheck: response.data,
          isLoading: false
        });
        console.log(response);
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getUser() {
    var userID = localStorage.getItem("userID");
    axios
      .get(`/api/users/getbyid/${userID}`)
      .then(response => {
        this.setState({
          user: response.data
        });
        console.log(response);
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getGraphMonthData(metricName) {
    var userID = localStorage.getItem("userID");

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/${metricName}`)
      .then(response => {
        console.log(response);
        this.setState({
          metricValuesDates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }
  
  getMetricBloodPressureSYSData() {
    var userID = localStorage.getItem("userID");
    const { 
      bloodPressureSYS,
      bloodPressureDYS
    } = this.state;

    axios
      .get(
        `/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_systolic`
      )
      .then(response => {
        console.log(response);
        this.setState({
          bloodPressureSYS: response.data.metricValues,
          isLoading: false
        });

        this.getMetricResultText(this.getRating(bloodPressureSYS, bloodPressureDYS), this.getBloodPressureSubRating());

      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMetricBloodPressureDYSData() {
    var userID = localStorage.getItem("userID");

    
    axios
      .get(
        `/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_diastolic`
      )
      .then(response => {
        console.log(response);
        this.setState({
          bloodPressureDYS: response.data.metricValues,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMetricRatingLowercase(colour) {
    var metricNameMap = {
      success: "good",
      warning: "average",
      danger: "poor"
    };
    return metricNameMap[colour];
  }
  
  getRating(){
    
    const { bloodPressureSYS, bloodPressureDYS } = this.state;

    var rating = "";
    console.log(rating);

    var valueSYS = bloodPressureSYS[0];
    var valueDYS = bloodPressureDYS[0];

    var ratingValue = this.getMetricRatingLowercase(
      this.getBloodPressureCardColour(valueSYS, valueDYS)
    );
    console.log(
      this.getBloodPressureCardColour(valueSYS, valueDYS)
    );


    // this.setState({
    //   rating: ratingValue
    // });

    
    var rating = ratingValue;
    console.log(rating);
    return rating;
  }
  
  getMetricResultText(rating, subRating){

    console.log(subRating);
    console.log(rating);

    if (subRating === null || subRating == undefined) {

      axios.get(`/api/metricTexts/get/blood_pressure/${rating}`)
      .then(response => {
        console.log(response);
        this.setState({
          metricResultTextFirstPara: response.data.metricText.firstPara,
          metricResultTextSecondPara: response.data.metricText.secondPara,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    } else {

      axios.get(`/api/metricTexts/getWithSubRating/blood_pressure/${rating}/${subRating}`)
      .then(response => {
        console.log(response);

        this.setState({
          metricResultTextFirstPara: response.data.metricText.firstPara,
          metricResultTextSecondPara: response.data.metricText.secondPara,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
    }
  }

  getBloodPressureSubRating() {
    var high = "high";
    var low = "low";

    const { 
      bloodPressureSYS,
      bloodPressureDYS
    } = this.state;

    var valueSYS = bloodPressureSYS[0];
    var valueDYS = bloodPressureDYS[0];

    var subRatingValue = "";

    if (70 <= valueSYS && valueSYS <= 90 && 40 <= valueDYS && valueDYS <= 60) {
      var subRatingValue = "low";
    } else if (140 <= valueSYS && valueSYS <= 200 && 90 <= valueDYS && valueDYS <= 120) {
      var subRatingValue = "high";
    } else {
      return null;
    }

    console.log(subRatingValue);

    var subRating = subRatingValue;
    console.log(subRating);

    return subRating;
  }

  getBloodPressureCardColour(valueSYS, valueDYS) {
    var green = "success";
    var red = "danger";

    if (
      (10 <= valueSYS && valueSYS < 90 && 10 <= valueDYS && valueDYS < 60) ||
      (120 < valueSYS && valueSYS <= 200 && 80 < valueDYS && valueDYS <= 200)
    ) {
      return red;
    } else {
      return green;
    }
  }

  getMetricRating(valueSYS, valueDYS) {
    var good = "GOOD";
    var poor = "POOR";

    if (
      (10 <= valueSYS && valueSYS < 90 && 10 <= valueDYS && valueDYS < 60) ||
      (120 < valueSYS && valueSYS <= 200 && 80 < valueDYS && valueDYS <= 200)
    ) {
      return poor;
    } else {
      return good;
    }
  }

  getBloodPressureResultRating(valueSYS, valueDYS) {
    var green = "Good";
    var red = "Poor";

    if (
      (10 <= valueSYS && valueSYS < 90 && 10 <= valueDYS && valueDYS < 60) ||
      (120 < valueSYS && valueSYS <= 200 && 80 < valueDYS && valueDYS <= 200)
    ) {
      return red;
    } else {
      return green;
    }
  }


  componentDidMount() {
    let userID = localStorage.getItem("userID");
    this.getUser(userID);
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      user,
      error,
      metricResultTextFirstPara,
      metricResultTextSecondPara,
      bloodPressureSYS,
      bloodPressureDYS,
      latestHealthCheck,
      metricName,
      metricValuesDates
    } = this.state;

    console.log(latestHealthCheck)

    let latestValue = null;
    let latestHealthCheckDate = null;
    let latestHealthCheckDateShort = null;

    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    if (latestHealthCheck) {
      latestValue = latestHealthCheck[metricName];
      latestHealthCheckDate = latestHealthCheck["date"];
      latestHealthCheckDateShort = new Date(latestHealthCheckDate).toLocaleDateString(
        "en-US",
        options
      );
    } 

    console.log(latestHealthCheckDateShort);

    function compare( a, b ){
      if (a.date > b.date ){
        return -1;
      }
      if (a.date < b.date ){
        return 1;
      }
      return 0;
    }

    

    var metricValuesDatesSorted = metricValuesDates.sort( compare );
    var bloodPressureSYSValuesSorted = bloodPressureSYS.sort( compare );
    var bloodPressureDYSValuesSorted = bloodPressureDYS.sort( compare );

    var metricValuesDatesSorted6 = [
      metricValuesDatesSorted[metricValuesDatesSorted.length - 6],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 5],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 4],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 3],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 2],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 1]
    ];


    console.log(metricValuesDates);
    console.log();
    console.log();



    let data = {
      labels: metricValuesDatesSorted6,
      series: [bloodPressureSYSValuesSorted, bloodPressureDYSValuesSorted]
    };

    console.log(bloodPressureSYS[0]);

    return (
      <React.Fragment>
        <h4>
          Hi {user.first_name}, your last check was on {latestHealthCheckDateShort}.
        </h4>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div key={user.email}>
              <GridContainer>
                <GridItem xs={12}>
                  <Card
                    color={this.getBloodPressureCardColour(
                      bloodPressureSYS[0],
                      bloodPressureDYS[0]
                    )}
                  >
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      Blood Pressure
                    </h4>
                    <h4 style={metricCardMargin}>
                    Current result: {bloodPressureSYS[0]}/
                      {bloodPressureDYS[0]} mmHg
                    </h4>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      {this.getMetricRating(
                        bloodPressureSYS[0],
                        bloodPressureDYS[0]
                      )}
                    </h4>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <Card chart>
                    <CardHeader color="info">
                      <ChartistGraph
                        className="ct-chart"
                        data={data}
                        type="Line"
                        options={bloodPressureChart.options}
                        listener={bloodPressureChart.animation}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Previous Blood Pressure Results
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <Card>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        What your result means
                      </h4>
                      <p className={classes.description}>
                        {metricResultTextFirstPara}
                        <div>
                          <br></br>
                        </div>
                        {metricResultTextSecondPara}
                      </p>
                      {/* <Button color="primary" round>
                        Learn more
                      </Button> */}
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);
