import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardBody from "components/Card/CardBody.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const title = {
  textAlign: "center",
  fontSize: "2em"
};

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://totalhealthcheck.org/">
        THC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class Verification extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      isLoading: false
    };
    this.verificationID = params.verification;
    console.log(this.verificationID);

  }

  getUser() {
    axios
      .get(`/api/users/getByVerification/${this.verificationID}`)
      .then(response => {
        this.setState({ isLoading: false });
        console.log(response.data[0]._id);
        this.setUserVerifiedStatus(response.data[0]);
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
  }


  setUserVerifiedStatus(player) {
    let user = {};
    user.id = player._id;
    user.status = "active";
    axios
      .put(
        "/api/users/update",
        { user: user },
        { headers: { "Content-Type": "application/json" } }
      )
      .then
      // do nothing
      ()
      .catch(function(error) {
        console.log(error.response);
      });
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth="xs">
        <Helmet>
          <title>Verification | TotalHealthCheck</title>
        </Helmet>
        <CssBaseline />
        <Box mt={5}>
        <GridContainer>
        <GridItem xs={12}>
            <Card>
              <CardBody>
                <h4 style={title} className={classes.cardTitleBlack}>
                  Thank you! Your account is now verified.
                </h4>
              </CardBody>
            </Card>
        </GridItem>
            <GridItem xs={12} >
              <Link to={"/login"}>
                <Card
                  // color={getBloodGlucoseCardColour(healthcheck.blood_glucose)}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Click here to Log in
                  </h4>
                  
                </Card>
              </Link>
            </GridItem>
          </GridContainer>
          
          <Copyright />
        </Box>

        

      </Container>
      
      
          

    );
  }
}

Verification.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  withRouter(Verification)
);
