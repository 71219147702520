import React from "react";
import useEffect from "react";
import axios from "axios";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  heartRateChart,
  hundredMeterSprintChart,
  bleepTestChart,
  airwayPeakFlowChart,
  oxygenLevelChart,
  reactionTimeChart,
  bloodGlucoseChart,
  bodyMassIndexChart,
  cholesterolLevelChart,
  bodyTemperatureChart,
  footPostureIndexChart,
  verticalJumpChart,
  sleepQualityChart,
  stressLevelChart,
  alcoholIntakeChart,
  smokingLevelChart,
  dietQualityChart
} from "variables/charts.js";

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricCardMargin = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      isLoading: false,
      user: [],
      metricValues: [],
      metricValuesTwo: [],
      metricValuesDates: [],
      metricName: params.metricName,
      latestHealthCheck: null,
      rating: params.rating,
      subRating: params.subRating,
      error: null
    };
    this.getMetricResultColour = this.getMetricResultColourFunction(
      params.metricName
    );

    this.getGraphMonthData(params.metricName);
    this.getMetricData(params.metricName);
    this.getLatestHealthCheckData(params.metricName);
    this.getRating(params.metricName);
    this.getSubRating(params.metricName, params.metricValues);

  }

  getUser() {
    var userID = localStorage.getItem("userID");
    axios
      .get(`/api/users/getbyid/${userID}`)
      .then(response => {
        this.setState({
          user: response.data
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getGraphMonthData(metricName) {
    var userID = localStorage.getItem("userID");

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/${metricName}`)
      .then(response => {
        this.setState({
          metricValuesDates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMetricData(metricName) {
    var userID = localStorage.getItem("userID");

    console.log(metricName)

    if (metricName == "beep_test") {
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/${metricName}`)
      .then(response => {
        this.setState({
          metricValues: response.data.metricValues
        });

        this.getMetricResultText(metricName, this.getRating(metricName), this.getSubRating(metricName));
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test_decimal`)
      .then(response => {
        this.setState({
          metricValuesTwo: response.data.metricValues
        });

        this.getMetricResultText(metricName, this.getRating(metricName), this.getSubRating(metricName));
      })
      .catch(error => this.setState({ error, isLoading: false }));
    
    } else if (metricName == "bloodpressure") {


      console.log(userID)
      console.log(metricName)

      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/${metricName}`)
      .then(response => {
        this.setState({
          metricValues: response.data.metricValues
        });

        console.log(response.data)

        this.getMetricResultText(metricName, this.getRating(metricName), this.getSubRating(metricName));
      })
      .catch(error => this.setState({ error, isLoading: false }));

    } else {


      console.log(userID)
      console.log(metricName)

      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/${metricName}`)
      .then(response => {
        this.setState({
          metricValues: response.data.metricValues
        });

        console.log(response.data)

        this.getMetricResultText(metricName, this.getRating(metricName), this.getSubRating(metricName));
      })
      .catch(error => this.setState({ error, isLoading: false }));
    }

    
  }

  getLatestHealthCheckData() {
    var userID = localStorage.getItem("userID");
    axios
      .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
      .then(response => {
        this.setState({
          latestHealthCheck: response.data,
          isLoading: false
        });
        console.log(response);
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMetricRatingLowercase(colour) {
    var metricNameMap = {
      success: "good",
      warning: "average",
      danger: "poor"
    };
    return metricNameMap[colour];
  }
  
  getRating(metricName){
    var rating = "";

    var ratingValue = this.getMetricRatingLowercase(
      this.getMetricResultColourFunction(metricName)
    );
    
    var rating = ratingValue;
    return rating;
  }

  getSubRating(metricName){

    var metricValues = this.state;

    var subRating = "";

    var latestMetricValue = metricValues.metricValues[0];
    
    var subRatingValue = "";
  
      if (metricName == "blood_glucose") {
        var subRatingValue = this.getBloodGlucoseSubRating(latestMetricValue);
      } else if (metricName == "heart_rate"){
        var subRatingValue = this.getHeartRateSubRating(latestMetricValue);
      } else if (metricName == "body_mass_index"){
        var subRatingValue = this.getBodyMassIndexSubRating(latestMetricValue);
      } else if (metricName == "body_temperature"){
        var subRatingValue = this.getBodyTemperatureSubRating(latestMetricValue);
      } else {
        return null;
      }
      

    var subRating = subRatingValue;
    return subRating;

  }

  getMetricResultText(metricName, rating, subRating){

    if (subRating === null || subRating == undefined) {

      axios.get(`/api/metricTexts/get/${metricName}/${rating}`)
      .then(response => {
        this.setState({
          metricResultTextFirstPara: response.data.metricText.firstPara,
          metricResultTextSecondPara: response.data.metricText.secondPara,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

    } else {

      axios.get(`/api/metricTexts/getWithSubRating/${metricName}/${rating}/${subRating}`)
      .then(response => {
        this.setState({
          metricResultTextFirstPara: response.data.metricText.firstPara,
          metricResultTextSecondPara: response.data.metricText.secondPara,          isLoading: false
        });
        console.log(response.data);
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
    }
  }

  getMetricResultColourFunction(metricName) {
    var metricValues = this.state;
    var metricFuncMap = {
      blood_glucose: this.getBloodGlucoseCardColour(metricValues.metricValues[0]),
      oxygen_level: this.getOxygenLevelCardColour(metricValues.metricValues[0]),
      body_mass_index: this.getBodyMassIndexCardColour(metricValues.metricValues[0]),
      airway_peak_flow: this.getAirwayPeakFlowColour(metricValues.metricValues[0]),
      cholesterol_level: this.getCholesterolLevelCardColour(metricValues.metricValues[0]),
      body_temperature: this.getBodyTemperatureCardColour(metricValues.metricValues[0]),
      foot_posture_index: this.getFootPostureIndexCardColour(metricValues.metricValues[0]),
      heart_rate: this.getHeartRateCardColour(metricValues.metricValues[0]),
      beep_test: this.getBeepTestColour(metricValues.metricValues[0]),
      vertical_jump: this.getVerticalJumpColour(metricValues.metricValues[0]),
      hundred_meter_sprint: this.getHundredMeterSprintColour(metricValues.metricValues[0]),
      reaction_time: this.getReactionTimeColour(metricValues.metricValues[0]),
      sleep_quality: this.getSleepQualityColour(metricValues.metricValues[0]),
      stress_level: this.getStressLevelColour(metricValues.metricValues[0]),
      alcohol_intake: this.getAlcoholIntakeColour(metricValues.metricValues[0]),
      smoking_level: this.getSmokingLevelColour(metricValues.metricValues[0]),
      diet_quality: this.getDietQualityColour(metricValues.metricValues[0])
    };
    return metricFuncMap[metricName];
  }

  getCurrentResultValue(metricName, metricValues){

    console.log(metricName);

    if (metricName == "smoking_level"){

      console.log("Smoking page")
      if (metricValues[0] == 0) {
        return 'Non Smoker'
      } else if (metricValues[0] == 1){
        return 'Smoker'
      }

    } else {
      return metricValues[0]
      console.log("Non Smoking page")

    }
    // return metricValues[0]
  }

  getMetricName(metricName) {
    var metricNameMap = {
      blood_glucose: "Blood Glucose",
      oxygen_level: "Oxygen Level",
      body_mass_index: "Body Mass Index",
      airway_peak_flow: "Airway peak flow",
      cholesterol_level: "Cholesterol Level",
      body_temperature: "Body Temperature",
      foot_posture_index: "Foot Posture Index",
      heart_rate: "Heart Rate",
      beep_test: "Beep Test",
      vertical_jump: "Vertical Jump",
      hundred_meter_sprint: "100m Sprint",
      reaction_time: "Reaction Time",
      sleep_quality: "Sleep Quality",
      stress_level: "Stress Level",
      alcohol_intake: "Alcohol Intake",
      smoking_level: "Smoking Level",
      diet_quality: "Diet Quality"
    };
    return metricNameMap[metricName];
  }

  getMetricChartOptions(metricName) {
    var metricNameMap = {
      blood_glucose: bloodGlucoseChart.options,
      oxygen_level: oxygenLevelChart.options,
      body_mass_index: bodyMassIndexChart.options,
      airway_peak_flow: airwayPeakFlowChart.options,
      cholesterol_level: cholesterolLevelChart.options,
      body_temperature: bodyTemperatureChart.options,
      foot_posture_index: footPostureIndexChart.options,
      heart_rate: heartRateChart.options,
      beep_test: bleepTestChart.options,
      vertical_jump: verticalJumpChart.options,
      hundred_meter_sprint: hundredMeterSprintChart.options,
      reaction_time: reactionTimeChart.options,
      sleep_quality: sleepQualityChart.options,
      stress_level: stressLevelChart.options,
      alcohol_intake: alcoholIntakeChart.options,
      smoking_level: smokingLevelChart.options,
      diet_quality: dietQualityChart.options
    };
    return metricNameMap[metricName];
  }

  getMetricUnit(metricName) {
    var metricNameMap = {
      blood_glucose: "mmol/L",
      oxygen_level: "mmHg",
      body_mass_index: "kg/m2",
      airway_peak_flow: "L/min",
      cholesterol_level: "mmol/L",
      body_temperature: "°C",
      foot_posture_index: "",
      heart_rate: "bpm",
      beep_test: "",
      vertical_jump: "cm",
      hundred_meter_sprint: "seconds",
      reaction_time: "seconds",
      sleep_quality: "hours",
      stress_level: "/10",
      alcohol_intake: "units per week",
      smoking_level: "",
      diet_quality: "/10"
    };
    return metricNameMap[metricName];
  }

  getBloodGlucoseCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if ((2.6 <= value && value < 4.3) || (8.1 <= value && value <= 21.1)) {
      return red;
    } else if (6.3 <= value && value < 8.1) {
      return orange;
    } else {
      return green;
    }
  }
  
  getBloodGlucoseSubRating(value) {
    var high = "high";
    var low = "low";

    if (2.6 <= value && value < 4.3) {
      return low;
    } else if (8.1 <= value && value <= 21.1) {
      return high;
    } else {
      return null;
    }
  }

  getHeartRateCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if ((20 <= value && value <= 40) || (75 <= value && value <= 120)) {
      return red;
    } else if (60 <= value && value < 75) {
      return orange;
    } else {
      return green;
    }
  }

  getHeartRateSubRating(value) {
    var high = "high";
    var low = "low";

    if (20 <= value && value <= 40) {
      return low;
    } else if (75 <= value && value <= 120) {
      return high;
    } else {
      return null;
    }
  }
  
  getOxygenLevelCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (value < 95) {
      return red;
    } else if (93 <= value && value < 94) {
      return orange;
    } else {
      return green;
    }
  }

  getFootPostureIndexCardColour(value) {
    var green = "success";
    var red = "danger";

    if ((-5 < value && value < 0) || (6 <= value && value < 10)) {
      return red;
    } else {
      return green;
    }
  }

  getCholesterolLevelCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (5 < value && value <= 10) {
      return red;
    } else if (5 <= value && value < 6.5) {
      return orange;
    } else {
      return green;
    }
  }

  getBodyMassIndexCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if ((10 <= value && value <= 18.5) || (25 <= value && value <= 200)) {
      return red;
    } else if (18.5 < value && value < 25) {
      return green;
    } else {
      return orange;
    }
  }

  getBodyMassIndexSubRating(value) {
    var high = "high";
    var low = "low";

    if (0 <= value && value <= 18.5) {
      return low;
    } else if (25 <= value && value <= 50) {
      return high;
    } else {
      return null;
    }
  }
  
  getBodyTemperatureCardColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (37.5 < value || value < 36.5) {
      return red;
    } else {
      return green;
    }
  }

  getBodyTemperatureSubRating(value) {
    var high = "high";
    var low = "low";

    if (value < 36) {
      return low;
    } else if (38 < value) {
      return high;
    } else {
      return null;
    }
  }
  
  getAirwayPeakFlowColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (value < 400) {
      return red;
    } else if (450 <= value && value < 650) {
      return orange;
    } else {
      return green;
    }
  }

  getBeepTestColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (1 <= value && value <= 7) {
      return red;
    } else if (7 < value && value <= 11) {
      return orange;
    } else {
      return green;
    }
  }

  getVerticalJumpColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (1 <= value && value <= 12) {
      return red;
    } else if (12 < value && value <= 24) {
      return orange;
    } else {
      return green;
    }
  }

  getHundredMeterSprintColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (14 <= value && value <= 20) {
      return red;
    } else if (12 <= value && value < 14) {
      return orange;
    } else {
      return green;
    }
  }

  getReactionTimeColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (value > 0.2) {
      return red;
    } else if (0.14 < value && value <= 0.2) {
      return orange;
    } else {
      return green;
    }
  }

  getAlcoholIntakeColour(value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";
    
    if (value > 14) {
      return red;
    } else if (0.1 <= value && value <= 14) {
      return orange;
    } else {
      return green;
    }
  }
  
  getSleepQualityColour(value) {
    var green = "success";
    var red = "danger";
    
    if (value >= 6) {
      return green;
    } else {
      return red;
    }
  }
  
  getDietQualityColour(value) {
    var green = "success";
    var red = "danger";
    
    if (value <= 5) {
      return red;
    } else {
      return green;
    }
  }
  
  getSmokingLevelColour(value) {
    var green = "success";
    var red = "danger";
    
    if (value == 1) {
      return red;
    } else {
      return green;
    }
  }
  
  getStressLevelColour(value) {
    var green = "success";
    var red = "danger";
    
    if (value >= 5) {
      return red;
    } else {
      return green;
    }
  }

  getMetricRating(colour) {
    var metricNameMap = {
      success: "GOOD",
      warning: "AVERAGE",
      danger: "POOR"
    };
    return metricNameMap[colour];
  }

  componentDidMount() {
    let userID = localStorage.getItem("userID");
    this.getUser(userID);
    window.scrollTo(0, 0);
    
  }

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      user,
      error,
      latestHealthCheck,
      metricValues,
      metricValuesTwo,
      metricValuesDates,
      metricName,
      metricResultTextFirstPara,
      metricResultTextSecondPara
    } = this.state;

    console.log(metricResultTextFirstPara);
    console.log(metricResultTextSecondPara);

    var d = new Date();

    function compare( a, b ){
      if (a.date > b.date ){
        return -1;
      }
      if (a.date < b.date ){
        return 1;
      }
      return 0;
    }


    // function compare( a, b ){
    //   console.log("a: " + a);
    //   console.log("b: " + b);
    //   console.log(b != null);
      
    //   if (a != null && b != null){
    //     if (a.date > b.date ){
    //       return -1;
    //     }
    //     if (a.date < b.date ){
    //       return 1;
    //     }
    //     return 0;  
    //   }
    // }

    var metricValuesDatesSorted = metricValuesDates.sort( compare );
    var metricValuesSorted = metricValues.sort( compare );

    var metricValuesDatesSorted6 = [
      metricValuesDatesSorted[metricValuesDatesSorted.length - 6],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 5],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 4],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 3],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 2],
      metricValuesDatesSorted[metricValuesDatesSorted.length - 1]
    ];

    let data = {
      labels: metricValuesDatesSorted6,
      series: [metricValuesSorted]
    };

    if (metricValuesTwo[0] != undefined){
      var metricValuesTwoWithDecimalPoint = "." + metricValuesTwo[0];
    } else {
      var metricValuesTwoWithDecimalPoint = "";
    }
    
    let latestValue = null;
    let latestHealthCheckDate = null;
    let latestHealthCheckDateShort = null;

    console.log(latestHealthCheck)
     
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    if (latestHealthCheck) {
      latestValue = latestHealthCheck[metricName];
      latestHealthCheckDate = latestHealthCheck["date"];
      latestHealthCheckDateShort = new Date(latestHealthCheckDate).toLocaleDateString(
        "en-US",
        options
      );
    } 

    // var scrollToTopOfPage = window.scrollTo(0, 0);

    console.log(metricResultTextFirstPara);
    console.log(metricResultTextSecondPara);


    return (
      <React.Fragment>
        <h4>
          Hi {user.first_name}, your last check was on {latestHealthCheckDateShort}.
        </h4>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div key={user.email}>
              {/* {scrollToTopOfPage} */}
              <GridContainer>
                <GridItem xs={12}>
                  <Card color={this.getMetricResultColourFunction(metricName)}>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      {this.getMetricName(metricName)}
                    </h4>
                    <h4 style={metricCardMargin}>
                      Current result: {this.getCurrentResultValue(metricName, metricValues)}{metricValuesTwoWithDecimalPoint} {this.getMetricUnit(metricName)}
                    </h4>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      {this.getMetricRating(
                        this.getMetricResultColourFunction(metricName)
                      )}
                    </h4>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <Card chart>
                    <CardHeader color="info">
                      <ChartistGraph
                        className="ct-chart"
                        data={data}
                        type="Line"
                        options={this.getMetricChartOptions(metricName)}
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        Previous {this.getMetricName(metricName)} Results
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} sm={12}>
                  <Card>
                    <CardBody>
                      <h4 className={classes.cardTitle}>
                        What your result means
                      </h4>
                      <p className={classes.description}>
                        {metricResultTextFirstPara}
                        <div>
                          <br></br>
                        </div>
                        {metricResultTextSecondPara}
                      </p>
                      {/* <Button color="primary" round>
                        Learn more
                      </Button> */}
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);
