import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import qs from "qs";
// @material-ui/core components
import { InputAdornment, Icon, withStyles } from "@material-ui/core";
// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Link, withRouter } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { stringifyDate } from "libs/dates"

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  eye: {
    cursor: "pointer"
  },
  logoImage: {
    margin: theme.spacing(1)
  },
  img: {
    maxHeight: "150px"
  },
  errorMessage: {
    textAlign: "center",
    color: "#ff0033"
  },
  successMessage: {
    textAlign: "center",
    color: "#00E676"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
});
const title = {
  textAlign: "center",
  fontSize: "2em"
};

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      password: {},
      isLoading: true,
      error: null,
      currPassMasked: true,
      newPassMasked: true,
      unauthorised: false,
      success: false
    };
  }

  handleChange = event => {
    const { password } = this.state;
    password[event.target.name] = event.target.value;
    this.setState({ password });
  };

  getUser(userID) {
    axios
      .get(`/api/users/getByID/${userID}`)
      .then(response => {
        let user = response.data;
        user.date_of_birth = stringifyDate(new Date(response.data.date_of_birth));
        this.setState({ user: response.data, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    let userID = localStorage.getItem("userID");
    this.getUser(userID);
  }

  toggleCurrPassMask = () => {
    this.setState(prevState => ({
      currPassMasked: !prevState.currPassMasked
    }));
  };

  toggleNewPassMask = () => {
    this.setState(prevState => ({
      newPassMasked: !prevState.newPassMasked
    }));
  };

  getCurrEyeIcon = () => {
    let icon = "visibility";
    if (this.state.currPassMasked) {
      icon = icon + "_off";
    }
    return icon;
  };

  getNewEyeIcon = () => {
    let icon = "visibility";
    if (this.state.newPassMasked) {
      icon = icon + "_off";
    }
    return icon;
  };

  handleSubmit = event => {
    const { user } = this.state;
    event.preventDefault();
    const data = this.state.password;
    data.id = user._id;

    axios({
      method: "post",
      url: "/api/users/changepassword",
      data: qs.stringify(data),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    })
      .then(response => {
        console.log(response);
        this.setState({ success: true });
      })
      .catch(error => {
        console.log(error.response);
        if (error.response.status === 401) {
          this.setState({ unauthorised: true });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      password,
      user,
      currPassMasked,
      newPassMasked,
      success,
      unauthorised
    } = this.state;

    let errorMessage = "";
    if (unauthorised) {
      errorMessage = (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          You have entered an invalid current password.<br></br>Please try
          again.
        </Typography>
      );
    }

    let successMessage = "";
    if (success) {
      successMessage = (
        <div>
          <Typography
            className={classes.successMessage}
            component="h1"
            variant="subtitle1"
          >
            Password successfully changed.
          </Typography>
          <br></br>
        </div>
      );
    }

    return (
      <div>
        {!isLoading ? (
          <GridContainer>
            <GridItem xs={12}>
              <h4 style={title} className={classes.cardTitleBlack}>
                Account Settings
              </h4>
            </GridItem>
            <GridItem xs={12}>
              <Card center>
                <CardBody profile>
                    <h3 className={classes.cardTitle}>User Profile</h3>
                    <h4 className={classes.cardTitle}>
                      Name: {user.first_name + " " + user.last_name}
                      <br></br>
                      Email: {user.email}
                      <br></br>
                      D.O.B: {user.date_of_birth}
                      <br></br>
                      Gender: {user.gender}
                    </h4>
                    {/* <Link to={"/dashboard/delete"}>
                      <Button color="danger">Delete Account</Button>
                    </Link> */}

                  <br></br><br></br>
                  <Divider light />

                  <h3 className={classes.cardTitle}>Change Password</h3>
                  <ValidatorForm
                    ref={ref => (this.form = ref)}
                    className={classes.form}
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {errorMessage}
                        {successMessage}
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          required
                          name="current_password"
                          label="Current Password"
                          type={currPassMasked ? "password" : "text"}
                          id="current_password"
                          autoComplete="current-password"
                          onChange={this.handleChange}
                          value={password.current_password || ""}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon
                                  className={classes.eye}
                                  onClick={this.toggleCurrPassMask}
                                >
                                  {this.getCurrEyeIcon()}
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextValidator
                          variant="outlined"
                          required
                          name="new_password"
                          label="New Password"
                          type={newPassMasked ? "password" : "text"}
                          id="new_password"
                          autoComplete="new-password"
                          onChange={this.handleChange}
                          value={password.new_password || ""}
                          validators={["required"]}
                          errorMessages={["This field is required"]}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon
                                  className={classes.eye}
                                  onClick={this.toggleNewPassMask}
                                >
                                  {this.getNewEyeIcon()}
                                </Icon>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                    <br></br>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Submit
                    </Button>
                  </ValidatorForm>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(UserProfile));
