import React from "react";
import axios from "axios";

// @material-ui/icons
import Divider from "@material-ui/core/Divider";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {
  getBloodGlucoseCardColour,
  getBloodPressureCardColour,
  getHeartRateCardColour,
  getOxygenLevelCardColour,
  getFootPostureIndexCardColour,
  getCholesterolLevelCardColour,
  getBodyMassIndexCardColour,
  getBodyTemperatureCardColour,
  getAirwayPeakFlowColour,
  getBeepTestColour,
  getVerticalJumpColour,
  getHundredMeterSprintColour,
  getReactionTimeColour,
  getAlcoholIntakeColour,
  getSleepQualityColour,
  getDietQualityColour,
  getSmokingLevelColour,
  getStressLevelColour
} from "libs/healthchecks.js";
import { Link } from "react-router-dom";


const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricCardMargin = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricThresholds = {
  bloodGlucose: [0, 10, 20],
  heartRate: [60, 80, 100]
};

const title = {
  textAlign: "center",
  fontSize: "2em"
};

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      healthcheck: null,
      error: null,
      blood_pressure_sys_values: [],
      blood_pressure_dys_values: [],
      blood_glucose_values: [],
      oxygen_level_values: [],
      body_mass_index_values: [],
      airway_peak_flow_values: [],
      cholesterol_level_values: [],
      body_temperature_values: [],
      heart_rate_values: [],
      beep_test_values: [],
      beep_test_decimal_values: [],
      vertical_jump_values: [],
      hundred_meter_sprint_values: [],
      reaction_time_values: [],
      sleep_quality_values: [],
      stress_level_values: [],
      alcohol_intake_values: [],
      smoking_level_values: [],
      diet_quality_values: []
    };
  }

  getMetricData() {
    const { healthcheck, user } = this.state;

    let userID = localStorage.getItem("userID");

    console.log(userID)
    
      // blood_pressure_systolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_systolic`)
      .then(response => {
        this.setState({
          blood_pressure_sys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_pressure_diastolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_diastolic`)
      .then(response => {
        this.setState({
          blood_pressure_dys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_glucose //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_glucose`)
      .then(response => {
        this.setState({
          blood_glucose_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
    
      // oxygen_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/oxygen_level`)
      .then(response => {
        this.setState({
          oxygen_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_mass_index //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_mass_index`)
      .then(response => {
        this.setState({
          body_mass_index_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // airway_peak_flow //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/airway_peak_flow`)
      .then(response => {
        this.setState({
          airway_peak_flow_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // cholesterol_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/cholesterol_level`)
      .then(response => {
        this.setState({
          cholesterol_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_temperature //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_temperature`)
      .then(response => {
        this.setState({
          body_temperature_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // heart_rate //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/heart_rate`)
      .then(response => {
        this.setState({
          heart_rate_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test`)
      .then(response => {
        this.setState({
          beep_test_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test_decimal //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test_decimal`)
      .then(response => {
        this.setState({
          beep_test_decimal_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // vertical_jump //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/vertical_jump`)
      .then(response => {
        this.setState({
          vertical_jump_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // hundred_meter_sprint //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/hundred_meter_sprint`)
      .then(response => {
        this.setState({
          hundred_meter_sprint_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // reaction_time //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/reaction_time`)
      .then(response => {
        this.setState({
          reaction_time_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // sleep_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/sleep_quality`)
      .then(response => {
        this.setState({
          sleep_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // stress_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/stress_level`)
      .then(response => {
        this.setState({
          stress_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // alcohol_intake //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/alcohol_intake`)
      .then(response => {
        this.setState({
          alcohol_intake_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // smoking_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/smoking_level`)
      .then(response => {
        this.setState({
          smoking_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // diet_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/diet_quality`)
      .then(response => {
        this.setState({
          diet_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getHealthCheckByID(id) {
    axios
      .get(`/api/healthchecks/get/${id}`)
      .then(response => {
        console.log(response.data.healthcheck);
        this.setState({
          healthcheck: response.data.healthcheck,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getHealthCheckByID(params.id);
    this.getMetricData();
  }

  getMetricCardColour(metric, value) {
    var green = "success";
    var orange = "warning";
    var red = "danger";

    if (
      metricThresholds[metric][0] < value &&
      value <= metricThresholds[metric][1]
    ) {
      return red;
    } else if (
      metricThresholds[metric][1] < value &&
      value <= metricThresholds[metric][2]
    ) {
      return orange;
    } else {
      return green;
    }
  }

  allAreNull(arr) {
    return arr.every(element => element === null);
  }

  healthTitle(){
    const { classes } = this.props;
    let { healthcheck } = this.state;

    var latest_blood_pressure_sys_value = healthcheck.blood_pressure_systolic;
    var latest_blood_pressure_dys_value = healthcheck.blood_pressure_diastolic;
    var latest_blood_glucose_value = healthcheck.blood_glucose;
    var latest_oxygen_level_value = healthcheck.oxygen_level;
    var latest_body_mass_index_value = healthcheck.body_mass_index;
    var latest_airway_peak_flow_value = healthcheck.airway_peak_flow;
    var latest_cholesterol_level_value = healthcheck.cholesterol_level;
    var latest_body_temperature_value = healthcheck.body_temperature;
    var latest_heart_rate_value = healthcheck.heart_rate;



    if (!(latest_blood_pressure_sys_value == undefined && 
      latest_blood_pressure_dys_value == undefined &&
      latest_blood_glucose_value == undefined && 
      latest_oxygen_level_value == undefined &&
      latest_body_mass_index_value == undefined && 
      latest_airway_peak_flow_value == undefined &&
      latest_cholesterol_level_value == undefined && 
      latest_body_temperature_value == undefined && 
      latest_heart_rate_value == undefined)){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Health
          </h4>
        </GridItem>


      )
    }
  }

  fitnessTitle(){
    const { classes } = this.props;
    let { healthcheck,
      beep_test_values,
      vertical_jump_values,
      hundred_meter_sprint_values,
      reaction_time_values
    } = this.state;

    // var latest_beep_test_value = beep_test_values.find(el => el !== null);
    // var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    // var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    // var latest_reaction_time_value = reaction_time_values.find(el => el !== null);

    var latest_beep_test_value = healthcheck.beep_test;
    var latest_beep_test_decimal_value = healthcheck.beep_test_decimal;
    var latest_vertical_jump_value = healthcheck.vertical_jump;
    var latest_hundred_meter_sprint_value = healthcheck.hundred_meter_sprint;
    var latest_reaction_time_value = healthcheck.reaction_time;
    
    if (!(
      latest_beep_test_value == undefined && 
      latest_vertical_jump_value == undefined &&
      latest_hundred_meter_sprint_value == undefined && 
      latest_reaction_time_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Fitness
          </h4>
        </GridItem>


      )
    }
  }

  lifestyleTitle(){
    const { classes } = this.props;
    let { healthcheck,
      sleep_quality_values,
      stress_level_values,
      alcohol_intake_values,
      smoking_level_values,
      diet_quality_values
    } = this.state;

    // var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    // var latest_stress_level_value = stress_level_values.find(el => el !== null);
    // var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    // var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    // var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    var latest_sleep_quality_value = healthcheck.sleep_quality;
    var latest_stress_level_value = healthcheck.stress_level;
    var latest_alcohol_intake_value = healthcheck.alcohol_intake;
    var latest_smoking_level_value = healthcheck.smoking_level;
    var latest_diet_quality_value = healthcheck.diet_quality;


    if (!(
      latest_sleep_quality_value == undefined && 
      latest_stress_level_value == undefined &&
      latest_alcohol_intake_value == undefined && 
      latest_smoking_level_value == undefined && 
      latest_diet_quality_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Lifestyle
          </h4>
        </GridItem>


      )
    }
  }

  moreHealthTitle(){
    const { classes } = this.props;
    let { healthcheck } = this.state;

    var latest_notes_value = healthcheck.notes;
    var latest_neuro_assessment_value = healthcheck.neuro_assessment;
    var latest_biomech_assessment_value = healthcheck.biomech_assessment;
    var latest_ultrasound_results_value = healthcheck.ultrasound_results;
    var latest_diet_assessment_value = healthcheck.diet_assessment;



    if (!(

      latest_notes_value == undefined && 
      latest_neuro_assessment_value == undefined &&
      latest_biomech_assessment_value == undefined && 
      latest_ultrasound_results_value == undefined &&
      latest_diet_assessment_value == undefined

      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Health
          </h4>
        </GridItem>


      )
    }
  }

  createMoreHealthCard(name, value){
    const { classes } = this.props;
    const { healthcheck, user } = this.state;

    console.log(value)
    console.log(value !== (undefined + "/" + undefined))

    if (value && value !== (undefined + "/" + undefined) && value !== (undefined + "." + undefined)){

      return (
          // <GridItem xs={12} sm={3} >
          //   <Link to={link}>
          //     <Card
          //       color={color}
          //     >
          //       <h4
          //         style={metricCardTextAlign}
          //         className={classes.cardTitleBlack}
          //       >
          //         {name}
          //       </h4>
          //       <h4 style={metricCardMargin}>
          //         {value} {unit}
          //       </h4>
          //     </Card>
          //   </Link>
          // </GridItem>
          <GridItem xs={12}>
          <Card>
            <CardBody>
              <h4 className={classes.cardTitle}>{name}</h4>
              <p className={classes.cardCategory}>
              {value}
              </p>
            </CardBody>
          </Card>
        </GridItem>

        );
    }
  }

  createCard(name, link, color, value, unit ){
    const { classes } = this.props;
    const { healthcheck, user } = this.state;

    console.log(value)
    console.log(value !== (undefined + "/" + undefined))

    if (value && value !== (undefined + "/" + undefined) && value !== (undefined + "." + undefined)){

      return (
          <GridItem xs={12} sm={3} >
            <Link to={link}>
              <Card
                color={color}
              >
                <h4
                  style={metricCardTextAlign}
                  className={classes.cardTitleBlack}
                >
                  {name}
                </h4>
                <h4 style={metricCardMargin}>
                  {value} {unit}
                </h4>
              </Card>
            </Link>
          </GridItem>
        );
    }
  }

  render() {
    const { classes } = this.props;
    const { isLoading, healthcheck, user, error } = this.state;
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    let { blood_pressure_sys_values,
      blood_pressure_dys_values,
      blood_glucose_values,
      oxygen_level_values,
      body_mass_index_values,
      airway_peak_flow_values,
      cholesterol_level_values,
      body_temperature_values,
      heart_rate_values,
      beep_test_values,
      beep_test_decimal_values,
      vertical_jump_values,
      hundred_meter_sprint_values,
      reaction_time_values,
      sleep_quality_values,
      stress_level_values,
      alcohol_intake_values,
      smoking_level_values,
      diet_quality_values,
    } = this.state;

    const smoking_level_valuesConverted = [];

    if (smoking_level_values) {
      for (let i = 0; i < smoking_level_values.length; i++) {

        var smokerMap = {
          0: "Non Smoker",
          1: "Smoker"
        };

        smoking_level_valuesConverted.push(smokerMap[smoking_level_values[i]]);
      }
    }

    var latest_blood_pressure_sys_value = blood_pressure_sys_values.find(el => el !== null);
    var latest_blood_pressure_dys_value = blood_pressure_dys_values.find(el => el !== null);
    var latest_blood_glucose_value = blood_glucose_values.find(el => el !== null);
    var latest_oxygen_level_value = oxygen_level_values.find(el => el !== null);
    var latest_body_mass_index_value = body_mass_index_values.find(el => el !== null);
    var latest_airway_peak_flow_value = airway_peak_flow_values.find(el => el !== null);
    var latest_cholesterol_level_value = cholesterol_level_values.find(el => el !== null);
    var latest_body_temperature_value = body_temperature_values.find(el => el !== null);
    var latest_heart_rate_value = heart_rate_values.find(el => el !== null);
    
    var latest_beep_test_value = beep_test_values.find(el => el !== null);
    var latest_beep_test_decimal_value = beep_test_decimal_values.find(el => el !== null);
    var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    var latest_reaction_time_value = reaction_time_values.find(el => el !== null);
    
    var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    var latest_stress_level_value = stress_level_values.find(el => el !== null);
    var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    var latest_smoking_level_value_converted = smoking_level_valuesConverted.find(el => el !== undefined);
    var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    // var latest_blood_pressure_sys_value = healthcheck.blood_pressure_systolic;
    // var latest_blood_pressure_dys_value = healthcheck.blood_pressure_diastolic;
    // var latest_blood_glucose_value = healthcheck.blood_glucose;
    // var latest_oxygen_level_value = healthcheck.oxygen_level;
    // var latest_body_mass_index_value = healthcheck.body_mass_index;
    // var latest_airway_peak_flow_value = healthcheck.airway_peak_flow;
    // var latest_cholesterol_level_value = healthcheck.cholesterol_level;
    // var latest_body_temperature_value = healthcheck.body_temperature;
    // var latest_heart_rate_value = healthcheck.heart_rate;
    
    // var latest_beep_test_value = healthcheck.beep_test;
    // var latest_beep_test_decimal_value = healthcheck.beep_test_decimal;
    // var latest_vertical_jump_value = healthcheck.vertical_jump;
    // var latest_hundred_meter_sprint_value = healthcheck.hundred_meter_sprint;
    // var latest_reaction_time_value = healthcheck.reaction_time;
    
    // var latest_sleep_quality_value = healthcheck.sleep_quality;
    // var latest_stress_level_value = healthcheck.stress_level;
    // var latest_alcohol_intake_value = healthcheck.alcohol_intake;
    // var latest_smoking_level_value = healthcheck.smoking_level;
    // var latest_diet_quality_value = healthcheck.diet_quality;

    if (healthcheck) {

    var smoking_level_text = "";

    if (healthcheck.smoking_level == 1) {
        var smoking_level_text = "Smoker";
    } else {
      var smoking_level_text = "Non Smoker";
    }

    return (
      <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div>
              <GridContainer>
                <GridItem xs={12}>
                  <h4 style={title} className={classes.cardTitleBlack}>
                    Health Check Details
                  </h4>
                </GridItem>
                <GridItem xs={12}>
                  <Card>
                    <CardBody>
                      <h4 className={classes.cardTitle}>Date:</h4>
                      <p className={classes.cardCategory}>
                        {new Date(healthcheck.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </p>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>

              {this.healthTitle()}

              <GridContainer>
                {this.createCard(
                  "Blood Glucose", 
                  "/dashboard/metric/blood_glucose", 
                  getBloodGlucoseCardColour(healthcheck.blood_glucose), 
                  healthcheck.blood_glucose, 
                  "mmol/L" 
                )}
                {this.createCard(
                  "Blood Pressure", 
                  "/dashboard/metric/bloodpressure", 
                  getBloodPressureCardColour(healthcheck.blood_pressure_systolic,
                    healthcheck.blood_pressure_diastolic), 
                    healthcheck.blood_pressure_systolic + "/" + healthcheck.blood_pressure_diastolic, 
                  "mmHg" 
                )}
                {this.createCard(
                  "Heart Rate", 
                  "/dashboard/metric/heart_rate", 
                  getHeartRateCardColour(healthcheck.heart_rate), 
                  healthcheck.heart_rate, 
                  "BPM" 
                )}
                {this.createCard(
                  "Oxygen Level", 
                  "/dashboard/metric/oxygen_level", 
                  getOxygenLevelCardColour(healthcheck.oxygen_level), 
                  healthcheck.oxygen_level, 
                  "mmHg" 
                )}

                {this.createCard(
                  "Cholesterol Level", 
                  "/dashboard/metric/cholesterol_level", 
                  getCholesterolLevelCardColour(healthcheck.cholesterol_level), 
                  healthcheck.cholesterol_level, 
                  "mg/dL" 
                )}
                {this.createCard(
                  "Body Mass Index", 
                  "/dashboard/metric/body_mass_index", 
                  getBodyMassIndexCardColour(healthcheck.body_mass_index), 
                  healthcheck.body_mass_index, 
                  "kg/m" 
                )}
                {this.createCard(
                  "Body Temperature", 
                  "/dashboard/metric/body_temperature", 
                  getBodyTemperatureCardColour(healthcheck.body_temperature), 
                  healthcheck.body_temperature, 
                  "°C" 
                )}
                {this.createCard(
                  "Airway Peak Flow", 
                  "/dashboard/metric/airway_peak_flow", 
                  getAirwayPeakFlowColour(healthcheck.airway_peak_flow), 
                  healthcheck.airway_peak_flow, 
                  "L/min" 
                )}
              </GridContainer>

              {this.fitnessTitle()}

              <GridContainer>
                {this.createCard(
                  "Beep Test", 
                  "/dashboard/metric/beep_test", 
                  getBeepTestColour(healthcheck.beep_test), 
                  healthcheck.beep_test + "." + healthcheck.beep_test_decimal 
                )}
                {this.createCard(
                  "Vertical Jump", 
                  "/dashboard/metric/vertical_jump", 
                  getVerticalJumpColour(healthcheck.vertical_jump), 
                  healthcheck.vertical_jump, 
                  "cm" 
                )}
                {this.createCard(
                  "100m Sprint", 
                  "/dashboard/metric/hundred_meter_sprint", 
                  getHundredMeterSprintColour(healthcheck.hundred_meter_sprint), 
                  healthcheck.hundred_meter_sprint, 
                  "secs" 
                )}
                {this.createCard(
                  "Reaction Time", 
                  "/dashboard/metric/reaction_time", 
                  getReactionTimeColour(healthcheck.reaction_time), 
                  healthcheck.reaction_time, 
                  "secs" 
                )}
              </GridContainer>
              
              {this.lifestyleTitle()}

                <GridContainer>
                {/* <GridItem xs={12} sm={6} md={3}>
                  <Link to={"/dashboard/metric/alcohol_intake"}>
                    <Card
                      color={getAlcoholIntakeColour(healthcheck.alcohol_intake)}
                    >
                      <h4
                        style={metricCardTextAlign}
                        className={classes.cardTitleBlack}
                      >
                        Alcohol Intake
                      </h4>
                      <h4 style={metricCardMargin}>
                        {healthcheck.alcohol_intake} units per week
                      </h4>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <Link to={"/dashboard/metric/sleep_quality"}>
                    <Card
                      color={getSleepQualityColour(healthcheck.sleep_quality)}
                    >
                      <h4
                        style={metricCardTextAlign}
                        className={classes.cardTitleBlack}
                      >
                        Sleep Quality
                      </h4>
                      <h4 style={metricCardMargin}>
                        {healthcheck.sleep_quality} hours
                      </h4>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <Link to={"/dashboard/metric/stress_level"}>
                    <Card
                      color={getStressLevelColour(
                        healthcheck.stress_level
                      )}
                    >
                      <h4
                        style={metricCardTextAlign}
                        className={classes.cardTitleBlack}
                      >
                        Stress Level
                      </h4>
                      <h4 style={metricCardMargin}>
                        {healthcheck.stress_level} /10
                      </h4>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={2}>
                  <Link to={"/dashboard/metric/diet_quality"}>
                    <Card
                      color={getDietQualityColour(healthcheck.diet_quality)}
                    >
                      <h4
                        style={metricCardTextAlign}
                        className={classes.cardTitleBlack}
                      >
                        Diet Quality
                      </h4>
                      <h4 style={metricCardMargin}>
                        {healthcheck.diet_quality} /10
                      </h4>
                    </Card>
                  </Link>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Link to={"/dashboard/metric/smoking_level"}>
                    <Card
                      color={getSmokingLevelColour(healthcheck.smoking_level)}
                    >
                      <h4
                        style={metricCardTextAlign}
                        className={classes.cardTitleBlack}
                      >
                        Smoking Level
                      </h4>
                      <h4 style={metricCardMargin}>
                        {smoking_level_text}
                      </h4>
                    </Card>
                  </Link>
                </GridItem> */}
                {this.createCard(
                  "Alcohol Intake", 
                  "/dashboard/metric/alcohol_intake", 
                  getAlcoholIntakeColour(healthcheck.alcohol_intake), 
                  healthcheck.alcohol_intake, 
                  "units per week" 
                )}
                {this.createCard(
                  "Sleep Quality", 
                  "/dashboard/metric/sleep_quality", 
                  getSleepQualityColour(healthcheck.sleep_quality), 
                  healthcheck.sleep_quality, 
                  "hours" 
                )}
                {this.createCard(
                  "Stress Level", 
                  "/dashboard/metric/stress_level", 
                  getStressLevelColour(healthcheck.stress_level), 
                  healthcheck.stress_level, 
                  "/10" 
                )}
                {this.createCard(
                  "Diet Quality", 
                  "/dashboard/metric/diet_quality", 
                  getDietQualityColour(healthcheck.diet_quality), 
                  healthcheck.diet_quality, 
                  "/10" 
                )}
                {this.createCard(
                  "Smoking Level", 
                  "/dashboard/metric/smoking_level", 
                  getSmokingLevelColour(healthcheck.smoking_level), 
                  latest_smoking_level_value_converted
                )}
                {this.createMoreHealthCard("Notes", healthcheck.notes)}
                {this.createMoreHealthCard("Neurovascular assessment", healthcheck.neuro_assessment)}
                {this.createMoreHealthCard("Biomechanical assessment", healthcheck.biomech_assessment)}
                {this.createMoreHealthCard("Ultrasound results", healthcheck.ultrasound_results)}
                {this.createMoreHealthCard("Neurovascular assessment", healthcheck.diet_assessment)}
                {this.createMoreHealthCard("Blood results", healthcheck.blood_results)}

              </GridContainer>              
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
    } else {
      return (
        <h4 style={title} className={classes.cardTitleBlack}>
          No healthchecks found!
        </h4>
      );
    }
}
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);
