/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Assignment from "@material-ui/icons/Assignment";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import CoachDashboardPage from "views/Dashboard/CoachDashboard.js";
import AdminDashboardPage from "views/Dashboard/AdminDashboard.js";
import PractitionerDashboardPage from "views/Dashboard/PractitionerDashboard.js";
import PlayerDetailsPage from "views/PlayerDetails/PlayerDetails.js";
import AllMetricsPage from "views/MetricPage/Metrics.js";
import MetricBloodPressurePage from "views/MetricPage/MetricBloodPressure.js";

import UserProfile from "views/UserProfile/UserProfile.js";
import NewHealthCheckPage from "views/NewHealthCheck/NewHealthCheck.js";
import HealthCheckHistoryPage from "views/HealthCheckHistory/HealthCheckHistory.js";
import HealthCheckDetailsPage from "views/HealthCheckDetails/HealthCheckDetails.js";
import BookingPage from "views/Booking/Booking.js";
import SignOutPage from "views/SignOut/SignOut.js";
import DeleteAccountPage from "views/DeleteAccount/Delete.js";
import AddOrganisationPage from "views/AddOrganisation/AddOrganisation";
import AddPractitionerPage from "views/AddPractitioner/AddPractitioner";
import {
  AccountBox,
  AssignmentTurnedIn,
  Schedule,
  SupervisorAccount,
  ExitToApp,
  Timeline,
} from "@material-ui/icons";

// Unfinished code to codify the route config
// function gen_dashboard_page(path, name, icon, component) {
//   page = {};
//   page.path = path;
//   page.name = name;
//   page.icon = icon;
//   page.component = component;
//   page.layout = "/dashboard";
//   return page;
// }

// let dashboardRoutesExample = [];
// dashboardRoutesExample.append(
//   gen_dashboard_page("/main", "Dashboard", Dashboard, DashboardPage)
// )

class dashboardRoutes {
  constructor() {
    this.state = {
      isLoading: false,
      error: null
    };
  }

  getRoutes(userType) {
    let dashboardComponent = DashboardPage;
    if (userType === "Coach") {
      dashboardComponent = CoachDashboardPage;
    }
    if (userType === "Admin") {
      dashboardComponent = AdminDashboardPage;
    }
    if (userType === "Practitioner") {
      dashboardComponent = PractitionerDashboardPage;
    }

    const universalRoutes = [
      {
        path: "/delete",
        name: "Delete Account",
        icon: Assignment,
        component: DeleteAccountPage,
        layout: "/dashboard",
        invisible: true,
        types: ["All"]
      },
      {
        path: "/user",
        name: "Account Settings",
        icon: AccountBox,
        component: UserProfile,
        layout: "/dashboard",
        types: ["All"]
      },
      {
        path: "/signout",
        name: "Sign Out",
        icon: ExitToApp,
        component: SignOutPage,
        layout: "/dashboard",
        types: ["All"]
      }
    ];

    const playerRoutes = [
      {
        path: "/main",
        name: "Dashboard",
        icon: Dashboard,
        component: dashboardComponent,
        layout: "/dashboard",
        types: ["Player", "Coach"]
      },
      {
        path: "/player/:id",
        name: "Player Details",
        icon: Dashboard,
        component: PlayerDetailsPage,
        layout: "/dashboard",
        invisible: true,
        types: ["Player"]
      },
      // Keep blood pressure separate from other metrics due to having two inputs:
      // Systolic + Diastolic
      {
        path: "/metric/bloodpressure",
        name: "Metric",
        icon: Person,
        component: MetricBloodPressurePage,
        layout: "/dashboard",
        invisible: true,
        types: ["Player"]
      },
      {
        path: "/metric/:metricName",
        name: "Metric",
        icon: Person,
        component: AllMetricsPage,
        layout: "/dashboard",
        invisible: true,
        types: ["Player"]
      },
      {
        path: "/healthcheckhistory",
        name: "Health Check History",
        icon: Timeline,
        component: HealthCheckHistoryPage,
        layout: "/dashboard",
        types: ["Player"]
      },
      {
        path: "/healthcheck/:id",
        name: "Health Check Details",
        icon: Dashboard,
        component: HealthCheckDetailsPage,
        layout: "/dashboard",
        invisible: true,
        types: ["Player"]
      }
    ];

    const coachRoutes = [
      {
        path: "/main",
        name: "Dashboard",
        icon: Dashboard,
        component: dashboardComponent,
        layout: "/dashboard",
        types: ["Player", "Coach"]
      },
      {
        path: "/book",
        name: "Book Appointment",
        icon: Schedule,
        component: BookingPage,
        layout: "/dashboard",
        types: ["Coach"]
      },
      {
        path: "/player/:id",
        name: "Player Details",
        icon: Dashboard,
        component: PlayerDetailsPage,
        layout: "/dashboard",
        invisible: true,
        types: ["Coach"]
      },
    ];

    const practitionerRoutes = [
      {
        path: "/main",
        name: "Dashboard",
        icon: Dashboard,
        component: dashboardComponent,
        layout: "/dashboard",
        types: ["Practitioner"]
      },
      {
        path: "/newhealthcheck",
        name: "Add Health Check",
        icon: AssignmentTurnedIn,
        component: NewHealthCheckPage,
        layout: "/dashboard",
        types: ["Practitioner"]
      }
    ];

    const adminRoutes = [
      {
        path: "/main",
        name: "Dashboard",
        icon: Dashboard,
        component: dashboardComponent,
        layout: "/dashboard",
        types: ["Admin"]
      },
      {
        path: "/addorganisation",
        name: "Add Organisation",
        icon: SupervisorAccount,
        component: AddOrganisationPage,
        layout: "/dashboard",
        types: ["Admin"]
      },
      {
        path: "/addpractitioner",
        name: "Add Practitioner",
        icon: SupervisorAccount,
        component: AddPractitionerPage,
        layout: "/dashboard",
        types: ["Admin"]
      }
    ];

    if (userType === "Player") {
      return playerRoutes.concat(universalRoutes);
    }
    if (userType === "Coach") {
      return coachRoutes.concat(universalRoutes);
    }
    if (userType === "Practitioner") {
      return practitionerRoutes.concat(universalRoutes);
    }
    if (userType === "Admin") {
      return adminRoutes.concat(universalRoutes);
    }

    return universalRoutes;
  }
}

export default dashboardRoutes;
