import React from "react";
import axios from "axios";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import Divider from "@material-ui/core/Divider";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
// import CardContent from "components/Card/CardContent.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
import ChartistGraph from "react-chartist";
import {
  bloodPressureChart,
  heartRateChart,
  hundredMeterSprintChart,
  bleepTestChart,
  airwayPeakFlowChart,
  oxygenLevelChart,
  reactionTimeChart,
  bloodGlucoseChart,
  bodyMassIndexChart,
  cholesterolLevelChart,
  bodyTemperatureChart,
  verticalJumpChart,
  sleepQualityChart,
  stressLevelChart,
  alcoholIntakeChart,
  smokingLevelChart,
  dietQualityChart
} from "variables/charts.js";
import {
  getBloodGlucoseCardColour,
  getBloodPressureCardColour,
  getHeartRateCardColour,
  getOxygenLevelCardColour,
  getFootPostureIndexCardColour,
  getCholesterolLevelCardColour,
  getBodyMassIndexCardColour,
  getBodyTemperatureCardColour,
  getAirwayPeakFlowColour,
  getBeepTestColour,
  getVerticalJumpColour,
  getHundredMeterSprintColour,
  getReactionTimeColour,
  getAlcoholIntakeColour,
  getSleepQualityColour,
  getDietQualityColour,
  getSmokingLevelColour,
  getStressLevelColour
} from "libs/healthchecks.js";

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricCardTextAlignBold = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em",
  fontWeight: "900"
};

const metricCardTextAlignBoldLeft = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em",
  fontWeight: "900",
  marginLeft: "20px"
};

const h3Style = {
  fontWeight: "bold", 
  // fontFamily: "Aeonik-Medium"
}

const cardIconCustom = {
  float: "left",
  maxWidth: "95px",
  maxHeight: "95px",
  margin: "auto 0",
  marginTop: "-25px",
  borderRadius: "50%",
  overflow: "hidden",
  padding: "0",
  boxShadow:
    "0 16px 38px -24px rgba(" +
    hexToRgb(blackColor) +
    ", 0.56), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
};

const title = {
  textAlign: "center",
  fontSize: "2em"
};

const titleBold = {
  textAlign: "left",
  fontSize: "1.3em",
  fontWeight: "900",
  marginLeft: "20px"

};

class PlayerDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLoading: false,
      error: null,
      latestHealthCheck: null,
      metricValuesDates: [],
      blood_pressure_sys_dates: [],
      blood_pressure_dys_dates: [],
      blood_glucose_dates: [],
      oxygen_level_dates: [],
      body_mass_index_dates: [],
      airway_peak_flow_dates: [],
      cholesterol_level_dates: [],
      body_temperature_dates: [],
      heart_rate_dates: [],
      beep_test_dates: [],
      beep_test_decimal_dates: [],
      vertical_jump_dates: [],
      hundred_meter_sprint_dates: [],
      reaction_time_dates: [],
      sleep_quality_dates: [],
      stress_level_dates: [],
      alcohol_intake_dates: [],
      smoking_level_dates: [],
      diet_quality_dates: [],
      blood_pressure_sys_values: [],
      blood_pressure_dys_values: [],
      blood_glucose_values: [],
      oxygen_level_values: [],
      body_mass_index_values: [],
      airway_peak_flow_values: [],
      cholesterol_level_values: [],
      body_temperature_values: [],
      heart_rate_values: [],
      beep_test_values: [],
      beep_test_decimal_values: [],
      vertical_jump_values: [],
      hundred_meter_sprint_values: [],
      reaction_time_values: [],
      sleep_quality_values: [],
      stress_level_values: [],
      alcohol_intake_values: [],
      smoking_level_values: [],
      diet_quality_values: []
    };

    this.getLatestHealthCheckData();

  }

  convertDateOfBirth(dob) {
    var date = new Date(dob);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    } else if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  }

  getUserByID(id) {
    axios
      .get(`/api/users/getbyid/${id}`)
      .then(response => {
        this.setState({
          user: response.data,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getLatestHealthCheckData() {
    var userID = this.props.match.params.id;
    axios
      .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
      .then(response => {
        this.setState({
          latestHealthCheck: response.data,
          isLoading: false
        });
        console.log(response);
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getMetricData() {
    
    var userID = this.props.match.params.id;
    
      // blood_pressure_systolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_systolic`)
      .then(response => {
        this.setState({
          blood_pressure_sys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_pressure_diastolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_diastolic`)
      .then(response => {
        this.setState({
          blood_pressure_dys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_glucose //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_glucose`)
      .then(response => {
        this.setState({
          blood_glucose_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
    
      // oxygen_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/oxygen_level`)
      .then(response => {
        this.setState({
          oxygen_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_mass_index //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_mass_index`)
      .then(response => {
        this.setState({
          body_mass_index_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // airway_peak_flow //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/airway_peak_flow`)
      .then(response => {
        this.setState({
          airway_peak_flow_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // cholesterol_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/cholesterol_level`)
      .then(response => {
        this.setState({
          cholesterol_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_temperature //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_temperature`)
      .then(response => {
        this.setState({
          body_temperature_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // heart_rate //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/heart_rate`)
      .then(response => {
        this.setState({
          heart_rate_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test`)
      .then(response => {
        this.setState({
          beep_test_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test_decimal //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test_decimal`)
      .then(response => {
        this.setState({
          beep_test_decimal_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // vertical_jump //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/vertical_jump`)
      .then(response => {
        this.setState({
          vertical_jump_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // hundred_meter_sprint //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/hundred_meter_sprint`)
      .then(response => {
        this.setState({
          hundred_meter_sprint_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // reaction_time //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/reaction_time`)
      .then(response => {
        this.setState({
          reaction_time_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // sleep_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/sleep_quality`)
      .then(response => {
        this.setState({
          sleep_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // stress_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/stress_level`)
      .then(response => {
        this.setState({
          stress_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // alcohol_intake //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/alcohol_intake`)
      .then(response => {
        this.setState({
          alcohol_intake_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // smoking_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/smoking_level`)
      .then(response => {
        this.setState({
          smoking_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // diet_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/diet_quality`)
      .then(response => {
        this.setState({
          diet_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }


  getGraphMonthData() {
    var userID = this.props.match.params.id;
    let {
      blood_pressure_sys_dates,
      blood_pressure_dys_dates,
      blood_glucose_dates,
      oxygen_level_dates,
      body_mass_index_dates,
      airway_peak_flow_dates,
      cholesterol_level_dates,
      body_temperature_dates,
      heart_rate_dates,
      beep_test_dates,
      beep_test_decimal_dates,
      vertical_jump_dates,
      hundred_meter_sprint_dates,
      reaction_time_dates,
      sleep_quality_dates,
      stress_level_dates,
      alcohol_intake_dates,
      smoking_level_dates,
      diet_quality_dates
    } = this.state;

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/blood_pressure_diastolic`)
      .then(response => {
        this.setState({
          blood_pressure_dys_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/blood_pressure_systolic`)
      .then(response => {
        this.setState({
          blood_pressure_sys_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/blood_glucose`)
      .then(response => {
        this.setState({
          blood_glucose_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/heart_rate`)
      .then(response => {
        this.setState({
          heart_rate_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/oxygen_level`)
      .then(response => {
        this.setState({
          oxygen_level_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/cholesterol_level`)
      .then(response => {
        this.setState({
          cholesterol_level_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/body_mass_index`)
      .then(response => {
        this.setState({
          body_mass_index_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/body_temperature`)
      .then(response => {
        this.setState({
          body_temperature_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/airway_peak_flow`)
      .then(response => {
        this.setState({
          airway_peak_flow_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/beep_test`)
      .then(response => {
        this.setState({
          beep_test_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/beep_test_decimal`)
      .then(response => {
        this.setState({
          beep_test_decimal_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/vertical_jump`)
      .then(response => {
        this.setState({
          vertical_jump_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/hundred_meter_sprint`)
      .then(response => {
        this.setState({
          hundred_meter_sprint_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/reaction_time`)
      .then(response => {
        this.setState({
          reaction_time_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/sleep_quality`)
      .then(response => {
        this.setState({
          sleep_quality_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/stress_level`)
      .then(response => {
        this.setState({
          stress_level_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/alcohol_intake`)
      .then(response => {
        this.setState({
          alcohol_intake_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/smoking_level`)
      .then(response => {
        this.setState({
          smoking_level_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      axios
      .get(`/api/healthchecks/getOneMetricHistoryDates/${userID}/diet_quality`)
      .then(response => {
        this.setState({
          diet_quality_dates: response.data.metricValuesDates
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

  }

  getMetricChartOptions(metricName) {
    var metricNameMap = {
      blood_glucose: bloodGlucoseChart.options,
      oxygen_level: oxygenLevelChart.options,
      body_mass_index: bodyMassIndexChart.options,
      airway_peak_flow: airwayPeakFlowChart.options,
      cholesterol_level: cholesterolLevelChart.options,
      body_temperature: bodyTemperatureChart.options,
      heart_rate: heartRateChart.options,
      beep_test: bleepTestChart.options,
      vertical_jump: verticalJumpChart.options,
      hundred_meter_sprint: hundredMeterSprintChart.options,
      reaction_time: reactionTimeChart.options,
      sleep_quality: sleepQualityChart.options,
      stress_level: stressLevelChart.options,
      alcohol_intake: alcoholIntakeChart.options,
      smoking_level: smokingLevelChart.options,
      diet_quality: dietQualityChart.options
    };
    return metricNameMap[metricName];
  }

  allAreNull(arr) {
    return arr.every(element => element === null);
  }
  
  healthTitle(){
    const { classes } = this.props;
    let {
      blood_pressure_sys_values,
      blood_pressure_dys_values,
      blood_glucose_values,
      oxygen_level_values,
      body_mass_index_values,
      airway_peak_flow_values,
      cholesterol_level_values,
      body_temperature_values,
      heart_rate_values,
    } = this.state;

    var latest_blood_pressure_sys_value = blood_pressure_sys_values.find(el => el !== null);
    var latest_blood_pressure_dys_value = blood_pressure_dys_values.find(el => el !== null);
    var latest_blood_glucose_value = blood_glucose_values.find(el => el !== null);
    var latest_oxygen_level_value = oxygen_level_values.find(el => el !== null);
    var latest_body_mass_index_value = body_mass_index_values.find(el => el !== null);
    var latest_airway_peak_flow_value = airway_peak_flow_values.find(el => el !== null);
    var latest_cholesterol_level_value = cholesterol_level_values.find(el => el !== null);
    var latest_body_temperature_value = body_temperature_values.find(el => el !== null);
    var latest_heart_rate_value = heart_rate_values.find(el => el !== null);

    if (!(latest_blood_pressure_sys_value == undefined && 
      latest_blood_pressure_dys_value == undefined &&
      latest_blood_glucose_value == undefined && 
      latest_oxygen_level_value == undefined &&
      latest_body_mass_index_value == undefined && 
      latest_airway_peak_flow_value == undefined &&
      latest_cholesterol_level_value == undefined && 
      latest_body_temperature_value == undefined && 
      latest_heart_rate_value == undefined)){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Health
          </h4>
        </GridItem>


      )
    }
  }

  fitnessTitle(){
    const { classes } = this.props;
    let {
      beep_test_values,
      vertical_jump_values,
      hundred_meter_sprint_values,
      reaction_time_values
    } = this.state;

    var latest_beep_test_value = beep_test_values.find(el => el !== null);
    var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    var latest_reaction_time_value = reaction_time_values.find(el => el !== null);

    if (!(
      latest_beep_test_value == undefined && 
      latest_vertical_jump_value == undefined &&
      latest_hundred_meter_sprint_value == undefined && 
      latest_reaction_time_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Fitness
          </h4>
        </GridItem>


      )
    }
  }

  lifestyleTitle(){
    const { classes } = this.props;
    let {
      sleep_quality_values,
      stress_level_values,
      alcohol_intake_values,
      smoking_level_values,
      diet_quality_values
    } = this.state;

    var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    var latest_stress_level_value = stress_level_values.find(el => el !== null);
    var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    if (!(
      latest_sleep_quality_value == undefined && 
      latest_stress_level_value == undefined &&
      latest_alcohol_intake_value == undefined && 
      latest_smoking_level_value == undefined && 
      latest_diet_quality_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Lifestyle
          </h4>
        </GridItem>


      )
    }
  }


  createChart(name, graph, months, values, latest, color) {
    const { classes } = this.props;

    let data = {
      labels: months,
      series: [values]
    };

    if (name === "Blood Pressure") {
      data.series = values;
    }

    let chartName = "ct-chart-" + name;

    console.log(data.series[0])
    console.log(!this.allAreNull(data.series[0])); // 👉️ true
    // console.log(allAreNull([null, undefined])); // 👉️ false
  

    if (!this.allAreNull(data.series[0])){


    return (
      // <Card chart>
      //   <CardHeader color={color}>
      //     <ChartistGraph
      //       className={chartName}
      //       data={data}
      //       type="Line"
      //       options={graph.options}
      //       listener={graph.animation}
      //     />
      //   </CardHeader>
      //   <CardBody>
      //     <h4 className={classes.cardTitle}>{name}</h4>
      //     <p className={classes.cardCategory}>Latest Result: {latest}</p>
      //   </CardBody>
      // </Card>
      <GridItem xs={12} sm={3}>
          <Card color={color} style={{ display: "flex", flexDirection: "column" }}>
            {/* <CardHeader color={color}> */}
            <div style={{margin: "20px 10px 10px 10px"}}>
              <ChartistGraph
                className={chartName}
                data={data}
                type="Line"
                options={graph.options}
                listener={graph.animation}
              />
            </div>
            {/* </CardHeader> */}
            {/* <CardBody color={color}>
              <h4 className={classes.cardTitle}>{name}</h4>
              <p className={classes.cardCategory}>Latest Result: {latest}</p>
            </CardBody> */}
            {/* <GridItem color="white">
              <h4
                style={{backgroundColor: color}}
                className={classes.cardTitleBlack}
              >
                Health
              </h4>
            </GridItem> */}
            <div style={{backgroundColor: "white", "border-radius": "0px 0px 5px 5px"}}>
              <div style={{backgroundColor: "white", margin: "20px 10px 10px 10px"}}>
              <h4
                style={{color: "black", "font-weight": "500"}}
                className={classes.cardTitleBlack}
              >
              {name}
              </h4>
              <p className={classes.cardCategory}>Latest Result: {latest}</p>
              </div>
            </div>
            {/* <CardContent className={classes.content}>
              Test 2
            </CardContent> */}

          </Card>
        </GridItem>
      );
    }
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.getUserByID(params.id);
    this.getGraphMonthData();
    this.getMetricData();
  }

  render() {
    const { classes } = this.props;
    const { isLoading, error } = this.state;
    let { user, 
          latestHealthCheck,
          metricValuesDates,
          blood_pressure_sys_dates,
          blood_pressure_dys_dates,
          blood_glucose_dates,
          oxygen_level_dates,
          body_mass_index_dates,
          airway_peak_flow_dates,
          cholesterol_level_dates,
          body_temperature_dates,
          heart_rate_dates,
          beep_test_dates,
          beep_test_decimal_dates,
          vertical_jump_dates,
          hundred_meter_sprint_dates,
          reaction_time_dates,
          sleep_quality_dates,
          stress_level_dates,
          alcohol_intake_dates,
          smoking_level_dates,
          diet_quality_dates,    
          blood_pressure_sys_values,
          blood_pressure_dys_values,
          blood_glucose_values,
          oxygen_level_values,
          body_mass_index_values,
          airway_peak_flow_values,
          cholesterol_level_values,
          body_temperature_values,
          heart_rate_values,
          beep_test_values,
          beep_test_decimal_values,
          vertical_jump_values,
          hundred_meter_sprint_values,
          reaction_time_values,
          sleep_quality_values,
          stress_level_values,
          alcohol_intake_values,
          smoking_level_values,
          diet_quality_values,
        } = this.state;

    function compare( a, b ){
      if (a.date > b.date ){
        return -1;
      }
      if (a.date < b.date ){
        return 1;
      }
      return 0;
    }

    let currentHeight = null;
    let currentWeight = null;
    if (latestHealthCheck) {
      currentHeight = latestHealthCheck["height"];
      currentWeight = latestHealthCheck["weight"];
    }

    console.log(latestHealthCheck);

    
    if (!user) {
      user = {
        first_name: "test",
        last_name: "test",
        gender: "test",
        age: 99,
        height: 999,
        weight: 99
      };
    }

    const smoking_level_valuesConverted = [];

    if (smoking_level_values) {
      for (let i = 0; i < smoking_level_values.length; i++) {

        var smokerMap = {
          0: "Non Smoker",
          1: "Smoker"
        };

        smoking_level_valuesConverted.push(smokerMap[smoking_level_values[i]]);
      }
    }

    // const latest_smoking_level_value_converted = "";

    if (smoking_level_valuesConverted) {

    }
    // latest_smoking_level_value_converted.push()

    console.log(latest_smoking_level_value_converted);
    console.log(smoking_level_valuesConverted);
    console.log(smoking_level_valuesConverted);

    console.log(smoking_level_valuesConverted.find(el => el !== null));

    // Latest Health values
    var latest_blood_pressure_sys_value = blood_pressure_sys_values.find(el => el !== null);
    var latest_blood_pressure_dys_value = blood_pressure_dys_values.find(el => el !== null);
    var latest_blood_glucose_value = blood_glucose_values.find(el => el !== null);
    var latest_oxygen_level_value = oxygen_level_values.find(el => el !== null);
    var latest_body_mass_index_value = body_mass_index_values.find(el => el !== null);
    var latest_airway_peak_flow_value = airway_peak_flow_values.find(el => el !== null);
    var latest_cholesterol_level_value = cholesterol_level_values.find(el => el !== null);
    var latest_body_temperature_value = body_temperature_values.find(el => el !== null);
    var latest_heart_rate_value = heart_rate_values.find(el => el !== null);
    
    // if (latest_blood_pressure_sys_value == null && 
    // latest_blood_pressure_dys_value == null &&
    // latest_blood_glucose_value == null && 
    // latest_oxygen_level_value == null &&
    // latest_body_mass_index_value == null && 
    // latest_airway_peak_flow_value == null &&
    // latest_cholesterol_level_value == null && 
    // latest_body_temperature_value == null && 
    // latest_heart_rate_value == null){

    // }

    // var healthTitle = null;

    if ( latest_blood_pressure_sys_value != null && 
          latest_blood_pressure_sys_value != null ){
        // healthTitle = (
          
        // )

        } 
      // }
    
      
    // Latest Fitness values
      
    var latest_beep_test_value = beep_test_values.find(el => el !== null);
    var latest_beep_test_decimal_value = beep_test_decimal_values.find(el => el !== null);
    var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    var latest_reaction_time_value = reaction_time_values.find(el => el !== null);
    
    // Latest Lifestyle values

    var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    var latest_stress_level_value = stress_level_values.find(el => el !== null);
    var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    var latest_smoking_level_value_converted = smoking_level_valuesConverted.find(el => el !== undefined);
    var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    console.log(latest_smoking_level_value);



    return (
      <React.Fragment>
        {error ? <h5>{error.message}</h5> : null}
        <div>
          {!isLoading ? (
            <div>
              {/* <GridContainer>
                <GridItem xs={12} sm={6}>
                  <h3 style={h3Style} >User Profile</h3>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <h3 style={h3Style} >History</h3>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={6} sm={6}>
                  <Card>

                    <GridItem xs={12} sm={12} md={12} lg={12} style={{display: "inline-flex", "margin-left": 8, "margin-right": 8, "margin-top": 8}}>
                      <Card style={{"background-color": "#e8f4f8", margin: 8}}>
                        <h5 style={{margin: 8, "font-weight": "bold"}}>
                        {user.first_name} {user.last_name}
                        </h5>
                      </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12} style={{display: "inline-flex", "margin-left": 8, "margin-right": 8, "margin-bottom": 8}}>

                      <Card style={{"background-color": "#e8f4f8", "margin-left": 8, "margin-right": 8, "margin-bottom": 8}} >

                        <GridItem style={{margin: "auto", "margin-top": 8}}>
                            <div >
                              <Accessibility style={{"background-color": "#436fb2", border: "4px solid #436fb2", "border-radius": "5px"}}/>
                            </div>
                        </GridItem>

                        <h7 style={{ margin: "auto", "font-weight": "bold", "margin-top": 8 }}>
                          Gender: {user.gender}
                        </h7>

                      </Card>

                      <Card style={{"background-color": "#e8f4f8", "margin-left": 8, "margin-right": 8, "margin-bottom": 8}}>
                        <h5 style={{ margin: 8 }}>
                          Player2
                        </h5>
                      </Card>

                      <Card style={{"background-color": "#e8f4f8", "margin-left": 8, "margin-right": 8, "margin-bottom": 8}}>
                        <h5 style={{ margin: 8 }}>
                          Player2
                        </h5>
                      </Card>

                      <Card style={{"background-color": "#e8f4f8", "margin-left": 8, "margin-right": 8, "margin-bottom": 8}}>
                        <h5 style={{ margin: 8 }}>
                          Player2
                        </h5>
                      </Card>

                    </GridItem>

                  </Card>
                </GridItem>

                <GridItem xs={6} sm={6}>
                  <Card>

                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Player
                    </h5>
                  </GridItem>

                  </Card>
                </GridItem>
              </GridContainer> */}

              {/* <GridContainer>
                <GridItem>
                  <Card>
                    <GridItem xs={3} style={{ margin: 8, "background-color": "#f1f4f9"}}>
                      <Card >
                        <h5 style={{ margin: 8}}>
                          b:
                        </h5>
                      </Card>
                    </GridItem>
                    <GridItem xs={3}>
                      <Card style={{ margin: 8, "background-color": "#f1f4f9"}}>
                        <h5 style={{ margin: 8}}>
                          b:
                        </h5>
                      </Card>
                    </GridItem>
                    <GridItem xs={3}>
                      <Card style={{ margin: 8, "background-color": "#f1f4f9"}}>
                        <h5 style={{ margin: 8}}>
                          b:
                        </h5>
                      </Card>
                    </GridItem>
                    <GridItem xs={3}>
                      <Card style={{ margin: 8, "background-color": "#f1f4f9"}}>
                        <h5 style={{ margin: 8}}>
                          b:
                        </h5>
                      </Card>
                    </GridItem>
                  </Card>
                </GridItem>
              </GridContainer> */}

              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      {/* <CardAvatar profile style={cardIconCustom}>
                        <img src={user.image} alt={user.first_name}></img>
                      </CardAvatar> */}
                      <p className={classes.cardCategory}>Name</p>
                      <h3 className={classes.cardTitle}>
                        {user.first_name} {user.last_name}
                      </h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Accessibility />
                        {user.gender}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                        <Store />
                      </CardIcon>
                      <p className={classes.cardCategory}>Age</p>
                      <h3 className={classes.cardTitle}>{user.age}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <DateRange />
                        {this.convertDateOfBirth(user.date_of_birth)}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="danger" stats icon>
                      <CardIcon color="danger">
                        <Icon>info_outline</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Height</p>
                      <h3 className={classes.cardTitle}>{currentHeight}cm</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <LocalOffer />
                        5&apos;5&quot;
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                        <Accessibility />
                      </CardIcon>
                      <p className={classes.cardCategory}>Weight</p>
                      <h3 className={classes.cardTitle}>{currentWeight}kg</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                        <Update />
                        200lbs
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
              {this.healthTitle()}


              {/* <Divider light />

              <GridContainer>
                <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Health
                  </h4>
                </GridItem>
              </GridContainer> */}


              <GridContainer>
                {/* <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Health
                  </h4>
                </GridItem> */}
                {/* <GridItem xs={12} sm={3}> */}
                  
                  {this.createChart(
                    "Blood Glucose",
                    bloodGlucoseChart,
                    blood_glucose_dates,
                    blood_glucose_values,
                    latest_blood_glucose_value + " mmol/L",
                    getBloodGlucoseCardColour(latest_blood_glucose_value)
                  )}
                {/* </GridItem> */}
                {/* <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Blood Pressure",
                    bloodPressureChart,
                    blood_pressure_sys_dates,
                    [blood_pressure_sys_values, blood_pressure_dys_values],
                    latest_blood_pressure_sys_value + "/" + latest_blood_pressure_dys_value + " mmHg",
                    getBloodPressureCardColour(latest_blood_pressure_sys_value, latest_blood_pressure_dys_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Heart Rate",
                    heartRateChart,
                    heart_rate_dates,
                    heart_rate_values,
                    latest_heart_rate_value + " BPM",
                    getHeartRateCardColour(latest_heart_rate_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Oxygen Level",
                    oxygenLevelChart,
                    oxygen_level_dates,
                    oxygen_level_values,
                    latest_oxygen_level_value + " mmHg",
                    getOxygenLevelCardColour(latest_oxygen_level_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Cholesterol Level",
                    cholesterolLevelChart,
                    cholesterol_level_dates,
                    cholesterol_level_values,
                    latest_cholesterol_level_value + " mg/dL",
                    getCholesterolLevelCardColour(latest_cholesterol_level_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Body Mass Index",
                    bodyMassIndexChart,
                    body_mass_index_dates,
                    body_mass_index_values,
                    latest_body_mass_index_value + " kg/m",
                    getBodyMassIndexCardColour(latest_body_mass_index_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Body Temperature",
                    bodyTemperatureChart,
                    body_temperature_dates,
                    body_temperature_values,
                    latest_body_temperature_value + " °C",
                    getBodyTemperatureCardColour(latest_body_temperature_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Airway Peak Flow",
                    airwayPeakFlowChart,
                    airway_peak_flow_dates,
                    airway_peak_flow_values,
                    latest_airway_peak_flow_value + " L/min",
                    getAirwayPeakFlowColour(latest_airway_peak_flow_value)
                  )}
                {/* </GridItem> */}
              </GridContainer>
              {this.fitnessTitle()}

              {/* <Divider light /> */}

              <GridContainer>
                {/* <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Fitness
                  </h4>
                </GridItem> */}
                {/* <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Beep Test",
                    bleepTestChart,
                    beep_test_dates,
                    beep_test_values,
                    latest_beep_test_value + "." + latest_beep_test_decimal_value,
                    getBeepTestColour(latest_beep_test_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Vertical Jump",
                    verticalJumpChart,
                    vertical_jump_dates,
                    vertical_jump_values,
                    latest_vertical_jump_value + " cm",
                    getVerticalJumpColour(latest_vertical_jump_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "100m Sprint",
                    hundredMeterSprintChart,
                    hundred_meter_sprint_dates,
                    hundred_meter_sprint_values,
                    latest_hundred_meter_sprint_value + " secs",
                    getHundredMeterSprintColour(latest_hundred_meter_sprint_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Reaction Time",
                    reactionTimeChart,
                    reaction_time_dates,
                    reaction_time_values,
                    latest_reaction_time_value + " secs",
                    getReactionTimeColour(latest_reaction_time_value)
                  )}
                {/* </GridItem> */}
                </GridContainer>
                {this.lifestyleTitle()}

              {/* <Divider light /> */}

              <GridContainer>
                {/* <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Lifestyle
                  </h4>
                </GridItem> */}
                {/* <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Alcohol Intake",
                    alcoholIntakeChart,
                    alcohol_intake_dates,
                    alcohol_intake_values,
                    latest_alcohol_intake_value + " units per week",
                    getAlcoholIntakeColour(latest_alcohol_intake_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Sleep Quality",
                    sleepQualityChart,
                    sleep_quality_dates,
                    sleep_quality_values,
                    latest_sleep_quality_value + " hours",
                    getSleepQualityColour(latest_sleep_quality_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Stress Level",
                    stressLevelChart,
                    stress_level_dates,
                    stress_level_values,
                    latest_stress_level_value + " /10",
                    getStressLevelColour(latest_stress_level_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Diet Quality",
                    dietQualityChart,
                    diet_quality_dates,
                    diet_quality_values,
                    latest_diet_quality_value + " /10",
                    getDietQualityColour(latest_diet_quality_value)
                  )}
                {/* </GridItem>
                <GridItem xs={12} sm={3}> */}
                  {this.createChart(
                    "Smoking Level",
                    smokingLevelChart,
                    smoking_level_dates,
                    smoking_level_values,
                    latest_smoking_level_value_converted,
                    getSmokingLevelColour(latest_smoking_level_value)
                  )}
                {/* </GridItem> */}
              </GridContainer>

              <Divider light />

              <GridContainer>
                <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    History
                  </h4>
                  </GridItem>
              </GridContainer>

              <GridContainer>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Family history
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.family_history}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Medical history
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.medical_history}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Allergies
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.allergies}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                      Previous injuries
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.previous_injuries}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                
                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Previous surgeries
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.previous_surgeries}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
              
              </GridContainer>
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

PlayerDetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  withRouter(PlayerDetailsPage)
);
