import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { calculateHealthCheckMetricNumbers } from "libs/healthchecks.js";
import FormHelperText from '@material-ui/core/FormHelperText';
import Container from "@material-ui/core/Container";


const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};
const selector = {
  minWidth: "150",
  width: "100%"
};const selector2 = {
  minWidth: "150",
  width: "100%",
  margin: 8
};

// style={{  }}
const submitButton = {
  width: "20%",
  marginLeft: "40%"
};

const styles = () => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  }
});

const h3Style = {
  fontWeight: "bold", 
  // fontFamily: "Aeonik-Medium"
}

const h3Style2 = {
  fontWeight: "bold", 
  'margin-left': 8
}

const FirstDiv = {
  backgroundColor: "#e8f4f8", 
  // width: "1200px"
}

const ContainerStyle = {
  marginLeft: "2%", 
  width: "1000px"

}

const SecondContainerStyle = {
  backgroundColor: "white", 
  borderRadius: "20px"
}

const FormStyle = {
  borderRadius: "8px",
   backgroundColor: "#F1F4F9", 
   border: "#62C5C4"
}

class NewHealthCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      healthcheck: {},
      user: null,
      selectedUser: null,
      select: {
        org: "",
        player: ""
      },
      organisations: [],
      players: [],
      playersLoaded: false,
      isLoading: true,
      error: null,
      submittedHealthcheck: false,
      medicalQuestions: {
        previous_injuries: "",
        previous_surgeries: "",
        allergies: "",
        medical_history: "",
        family_history: ""
      },
      fields: {},
      errors: {},
      formIsValid: true
    };
  }

  getUser(userID) {
    axios
      .get(`/api/users/getByID/${userID}`)
      .then(response => {
        this.setState({ user: response.data });
        this.getOrganisations();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getSelectedUser(userID) {
    axios
      .get(`/api/users/getByID/${userID}`)
      .then(response => {
        this.setState({ selectedUser: response.data });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getOrganisations() {
    axios
      .get("/api/organisations/all")
      .then(response => {
        this.setState({
          organisations: response.data.organisations,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error }));
  }

  getPlayersByOrg() {
    const { select } = this.state;
    axios
      .get("/api/users/getByOrg/" + select.org)
      .then(response => {
        this.setState({
          players: response.data
        });
      })
      .catch(error => this.setState({ error }));
  }

  componentDidMount() {
    this.getUser(localStorage.getItem("userID"));
  }

  handleChange = event => {
    const { healthcheck, medicalQuestions, fields } = this.state;
    healthcheck[event.target.name] = event.target.value;
    medicalQuestions[event.target.name] = event.target.value;
    fields[event.target.name] = event.target.value;        
    this.setState({ healthcheck, medicalQuestions, fields });
    console.log(fields);
    console.log(healthcheck);
  };

  handleSelect = event => {
    const { players, select, medicalQuestions } = this.state;
    select[event.target.name] = event.target.value;
    this.setState({ select });
    
    


    if (event.target.name === "org") {
      this.getPlayersByOrg();
    }

    if (event.target.name === "player") {

      var player = players[select.player];

      console.log(player._id);

      this.getSelectedUser(player._id);

      medicalQuestions.previous_injuries = player.previous_injuries;
      medicalQuestions.previous_surgeries = player.previous_surgeries;
      medicalQuestions.allergies = player.allergies;
      medicalQuestions.medical_history = player.medical_history;
      medicalQuestions.family_history = player.family_history;


      console.log(medicalQuestions);
    }

    this.setState({
      isLoading: false
    });

  };

  handleValidation(){
    const { fields } = this.state;
    let formIsValid = true;

    let errors = {};

    if (2 > fields["sleep_quality"] || fields["sleep_quality"] > 9) {
      formIsValid = false;
      errors["sleep_quality"] = "Please enter a number between 2 and 9";
      console.log(errors);
    }

    if (0 > fields["stress_level"] || fields["stress_level"] > 10) {
      formIsValid = false;
      errors["stress_level"] = "Please enter a number between 0 and 10";
      console.log(errors);
    }

    if (0 > fields["alcohol_intake"] || fields["alcohol_intake"] > 30) {
      formIsValid = false;
      errors["alcohol_intake"] = "Please enter a value between 0 and 30";
    }

    if (0 > fields["diet_quality"] || fields["diet_quality"] > 10) {
      formIsValid = false;
      errors["diet_quality"] = "Please enter a value between 0 and 10";
    }
    
    if (58 > fields["blood_pressure_systolic"] || fields["blood_pressure_systolic"] > 270) {
      formIsValid = false;
      errors["blood_pressure_systolic"] = "Please enter a value between 58 and 270";
    }
    
    if (1 > fields["blood_glucose"] || fields["blood_glucose"] > 30) {
      formIsValid = false;
      errors["blood_glucose"] = "Please enter a value between 1 and 30";
    }

    if (0 > fields["cholesterol_level"] || fields["cholesterol_level"] > 15) {
      formIsValid = false;
      errors["cholesterol_level"] = "Please enter a value between 0 and 15";
    }

    if (20 > fields["heart_rate"] || fields["heart_rate"] > 200) {
      formIsValid = false;
      errors["heart_rate"] = "Please enter a value between 20 and 200";
    }
    
    if (32 > fields["blood_pressure_diastolic"] || fields["blood_pressure_diastolic"] > 250) {
      formIsValid = false;
      errors["blood_pressure_diastolic"] = "Please enter a value between 32 and 250";
    }

    if (20 > fields["weight"] || fields["weight"] > 400) {
      formIsValid = false;
      errors["weight"] = "Please enter a value between 20 and 400";
    }
    
    if (60 > fields["oxygen_level"] || fields["oxygen_level"] > 100) {
      formIsValid = false;
      errors["oxygen_level"] = "Please enter a value between 60 and 100";
    }  
    
    if (100 > fields["height"] || fields["height"] > 250) {
      formIsValid = false;
      errors["height"] = "Please enter a value between 100 and 250";
    }

    if (20 > fields["body_temperature"] || fields["body_temperature"] > 40) {
      formIsValid = false;
      errors["body_temperature"] = "Please enter a value between 20 and 40";
    }

    if (50 > fields["airway_peak_flow"] || fields["airway_peak_flow"] > 700) {
      formIsValid = false;
      errors["airway_peak_flow"] = "Please enter a value between 50 and 700";
    }

    if (1 > fields["beep_test"] || fields["beep_test"] > 23) {
      formIsValid = false;
      errors["beep_test"] = "Please enter a value between 1 and 23";
    }

    if (5 > fields["vertical_jump"] || fields["vertical_jump"] > 105) {
      formIsValid = false;
      errors["vertical_jump"] = "Please enter a value between 5 and 105";
    }

    if (0 > fields["hundred_meter_sprint"] || fields["hundred_meter_sprint"] > 30) {
      formIsValid = false;
      errors["hundred_meter_sprint"] = "Please enter a value between 0 and 30";
    }

    if (0 > fields["beep_test_decimal"] || fields["beep_test_decimal"] > 12) {
      formIsValid = false;
      errors["beep_test_decimal"] = "Please enter a value between 0 and 12";
    }
    
    if (0 > fields["reaction_time"] || fields["reaction_time"] > 30) {
      formIsValid = false;
      errors["reaction_time"] = "Please enter a value between 0 and 30";
    }
    
    // if(!fields["smoking_level"]){
    //   formIsValid = false;
    //   errors["smoking_level"] = "Cannot be empty";
    // }

   this.setState({errors: errors});

   return formIsValid;
}

  handleSubmit = event => {

    const { players, select, user, fields } = this.state;

    this.handleValidation();

    if(this.handleValidation()){
      alert("Form submitted")

      console.log(fields);

      const player = players[select.player];
      const data = this.state.healthcheck;
      console.log(data);
      const data2 = this.state.medicalQuestions;
      data.user_id = player._id;
      var dater = new Date().toISOString().substr(0,10);
      var timer = new Date().toISOString();
      data.date = dater;
      data.time = timer;
      data.practitioner_id = user._id;
      data.practitioner_name = user.first_name + ' ' + user.last_name;
      data.booking_id = player.next_appointment;

      var height = this.state.healthcheck.height;
      var heightInMeters = height / 100;
      var weight = this.state.healthcheck.weight;

      var bmi = (weight / (heightInMeters * heightInMeters)).toFixed(1);

      data.body_mass_index = bmi;

      console.log("This is the current reaction time value: " + this.state.healthcheck.reaction_time)
      if (this.state.healthcheck.reaction_time){
        var reactionTimeRulerDistance = this.state.healthcheck.reaction_time;
  
        var reactionTimeInMeters = reactionTimeRulerDistance / 100;
  
        var reactionTimeInSeconds = Math.sqrt((2 * reactionTimeInMeters) / 9.81);
  
        var reactionTimeInSeconds2decimalPlaces = reactionTimeInSeconds.toFixed(2);
  
        data.reaction_time = reactionTimeInSeconds2decimalPlaces;
  
      }

      console.log(data.reaction_time);
      
      axios
        .post(
          "/api/healthchecks/add",
          { healthCheck: data },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(() => {
          this.setState({
            submittedHealthcheck: true
          });
        })
        .catch(function(error) {
        });

        if(reactionTimeRulerDistance){
          this.state.healthcheck.reaction_time = reactionTimeRulerDistance;
        }

      const metrics = calculateHealthCheckMetricNumbers(data);

      console.log(data);
      let metricUser = {};
      metricUser.id = player._id;
      metricUser.unhealthy_score = metrics.red ? metrics.red : 0;
      metricUser.okay_score = metrics.orange ? metrics.orange : 0;
      metricUser.healthy_score = metrics.green ? metrics.green : 0;

      metricUser.previous_injuries = data2.previous_injuries;
      metricUser.previous_surgeries = data2.previous_surgeries;
      metricUser.allergies = data2.allergies;
      metricUser.medical_history = data2.medical_history;
      metricUser.family_history = data2.family_history;    

      console.log(metricUser);

      axios
        .put(
          "/api/users/update",
          { user: metricUser },
          { headers: { "Content-Type": "application/json" } }
        )
        .then
        // do nothing
        ()
        .catch(function(error) {
          console.log(error.response);
        });

      
      }else{
          alert("Form has errors.")
          event.preventDefault();
      }

  };

  genOrgs() {
    const { organisations } = this.state;
    let items = [];
    for (let i = 0; i < organisations.length; i++) {
      items.push(
        <MenuItem value={organisations[i]._id} key={organisations[i]._id}>
          {organisations[i].name}
        </MenuItem>
      );
    }
    return items;
  }

  genPlayers() {
    const { players } = this.state;
    let items = [];
    if (players) {
      for (let i = 0; i < players.length; i++) {
        items.push(
          <MenuItem value={i} key={i}>
            {players[i].first_name + " " + players[i].last_name}
          </MenuItem>
        );
      }
    }
    return items;
  }

  genOrgSelect() {
    const { classes } = this.props;
    const { select } = this.state;
    return (
      <FormControl fullWidth
        style={selector}
        variant="outlined"
        className={classes.formControl}
      >
        {/* <InputLabel id="org_select_label">Organisation</InputLabel> */}
        <Select
          labelId="org_select"
          id="org_select"
          name="org"
          value={select.org}
          onChange={this.handleSelect}
          style={selector2}
        >
          {this.genOrgs()}
        </Select>
      </FormControl>
    );
  }

  genPlayerSelect() {
    const { classes } = this.props;
    const { select } = this.state;
    return (
      <FormControl
        style={selector}
        variant="outlined"
        className={classes.formControl}
      >
        {/* <InputLabel id="player_select_label">Player</InputLabel> */}
        <Select
          labelId="player_select"
          id="player_select"
          name="player"
          value={select.player}
          onChange={this.handleSelect}
          style={selector2}
        >
          {this.genPlayers()}
        </Select>
      </FormControl>
    );
  }

  render() {
    const { classes } = this.props;
    const { healthcheck, medicalQuestions, submittedHealthcheck } = this.state;

    if (submittedHealthcheck) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    return (
      <ValidatorForm
        ref={ref => (this.form = ref)}
        className={classes.form}
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
        <GridContainer>
          <GridItem>
            <h3 style={h3Style} >Add Health Check</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              {/* <br></br> */}
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Organisation
                    </h5>
                    {this.genOrgSelect()}
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Player
                    </h5>
                    {this.genPlayerSelect()}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h4 style={h3Style2} >
                        <br></br>
                        Medical history questionnaire
                        <br></br>
                        {/* <br></br> */}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Family history
                    </h5>
                  <TextField
                      id="filled-full-width"
                      // label="Family history"
                      style={{ margin: 8 }}
                      helperText="Please add or edit family medical history"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={3}
                      variant="outlined"
                      name="family_history"
                      onChange={this.handleChange}
                      value={medicalQuestions.family_history}
                    />
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Medical history
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Medical history"
                      style={{ margin: 8 }}
                      helperText="Please add or edit your medical history"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={3}
                      variant="outlined"
                      name="medical_history"
                      onChange={this.handleChange}
                      value={medicalQuestions.medical_history}
                    />
                    <br />
                    <h5 style={{ margin: 8 }}>
                    Allergies
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Allergies"
                      style={{ margin: 8 }}
                      helperText="Please add or edit your allergies"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={3}
                      variant="outlined"
                      name="allergies"
                      onChange={this.handleChange}
                      value={medicalQuestions.allergies}
                    />
                    <br />
                    </GridItem>

                    <GridItem xs={12} sm={6}>

                    <h5 style={{ margin: 8 }}>
                    Previous surgeries
                    </h5>
                    <TextField
                    id="filled-full-width"
                    // label="Previous surgeries"
                    style={{ margin: 8 }}
                    helperText="Please add or edit your previous surgeries"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    multiline
                    rows={3}
                    variant="outlined"
                    name="previous_surgeries"
                    onChange={this.handleChange}
                    value={medicalQuestions.previous_surgeries}
                  />
                  <br />
                  <h5 style={{ margin: 8 }}>
                  Previous injuries
                    </h5>
                  <TextField
                    id="filled-full-width"
                    // label="Previous injuries"
                    style={{ margin: 8 }}
                    helperText="Please add or edit your previous injuries"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    multiline
                    rows={3}
                    variant="outlined"
                    name="previous_injuries"
                    onChange={this.handleChange}
                    value={medicalQuestions.previous_injuries}
                  />
                  </GridItem>
                </GridContainer> 
                {/* <h4
                  style={metricCardTextAlign}
                  className={classes.cardTitleBlack}
                >
                  <br></br>
                  <br></br>
                  Lifestyle questionnaire
                </h4> */}
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h4 style={h3Style2} >
                        <br></br>
                        Lifestyle questionnaire
                        <br></br>
                        {/* <br></br> */}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Sleep Quality
                    </h5>

                    <TextField
                      id="filled-full-width"
                      // label="Sleep Quality"
                      style={{ margin: 8 }}
                      helperText="Unit: hours"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="sleep_quality"
                      onChange={this.handleChange}
                      value={healthcheck.sleep_quality}
                    />
                    <span style={{color: "red"}}>{this.state.errors["sleep_quality"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                    Stress Level
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Stress Level"
                      style={{ margin: 8 }}
                      helperText="Unit: 0-10"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="stress_level"
                      onChange={this.handleChange}
                      value={healthcheck.stress_level}
                    />
                    <span style={{color: "red"}}>{this.state.errors["stress_level"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                    Alcohol Intake
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Alcohol Intake"
                      style={{ margin: 8 }}
                      helperText="Unit: Alcohol units"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="alcohol_intake"
                      onChange={this.handleChange}
                      value={healthcheck.alcohol_intake}
                    />
                    <span style={{color: "red"}}>{this.state.errors["alcohol_intake"]}</span>
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                  <h5 style={{ margin: 8 }}>
                  Smoking Level
                    </h5>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    {/* <InputLabel required shrink id="filled-full-width"><br />Smoking Level</InputLabel> */}
                      <Select
                        id="filled-full-width"
                        // label="Smoking Level"
                        style={{ margin: 8 }}
                        fullWidth
                        
                        variant="outlined"
                        name="smoking_level"
                        onChange={this.handleChange}
                        value={healthcheck.smoking_level}
                      >
                        <MenuItem value={"1"}>Smoker</MenuItem>
                        <MenuItem value={"0"}>Non-smoker</MenuItem>
                      </Select>
                      <FormHelperText>Please select an option</FormHelperText>
                      <span style={{color: "red"}}>{this.state.errors["smoking_level"]}</span>
                    </FormControl>
                    <br />
                    <br />
                    <h5 style={{ margin: 8 }}>
                    Diet Quality
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Diet Quality"
                      style={{ margin: 8 }}
                      helperText="Unit: 0-10"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="diet_quality"
                      onChange={this.handleChange}
                      value={healthcheck.diet_quality}
                    />
                    <span style={{color: "red"}}>{this.state.errors["diet_quality"]}</span>
                  </GridItem>
                </GridContainer>
                {/* <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Physical
                  </h4>
                </GridItem> */}
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h4 style={h3Style2} >
                        <br></br>
                        Physical
                        <br></br>
                        {/* <br></br> */}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Systolic Blood Pressure
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Systolic Blood Pressure"
                      style={{ margin: 8 }}
                      helperText="Unit: mmHg"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="blood_pressure_systolic"
                      onChange={this.handleChange}
                      value={healthcheck.blood_pressure_systolic}
                    />
                    <span style={{color: "red"}}>{this.state.errors["blood_pressure_systolic"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Blood Glucose
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Blood Glucose"
                      style={{ margin: 8 }}
                      helperText="Unit: mmol/L"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="blood_glucose"
                      onChange={this.handleChange}
                      value={healthcheck.blood_glucose}
                    />
                    <span style={{color: "red"}}>{this.state.errors["blood_glucose"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Cholesterol Level
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Cholesterol Level"
                      style={{ margin: 8 }}
                      helperText="Unit: mmol/L"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="cholesterol_level"
                      onChange={this.handleChange}
                      value={healthcheck.cholesterol_level}
                    />
                    <span style={{color: "red"}}>{this.state.errors["cholesterol_level"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Heart Rate
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Heart Rate"
                      style={{ margin: 8 }}
                      helperText="Unit: BPM"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="heart_rate"
                      onChange={this.handleChange}
                      value={healthcheck.heart_rate}
                    />
                    <span style={{color: "red"}}>{this.state.errors["heart_rate"]}</span>
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Diastolic Blood Pressure
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Diastolic Blood Pressure"
                      style={{ margin: 8 }}
                      helperText="Unit: mmHg"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="blood_pressure_diastolic"
                      onChange={this.handleChange}
                      value={healthcheck.blood_pressure_diastolic}
                    />
                    <span style={{color: "red"}}>{this.state.errors["blood_pressure_diastolic"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Weight
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Weight"
                      style={{ margin: 8 }}
                      helperText="Unit: KG"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="weight"
                      onChange={this.handleChange}
                      value={healthcheck.weight}
                    />
                    <span style={{color: "red"}}>{this.state.errors["weight"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Oxygen Level
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Oxygen Level"
                      style={{ margin: 8 }}
                      helperText="Unit: %"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="oxygen_level"
                      onChange={this.handleChange}
                      value={healthcheck.oxygen_level}
                    />
                    <span style={{color: "red"}}>{this.state.errors["oxygen_level"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Height
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Height"
                      style={{ margin: 8 }}
                      helperText="Unit: cm"
                      fullWidth
                      margin="normal"
                      required
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="height"
                      onChange={this.handleChange}
                      value={healthcheck.height}
                    />
                    <span style={{color: "red"}}>{this.state.errors["height"]}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Body Temperature
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Body Temperature"
                      style={{ margin: 8 }}
                      helperText="Unit: °C"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="body_temperature"
                      onChange={this.handleChange}
                      value={healthcheck.body_temperature}
                    />
                    <span style={{color: "red"}}>{this.state.errors["body_temperature"]}</span>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Airway Peak Flow
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Airway Peak Flow"
                      style={{ margin: 8 }}
                      helperText="Unit: L/min"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="airway_peak_flow"
                      onChange={this.handleChange}
                      value={healthcheck.airway_peak_flow}
                    />
                    <span style={{color: "red"}}>{this.state.errors["airway_peak_flow"]}</span>
                  </GridItem>
                </GridContainer>
                {/* <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Fitness
                  </h4>
                </GridItem> */}
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h4 style={h3Style2} >
                        <br></br>
                        Fitness
                        <br></br>
                        {/* <br></br> */}
                    </h4>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Beep test score (Number)
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Beep test score (Number)"
                      style={{ margin: 8 }}
                      helperText="Unit: 0-23"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="beep_test"
                      onChange={this.handleChange}
                      value={healthcheck.beep_test}
                    />
                    <span style={{color: "red"}}>{this.state.errors["beep_test"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Vertical jump
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Vertical jump"
                      style={{ margin: 8 }}
                      helperText="Unit: cm"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="vertical_jump"
                      onChange={this.handleChange}
                      value={healthcheck.vertical_jump}
                    />
                    <span style={{color: "red"}}>{this.state.errors["vertical_jump"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      100m Sprint
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="100m Sprint"
                      style={{ margin: 8 }}
                      helperText="Unit: seconds"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="hundred_meter_sprint"
                      onChange={this.handleChange}
                      value={healthcheck.hundred_meter_sprint}
                    />
                    <span style={{color: "red"}}>{this.state.errors["hundred_meter_sprint"]}</span>
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <h5 style={{ margin: 8 }}>
                      Beep test score (Decimal)
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Beep test score (Decimal)"
                      style={{ margin: 8 }}
                      helperText="Unit: 0-12"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="beep_test_decimal"
                      onChange={this.handleChange}
                      value={healthcheck.beep_test_decimal}
                    />
                    <span style={{color: "red"}}>{this.state.errors["beep_test_decimal"]}</span>
                    <br />
                    <h5 style={{ margin: 8 }}>
                      Reaction time
                    </h5>
                    <TextField
                      id="filled-full-width"
                      // label="Reaction time"
                      style={{ margin: 8 }}
                      helperText="Unit: seconds"
                      fullWidth
                      margin="normal"
                      inputProps={{ 
                        type: 'number',
                        inputmode: 'numeric', 
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      name="reaction_time"
                      onChange={this.handleChange}
                      value={healthcheck.reaction_time}
                    />
                    <span style={{color: "red"}}>{this.state.errors["reaction_time"]}</span>
                    <br />
                    {/* <br /> */}
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Notes
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add all other relevant information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="notes"
                      onChange={this.handleChange}
                      value={healthcheck.notes}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Neurovascular Assessment
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="neuro_assessment"
                      onChange={this.handleChange}
                      value={healthcheck.neuro_assessment}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Biomechanical Assessment
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add all other relevant information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="biomech_assessment"
                      onChange={this.handleChange}
                      value={healthcheck.biomech_assessment}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Ultrasound Results
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="ultrasound_results"
                      onChange={this.handleChange}
                      value={healthcheck.ultrasound_results}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Diet Assessment
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="diet_assessment"
                      onChange={this.handleChange}
                      value={healthcheck.diet_assessment}
                    />
                  </GridItem>
                </GridContainer>
                <GridItem xs={12}>
                  <h5 style={{ margin: 8 }}>
                  Blood Results
                    </h5>

                </GridItem>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      id="filled-full-width"
                      style={{ margin: 8 }}
                      helperText="Please add information here"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      multiline
                      rows={4}
                      variant="outlined"
                      name="blood_results"
                      onChange={this.handleChange}
                      value={healthcheck.blood_results}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button style={submitButton} color="primary" type="submit">
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </ValidatorForm>
    );
  }
}

NewHealthCheck.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(NewHealthCheck)
);
