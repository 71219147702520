import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputAdornment, Icon, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from "axios";
import qs from "qs";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import logo from "assets/img/THC_Logo_Compact.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://totalhealthcheck.org/">
        THC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#62c5c4"
  },
  eye: {
    cursor: "pointer"
  },
  logoImage: {
    margin: theme.spacing(1)
  },
  img: {
    maxHeight: "150px"
  },
  errorMessage: {
    textAlign: "center",
    color: "#ff0033",
    width: "max-content"
  }
});
const title = {
  textAlign: "center",
  fontSize: "2em",
  padding: "0px",
  margin: "0px"
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: "",
        password: ""
      },
      passwordIsMasked: true,
      isLoggedIn: false,
      error: false
    };
  }

  handleChange = event => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  handleSubmit = event => {
    event.preventDefault();
    const data = this.state.user;

    axios({
      method: "post",
      url: "/api/users/login",
      data: qs.stringify(data),
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    })
      .then(response => {
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("expiration", response.data.expiration);
        localStorage.setItem("userID", response.data.userID);
        this.setState({ isLoggedIn: true });
      })
      .catch(error => {
        let err_text = null;
        if (error.response.status === 401) {
          err_text =
            "You have entered an invalid email/password combination.\nPlease try again.";
        } else {
          err_text = error.response.data.error;
        }
        this.setState({ error: err_text });
      });
  };

  togglePasswordMask = () => {
    this.setState(prevState => ({
      passwordIsMasked: !prevState.passwordIsMasked
    }));
  };

  getEyeIcon = () => {
    let icon = "visibility";
    if (this.state.passwordIsMasked) {
      icon = icon + "_off";
    }
    return icon;
  };

  render() {
    const { classes } = this.props;
    const { user } = this.state;
    const { passwordIsMasked } = this.state;
    const { isLoggedIn, error } = this.state;

    if (isLoggedIn) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    let errorMessage = "";
    if (error) {
      errorMessage = (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          {error}
        </Typography>
      );
    }

    return (
      <Container component="main" maxWidth="xs">
        <Helmet>
          <title>Login | TotalHealthCheck</title>
        </Helmet>
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.logoImage}>
            <img src={logo} alt="logo" className={classes.img} />
          </div>
          <h2 style={title} className={classes.cardTitleBlack}>
            Sign In
          </h2>
          {errorMessage}
          <ValidatorForm
            ref={ref => (this.form = ref)}
            className={classes.form}
            onSubmit={this.handleSubmit}
            onError={errors => console.log(errors)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange}
                  value={user.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "This field is required",
                    "Invalid email address"
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={passwordIsMasked ? "password" : "text"}
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleChange}
                  value={user.password}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon
                          className={classes.eye}
                          onClick={this.togglePasswordMask}
                        >
                          {this.getEyeIcon()}
                        </Icon>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="forgot" variant="body2">
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="signup" variant="body2">
                  Don&apos;t have an account? Sign up
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(Login));
