import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { deserializeDate, serializeDate } from "libs/dates.js";

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};

const styles = () => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  errorMessage: {
    textAlign: "center",
    color: "#ff0033"
  },
  successMessage: {
    textAlign: "center",
    color: "#00E676"
  }
});

class AddPractitioner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isLoading: false,
      error: null,
      submitted: false,
      selectedDate: new Date()
    };
  }

  handleChange = event => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  handleDate = date => {
    this.setState({ selectedDate: date});
  };

  handleSubmit = event => {
    event.preventDefault();
    const data = this.state.user;
    data.account_type = "Practitioner";
    data.password = uuidv4();
    const { selectedDate } = this.state;
    data.date_of_birth = serializeDate(selectedDate);
    data.organisation_id = "606de4eded3459018c97e614";


    

    axios
      .post(
        "/api/users/register",
        { user: data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(response => {
        if (response.status === 201) {
          this.setState({ submitted: true });
        }
      })
      .catch(error => {
        this.setState({ error: error.response });
      });
  };

  render() {
    const { classes } = this.props;
    const { error, user, submitted, selectedDate } = this.state;

    let errorMessage = "";
    if (error) {
      errorMessage = (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          {error.data.error}
        </Typography>
      );
    }

    let submittedMessage = "";
    if (submitted) {
      submittedMessage = (
        <div>
          <Typography
            className={classes.successMessage}
            component="h1"
            variant="subtitle1"
          >
            Practitioner {user.first_name} successfully added.
          </Typography>
          <br></br>
        </div>
      );
    }

    return (
      <ValidatorForm
        ref={ref => (this.form = ref)}
        className={classes.form}
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <h4 style={title} className={classes.cardTitleBlack}>
                  Add Practitioner
                </h4>
                {errorMessage}
                {submittedMessage}
                <GridContainer>
                  <GridItem xs={12}>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      Practitioner Details
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="First Name"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="first_name"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={user.first_name}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Last Name"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="last_name"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={user.last_name}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Email"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="email"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={user.email}
                    />
                    <br />
                    <br />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          label="Date Of Birth"
                          value={selectedDate}
                          required
                          fullWidth
                          onChange={this.handleDate}
                          disableFuture
                          format="dd/MM/yyyy"
                          inputVariant="filled"
                          // minDate={nextDay}
                        />
                      <br />
                      <br />
                    </MuiPickersUtilsProvider>
                  </GridItem>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </ValidatorForm>
    );
  }
}

AddPractitioner.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(AddPractitioner)
);
