import React from "react";
import axios from "axios";

// core components
import Grid from "@material-ui/core/Grid";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { serializeDate } from "libs/dates.js";
import { calculateHealthCheckMetricNumbers } from "libs/healthchecks.js";

const circleRed = {
  width: "35px",
  height: "35px",
  background: "#d44",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  color: "#fff"
};
const circleYellow = {
  width: "35px",
  height: "35px",
  background: "#FFCC00",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  color: "#fff"
};
const circleGreen = {
  width: "35px",
  height: "35px",
  background: "#33CC00",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  padding: "0px",
  margin: "0px",
  color: "#fff"
};

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em",
  fontWeight: "500"
};

const metricCardTextAlignLarge = {
  textAlign: "center",
  fontSize: "3em",
  lineHeight: "1.4em",
  fontWeight: "900"
};

const metricCardMargin = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};

class HealthCheckHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      healthchecks: null,
      sort: "oldest",
      fullName: "",
      pracNamesMap: []

    };
  }

  getHealthChecks() {
    let userID = localStorage.getItem("userID");
    axios
      .get("/api/healthchecks/getAllByUser/" + userID)
      .then(response => {
        let healthchecks = response.data.healthchecks;
        let validHealthChecks = [];
        for (let i = 0; i < healthchecks.length; i++) {
          healthchecks[i].date = new Date(healthchecks[i].date);
          if (!healthchecks[i] || !healthchecks[i].date) {
            continue;
          }
          validHealthChecks.push(healthchecks[i]);
        }
        this.setState({
          healthchecks: validHealthChecks,
          isLoading: false
        });
        // this.setPracNameMap(healthchecks);
      })
      .catch(error => this.setState({ error }));
  }

  // setPracNameMap(healthchecks){
    
  //   const { pracNamesMap } = this.state;


  //   for (let i = 0; i < healthchecks.length; i++) {

  //     let practitionerId = healthchecks[i].practitioner_id;
  //     console.log(practitionerId);


  //     pracNamesMap[practitionerId] = this.getPractitionerName(practitionerId);
  //     console.log(pracNamesMap);

  //   }

  //   this.setState({
  //     pracNamesMap: pracNamesMap
  //   });

  // }

  // getPractitionerName(practitionerId){
    
  //   // var fullNameText = "";
  //   // var practitionerId = healthcheck.practitioner_id;

  //   axios
  //     .get("/api/users/getByID/" + practitionerId)
  //     .then(response => {
  //       var practitioner = response.data;
  //       console.log(practitioner);

  //       let fullNameText = practitioner.first_name + " " + practitioner.last_name;
  //       // console.log(fullName);
  //       // let validHealthChecks = [];
  //       // for (let i = 0; i < healthchecks.length; i++) {
  //       //   healthchecks[i].date = new Date(healthchecks[i].date);
  //       //   if (!healthchecks[i] || !healthchecks[i].date) {
  //       //     continue;
  //       //   }
  //       //   validHealthChecks.push(healthchecks[i]);
  //       // }
  //       this.setState({
  //         fullName: fullNameText
  //       });
  //       console.log(fullNameText);
  //       // this.getPractionerNameString(fullNameText);

  //     })
  //     .catch(error => this.setState({ error }));
  //     // return this.state.fullName;


  // }

  generateHealthCheckCards = () => {
    const { classes } = this.props;
    const { healthchecks, sort, fullName } = this.state;

    // let pracNamesMap = [];

    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };


    let checks = [];
    if (healthchecks != null && healthchecks.length > 0) {
      let sortFunction = this.compareDateOldest;
      switch (sort) {
        case "oldest":
          sortFunction = this.compareDateOldest;
          break;
        case "newest":
          sortFunction = this.compareDateNewest;
          break;
        default:
          sortFunction = this.compareDateOldest;
      }
      healthchecks.sort(sortFunction);

      // var conducterMan = this.getPractitionerName(healthchecks["0"].practitioner_id);

      console.log(healthchecks.length);

      // this.getPractitionerName("5e5d7bafa41be00e30d9776b");


      for (let i = 0; i < healthchecks.length; i++) {
        let date = new Date(healthchecks[i].date).toLocaleDateString(
          "en-US",
          options
        )
        
        healthchecks[i].date.toDateString();
        
        let practitionerId = healthchecks[i].practitioner_id;
        // this.getPractitionerName(healthchecks[i].practitioner_id);
        console.log(practitionerId);
        // console.log(conducterMan);
        // let conducter = this.getPractitionerName(practitionerId);
        let conducter = "John";
        console.log(conducter);

        
        // if(healthchecks[i].practitioner_name){
        //   conducter = healthchecks[i].practitioner_name
        // }
        let trafficLightNumbers = calculateHealthCheckMetricNumbers(
          healthchecks[i]
        );

        console.log(trafficLightNumbers);
        
        // if ()
        checks.push(
          <GridItem xs={12} sm={6} md={6} key={i}>
            <Link to={`/dashboard/healthcheck/${healthchecks[i]._id}`}>
              <Card color="primary">
                <CardBody>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    {"Date of assessment: "}
                  </h4>
                  <h2
                    style={metricCardTextAlignLarge}
                    className={classes.cardTitleBlack}
                  >
                    {date}
                  </h2>
                  {/* <h3 style={metricCardMargin}>Conducted by: {fullName}</h3> */}
                </CardBody>
                <CardFooter stats>
                  <GridContainer>
                    <GridItem xs={3}>
                      <div style={circleRed}>{trafficLightNumbers.red}</div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={circleYellow}>
                        {trafficLightNumbers.orange}
                      </div>
                    </GridItem>
                    <GridItem xs={3}>
                      <div style={circleGreen}>{trafficLightNumbers.green}</div>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </Link>
          </GridItem>
        );
      }
    } else {
      checks.push(
        <GridItem xs={12} sm={6} key="Teaser">
          <Card color="primary">
            <CardBody>
              <h3 style={metricCardMargin}>No healthchecks found!</h3>
            </CardBody>
            <CardFooter stats></CardFooter>
          </Card>
        </GridItem>
      );
    }

    return checks;
  };

  componentDidMount() {
    this.getHealthChecks();
    // this.getPractitionerName(healthcheck);
  }

  handleChange = event => {
    this.setState({ sort: event.target.value });
  };

  compareDateOldest(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  compareDateNewest(a, b) {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  }

  render() {
    const { classes } = this.props;
    const { isLoading, error, sort, healthchecks } = this.state;

    // console.log(this.getPractitionerName(healthchecks["0"]));

    return (
      <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <GridContainer>
              <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
              >
                <GridItem xs={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="sortSelectLabel">Order</InputLabel>
                    <Select
                      labelId="sortSelect"
                      id="sortSelect"
                      value={sort}
                      onChange={this.handleChange}
                    >
                      <MenuItem value="newest">Newest First</MenuItem>
                      <MenuItem value="oldest">Oldest First</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={4}>
                  <h4 style={title} className={classes.cardTitleBlack}>
                    Health Check History
                  </h4>
                </GridItem>
                <GridItem xs={4}></GridItem>
                {this.generateHealthCheckCards()}
              </Grid>
            </GridContainer>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

HealthCheckHistoryPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(HealthCheckHistoryPage)
);
