import React from "react";
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from "react-router-dom";
import Helmet from "react-helmet";

// core components
import Dashboard from "layouts/Dashboard.js";
import SignUp from "views/SignUp/SignUp.js";
import Verification from "views/Verification/Verification.js";
import SignUpConfirmation from "views/SignUp/SignUpConfirmation.js";
import Login from "views/Login/Login.js";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute.js";
import RestrictedRoute from "./components/RestrictedRoute/RestrictedRoute.js";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import ScrollToTop from "components/ScrollToTop.js";

function App() {
  const hist = createBrowserHistory();

  return (
    <div>
      <Helmet>
        <title>Total Health Check</title>
      </Helmet>
      <Router history={hist}>
        {/* <ScrollToTop /> */}
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/signup/:invite" component={SignUp} />
          <Route path="/signup-confirmation" component={SignUpConfirmation} />
          <Route path="/verify/:verification" component={Verification} />
          <RestrictedRoute path="/login" component={Login} />
          <RestrictedRoute path="/forgot" component={ForgotPassword} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
