import React from "react";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter, Redirect } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

class SignOutPage extends React.Component {
  logout = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("expiration", -1);
  };

  render() {
    this.logout();
    return <Redirect to={{ pathname: "/" }} />;
  }
}

SignOutPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(SignOutPage));
