import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        THC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  eye: {
    cursor: "pointer"
  }
});

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: ""
      }
    };
  }

  handleChange = event => {
    const { user } = this.state;
    user[event.target.name] = event.target.value;
    this.setState({ user });
  };

  handleSubmit = event => {
    event.preventDefault();
    const data = this.state.user;

    axios
      .post(
        "/api/users/forgot",
        { user: data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(() => {
        this.props.history.push("/login");
      })
      .catch(function(error) {
        console.log(error.response);
      });
  };

  render() {
    const { classes } = this.props;
    const { user } = this.state;

    return (
      <Container component="main" maxWidth="xs">
        <Helmet>
          <title>Forgot Password</title>
        </Helmet>
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <ValidatorForm
            ref={ref => (this.form = ref)}
            className={classes.form}
            onSubmit={this.handleSubmit}
            onError={errors => console.log(errors)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={this.handleChange}
                  value={user.email}
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "This field is required",
                    "Invalid email address"
                  ]}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
            <Grid container justify="flex-end">
              <Grid item>
                <Link href="login" variant="body2">
                  Know your password? Log in
                </Link>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(
  withRouter(ForgotPassword)
);
