import React, { Component } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";

class LogoutMenuItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedOut: false
    };
  }

  logout = () => {
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("expiration", -1);
    this.setState({ isLoggedOut: true });
  };

  render() {
    const { isLoggedOut } = this.state;

    if (isLoggedOut) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    return (
      <MenuItem onClick={this.logout} className={this.props.className}>
        Logout
      </MenuItem>
    );
  }
}

LogoutMenuItem.propTypes = {
  className: PropTypes.string
};

export default withRouter(LogoutMenuItem);
