import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../context/auth";
import PropTypes from "prop-types";

function RestrictedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/dashboard", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

RestrictedRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any
};

export default RestrictedRoute;
