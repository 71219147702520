import React from "react";
import axios from "axios";

// @material-ui/core
// @material-ui/icons

import Divider from "@material-ui/core/Divider";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const selector = {
  minWidth: "150",
  width: "100%"
};

const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const title = {
  textAlign: "center",
  fontSize: "2em"
};

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: [],
      select: {
        org: "",
        player: ""
      },
      organisations: [],
      healthcheck: [],
      nextAppointment: null,
      error: null,
      organisation: null,
      players: [],
      checkBoxBoolean: false,
      currentPlayer: "",
      todayCheckBooleanArray: [],
      checkBoxBooleanValue: false
    };
  }

  handleSelect = event => {
    const { select } = this.state;
    select[event.target.name] = event.target.value;
    this.setState({ select });

    const organisation = select.org;

    this.getPlayersByOrg(organisation);

  };

  getUser(userID) {
    axios
      .get(`/api/users/getByID/${userID}`)
      .then(response => {
        this.setState({ user: response.data });
        this.getNextAppointment(response.data.nextAppointment);
        this.getLatestHealthCheck(response.data._id);
        this.getOrganisations();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getOrganisations() {
    axios
      .get("/api/organisations/all")
      .then(response => {
        this.setState({
          organisations: response.data.organisations,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error }));
  }

  getLatestHealthCheck(userID) {
    axios
      .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
      .then(response => {
        this.setState({
          healthcheck: response.data.healthcheck,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getNextAppointment(appointment) {
    if (appointment) {
      axios
        .get(`/api/bookings/get/${appointment}`)
        .then(response => {
          this.setState({
            nextAppointment: response.data.booking
          });
        })
        .catch(error => this.setState({ error }));
    }
  }

  genOrgs() {
    const { organisations } = this.state;
    let items = [];
    for (let i = 0; i < organisations.length; i++) {
      items.push(
        <MenuItem value={organisations[i]._id} key={organisations[i]._id}>
          {organisations[i].name}
        </MenuItem>
      );
    }
    return items;
  }

  genOrgSelect() {
    const { classes } = this.props;
    const { select } = this.state;
    return (
      <FormControl
        style={selector}
        variant="filled"
        className={classes.formControl}
      >
        <InputLabel id="org_select_label">Organisation</InputLabel>
        <Select
          labelId="org_select"
          id="org_select"
          name="org"
          value={select.org}
          onChange={this.handleSelect}
          style={selector}
        >
          {this.genOrgs()}
        </Select>
      </FormControl>
    );
  }

  checkForAHealthCheckToday(userID) {
    axios
      .get(`/api/healthchecks/checkForAHealthCheckToday/${userID}`)
      .then(response => {

        var checkBoxBoolean  = response.data;

        this.setState({
          isLoading: false
        });

        this.addToPlayersCheckListArray(checkBoxBoolean ,userID);

      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  addToPlayersCheckListArray(checkBoxBoolean, userID){
    var { todayCheckBooleanArray } = this.state;

    todayCheckBooleanArray[userID] = checkBoxBoolean;

    this.setState({
          isLoading: false
        });
  } 

  getPlayersByOrg(organisation) {
    
    axios
      .get("/api/users/getByOrg/" + organisation)
      .then(response => {
        this.setState({
          players: response.data,
        });

        this.setCheckBoxBooleanMap();

      })
      .catch(error => this.setState({ error, isLoading: false }));
  }


  setCheckBoxBooleanMap(){
    const { players } = this.state;

    var objList = [];

    if (players) {
      for (let i = 0; i < players.length; i++) {

        var currentPlayerId = players[i]._id;

        this.checkForAHealthCheckToday(currentPlayerId);

        var obj = {};

        var objItem = i;

        obj.objItem = players[i]["_id"];

        objList[objItem] = obj.objItem;
      }
    }
  }

  calculateBodyMassIndex(weight, height) {
    var bmi = (weight / (height * height)) * 10000;
    return parseFloat(bmi).toPrecision(3);
  }

  calculateWeightInLbs(weight) {
    var weightInLbs = weight * 2.205;
    return Math.round(weightInLbs);
  }

  calculateHeightInFeet(height) {
    var heightInFeet = height / 30.48;
    var feet = Math.trunc(heightInFeet);
    var inches = (parseFloat(heightInFeet % 1) * 12).toFixed(0);

    if (inches === 12.0) {
      inches = 0;
      feet = feet + 1;
    }

    return feet + "'" + inches + '"';
  }

  convertDateOfBirth(dob) {
    var date = new Date(dob);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    } else if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  }

  componentDidMount() {
    let userID = localStorage.getItem("userID");
    this.getUser(userID);
    this.renderMetrics();
  }

  renderNextAppointment() {
    const { classes } = this.props;
    const { nextAppointment } = this.state;

    let card = "";
    if (nextAppointment) {
      card = (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h4 className={classes.cardTitle}>Next Appointment</h4>
                <p className={classes.cardCategory}>
                  {nextAppointment.date} at {nextAppointment.time}
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }
    return card;
  }

  

  renderMetrics() {
    const { classes } = this.props;
    const { players, todayCheckBooleanArray } = this.state;

      return (
        <div>
          <List>
            {players.map((value) => {
              for (let i = 0; i < players.length; i++) {

                this.state.checkBoxBooleanValue = todayCheckBooleanArray[value._id];

                const labelId = `checkbox-list-label-${value}`;

                  return (
                    <GridContainer>
                      <ListItem dense >
                        <ListItemIcon>
                          <Checkbox id={labelId}
                            edge="start"
                            checked={todayCheckBooleanArray[value._id] == true}
                            disableRipple
                            disabled
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.first_name} ${value.last_name}`} />
                      </ListItem>
                    </GridContainer>
                  );
              }
            })}
          </List>
        </div>
      );
  }

  render() {
    const { classes } = this.props;
    const { isLoading, user, healthcheck, error } = this.state;

    const height = healthcheck ? healthcheck.height : user.height;
    const weight = healthcheck ? healthcheck.weight : user.weight;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    return (
      <React.Fragment>
        <h4 style={title} className={classes.cardTitleBlack}>
          {user.first_name + " " + user.last_name}
        </h4>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div key={user.email}>
              <GridContainer>
                <GridItem xs={12} >
                  <h4
                  style={metricCardTextAlign}
                  className={classes.cardTitleBlack}
                  >
                    Please select an organisation to see a patient checklist for {today}:
                  </h4>
                </GridItem>
                <GridItem xs={12} >
                  {this.genOrgSelect()}
                </GridItem>
                <GridItem xs={12} >
                  <br />
                </GridItem>
                <GridItem xs={12} >
                  <br />
                </GridItem>
              </GridContainer>

              <Divider light />

              {this.renderNextAppointment()}

              <Divider light />

              {this.renderMetrics()}
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);
