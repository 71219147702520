import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../context/auth";
import PropTypes from "prop-types";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        auth.isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: props.location } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.any
};
