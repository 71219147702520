import React from "react";
import axios from "axios";

// @material-ui/core
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import Accessibility from "@material-ui/icons/Accessibility";
import Divider from "@material-ui/core/Divider";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { Link } from "react-router-dom";
import {
  getBloodGlucoseCardColour,
  getBloodPressureCardColour,
  getHeartRateCardColour,
  getOxygenLevelCardColour,
  getFootPostureIndexCardColour,
  getCholesterolLevelCardColour,
  getBodyMassIndexCardColour,
  getBodyTemperatureCardColour,
  getAirwayPeakFlowColour,
  getBeepTestColour,
  getVerticalJumpColour,
  getHundredMeterSprintColour,
  getReactionTimeColour,
  getAlcoholIntakeColour,
  getSleepQualityColour,
  getDietQualityColour,
  getSmokingLevelColour,
  getStressLevelColour
} from "libs/healthchecks.js";
import { getUser, getLatestHealthCheck, getNextAppointment }  from './getFuncs';


const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const metricCardMargin = {
  marginBottom: "20px",
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};

const metricCardTextAlignBold = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em",
  fontWeight: "900"
};

const title = {
  textAlign: "center",
  fontSize: "2em"
};

// class ScrollToTopOnMount extends React.Component {
//   componentDidMount() {
//     window.scrollTo(0, 0);
//   }

//   render() {
//     return null;
//   }
// }

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      user: [],
      healthcheck: [],
      nextAppointment: null,
      error: null,
      blood_pressure_sys_values: [],
      blood_pressure_dys_values: [],
      blood_glucose_values: [],
      oxygen_level_values: [],
      body_mass_index_values: [],
      airway_peak_flow_values: [],
      cholesterol_level_values: [],
      body_temperature_values: [],
      heart_rate_values: [],
      beep_test_values: [],
      beep_test_decimal_values: [],
      vertical_jump_values: [],
      hundred_meter_sprint_values: [],
      reaction_time_values: [],
      sleep_quality_values: [],
      stress_level_values: [],
      alcohol_intake_values: [],
      smoking_level_values: [],
      diet_quality_values: []

    };
  }

  getMetricData() {
    const { healthcheck, user } = this.state;

    let userID = localStorage.getItem("userID");

    console.log(userID)
    
      // blood_pressure_systolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_systolic`)
      .then(response => {
        this.setState({
          blood_pressure_sys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_pressure_diastolic //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_pressure_diastolic`)
      .then(response => {
        this.setState({
          blood_pressure_dys_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // blood_glucose //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/blood_glucose`)
      .then(response => {
        this.setState({
          blood_glucose_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
    
      // oxygen_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/oxygen_level`)
      .then(response => {
        this.setState({
          oxygen_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_mass_index //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_mass_index`)
      .then(response => {
        this.setState({
          body_mass_index_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // airway_peak_flow //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/airway_peak_flow`)
      .then(response => {
        this.setState({
          airway_peak_flow_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // cholesterol_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/cholesterol_level`)
      .then(response => {
        this.setState({
          cholesterol_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // body_temperature //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/body_temperature`)
      .then(response => {
        this.setState({
          body_temperature_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // heart_rate //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/heart_rate`)
      .then(response => {
        this.setState({
          heart_rate_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test`)
      .then(response => {
        this.setState({
          beep_test_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // beep_test_decimal //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/beep_test_decimal`)
      .then(response => {
        this.setState({
          beep_test_decimal_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // vertical_jump //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/vertical_jump`)
      .then(response => {
        this.setState({
          vertical_jump_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // hundred_meter_sprint //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/hundred_meter_sprint`)
      .then(response => {
        this.setState({
          hundred_meter_sprint_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // reaction_time //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/reaction_time`)
      .then(response => {
        this.setState({
          reaction_time_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // sleep_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/sleep_quality`)
      .then(response => {
        this.setState({
          sleep_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // stress_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/stress_level`)
      .then(response => {
        this.setState({
          stress_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // alcohol_intake //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/alcohol_intake`)
      .then(response => {
        this.setState({
          alcohol_intake_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));

      // smoking_level //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/smoking_level`)
      .then(response => {
        this.setState({
          smoking_level_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
      
      // diet_quality //
 
      axios
      .get(`/api/healthchecks/getOneMetricHistory/${userID}/diet_quality`)
      .then(response => {
        this.setState({
          diet_quality_values: response.data.metricValues
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }


  // getUser(userID) {
    
  //   return axios
  //     .get(`/api/users/getByID/${userID}`)
  //     .then(response => {
        
        // this.setState({ user: response.data });
  //       this.getNextAppointment(response.data.nextAppointment);
  //       this.getLatestHealthCheck(response.data._id);
  //       console.log(response);
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));

  // }


  // getLatestHealthCheck(userID) {
  //   axios
  //     .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
  //     .then(response => {
  //       this.setState({
  //         healthcheck: response.data.healthcheck,
  //         isLoading: false,
  //       });
  //       console.log(response);
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // };

  // getNextAppointment(appointment) {
  //   if (appointment) {
  //     axios
  //       .get(`/api/bookings/get/${appointment}`)
  //       .then(response => {
  //         this.setState({
  //           nextAppointment: response.data.booking
  //         });
  //       })
  //       .catch(error => this.setState({ error }));
  //   }
  // };

  calculateBodyMassIndex(weight, height) {
    var bmi = (weight / (height * height)) * 10000;
    return parseFloat(bmi).toPrecision(3);
  }

  calculateWeightInLbs(weight) {
    var weightInLbs = weight * 2.205;
    return Math.round(weightInLbs);
  }

  calculateHeightInFeet(height) {
    var heightInFeet = height / 30.48;
    var feet = Math.trunc(heightInFeet);
    var inches = (parseFloat(heightInFeet % 1) * 12).toFixed(0);

    if (inches === 12.0) {
      inches = 0;
      feet = feet + 1;
    }

    return feet + "'" + inches + '"';
  }

  convertDateOfBirth(dob) {
    var date = new Date(dob);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    } else if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  }

  

  componentDidMount() {
    // const { healthcheck, user, nextAppointment } = this.state;
    let userID = localStorage.getItem("userID");

    let userObjects = [];
    let healthcheckObjects = [];
    let nextAppointmentObjects = [];
    
    // let userObjectFinal = [];
    // let healthcheckObjectFinal = [];
    // let nextAppointmentObjectFinal = [];

    this.getMetricData();

    async function getCurrentUser(userID) {
      try {
        await getUser(userID)
          .then(userObject => {

            userObjects = userObject;
            // console.log(userObject);
            return userObjects;

          }).then(userObjects => {

            // userObjectFinal = userObjects;
            
            return userObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return userObjects;
    };

    async function getUsersLatestHealthCheck(userID) {
      try {
        await getLatestHealthCheck(userID)
          .then(healthcheckObject => {

            healthcheckObjects = healthcheckObject;
            // console.log(healthcheckObject);
            return healthcheckObjects;

          }).then(healthcheckObjects => {

            // healthcheckObjectFinal = healthcheckObjects;
            
            return healthcheckObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return healthcheckObjects;
    };

    async function getUsersNextAppointment(appointment) {
      try {
        await getNextAppointment(appointment)
          .then(nextAppointmentObject => {

            nextAppointmentObjects = nextAppointmentObject;
            // console.log(nextAppointmentObjects);
            return nextAppointmentObjects;

          }).then(nextAppointmentObjects => {

            // nextAppointmentObjectFinal = nextAppointmentObjects;
            
            return nextAppointmentObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return nextAppointmentObjects;
    };


    getCurrentUser(userID).then(userObjects => { 
      this.setState({ user: userObjects });
      console.log(userObjects);
      // this.getNextAppointment(userObjects.nextAppointment);

      if (userObjects.next_appointment){
        getUsersNextAppointment(userObjects.next_appointment)
        .then(nextAppointmentObjects => { 
        this.setState({ nextAppointment: nextAppointmentObjects });
        console.log(nextAppointmentObjects);
        });
      }
      

    });

    getUsersLatestHealthCheck(userID)
    .then(healthcheckObjects => { 
      this.setState({ healthcheck: healthcheckObjects });
      console.log(healthcheckObjects);
    });

    

    // console.log(nextAppointment);


  };


  

  renderNextAppointment() {
    const { classes } = this.props;
    const { nextAppointment } = this.state;

    console.log(nextAppointment);

    let card = "";
    if (nextAppointment) {
      card = (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <h4 className={classes.cardTitle}>Next Appointment</h4>
                <p className={classes.cardCategory}>
                  {nextAppointment.booking.date} at {nextAppointment.booking.time}
                </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      );
    }
    return card;
  }

  allAreNull(arr) {
    return arr.every(element => element === null);
  }

  healthTitle(){
    const { classes } = this.props;
    let {
      blood_pressure_sys_values,
      blood_pressure_dys_values,
      blood_glucose_values,
      oxygen_level_values,
      body_mass_index_values,
      airway_peak_flow_values,
      cholesterol_level_values,
      body_temperature_values,
      heart_rate_values,
    } = this.state;

    var latest_blood_pressure_sys_value = blood_pressure_sys_values.find(el => el !== null);
    var latest_blood_pressure_dys_value = blood_pressure_dys_values.find(el => el !== null);
    var latest_blood_glucose_value = blood_glucose_values.find(el => el !== null);
    var latest_oxygen_level_value = oxygen_level_values.find(el => el !== null);
    var latest_body_mass_index_value = body_mass_index_values.find(el => el !== null);
    var latest_airway_peak_flow_value = airway_peak_flow_values.find(el => el !== null);
    var latest_cholesterol_level_value = cholesterol_level_values.find(el => el !== null);
    var latest_body_temperature_value = body_temperature_values.find(el => el !== null);
    var latest_heart_rate_value = heart_rate_values.find(el => el !== null);

    if (!(latest_blood_pressure_sys_value == undefined && 
      latest_blood_pressure_dys_value == undefined &&
      latest_blood_glucose_value == undefined && 
      latest_oxygen_level_value == undefined &&
      latest_body_mass_index_value == undefined && 
      latest_airway_peak_flow_value == undefined &&
      latest_cholesterol_level_value == undefined && 
      latest_body_temperature_value == undefined && 
      latest_heart_rate_value == undefined)){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Health
          </h4>
        </GridItem>


      )
    }
  }

  fitnessTitle(){
    const { classes } = this.props;
    let {
      beep_test_values,
      vertical_jump_values,
      hundred_meter_sprint_values,
      reaction_time_values
    } = this.state;

    var latest_beep_test_value = beep_test_values.find(el => el !== null);
    var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    var latest_reaction_time_value = reaction_time_values.find(el => el !== null);

    if (!(
      latest_beep_test_value == undefined && 
      latest_vertical_jump_value == undefined &&
      latest_hundred_meter_sprint_value == undefined && 
      latest_reaction_time_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Fitness
          </h4>
        </GridItem>


      )
    }
  }

  lifestyleTitle(){
    const { classes } = this.props;
    let {
      sleep_quality_values,
      stress_level_values,
      alcohol_intake_values,
      smoking_level_values,
      diet_quality_values
    } = this.state;

    var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    var latest_stress_level_value = stress_level_values.find(el => el !== null);
    var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    if (!(
      latest_sleep_quality_value == undefined && 
      latest_stress_level_value == undefined &&
      latest_alcohol_intake_value == undefined && 
      latest_smoking_level_value == undefined && 
      latest_diet_quality_value == undefined
      )){

    return (
      <GridItem xs={12}>
        <Divider light />
          <h4
            style={metricCardTextAlign}
            className={classes.cardTitleBlack}
          >
            Lifestyle
          </h4>
        </GridItem>


      )
    }
  }


  createCard(name, link, color, value, unit ){
    const { classes } = this.props;
    const { healthcheck, user } = this.state;

    console.log(value)
    console.log(value !== (undefined + "/" + undefined))

    if (value && value !== (undefined + "/" + undefined) && value !== (undefined + "." + undefined)){

      return (
          <GridItem xs={12} sm={3} >
            <Link to={link}>
              <Card
                color={color}
              >
                <br></br>
                <h4
                  style={metricCardTextAlign}
                  className={classes.cardTitleBlack}
                >
                  {name}
                </h4>
                <h4 style={metricCardMargin}>
                  {value} {unit}
                </h4>
              </Card>
            </Link>
          </GridItem>
        );
    }
  }

  renderMetrics() {
    const { classes } = this.props;
    const { healthcheck, user } = this.state;
    let { latestHealthCheck,
      metricValuesDates,
      blood_pressure_sys_values,
      blood_pressure_dys_values,
      blood_glucose_values,
      oxygen_level_values,
      body_mass_index_values,
      airway_peak_flow_values,
      cholesterol_level_values,
      body_temperature_values,
      heart_rate_values,
      beep_test_values,
      beep_test_decimal_values,
      vertical_jump_values,
      hundred_meter_sprint_values,
      reaction_time_values,
      sleep_quality_values,
      stress_level_values,
      alcohol_intake_values,
      smoking_level_values,
      diet_quality_values,
    } = this.state;

    const smoking_level_valuesConverted = [];

    if (smoking_level_values) {
      for (let i = 0; i < smoking_level_values.length; i++) {

        var smokerMap = {
          0: "Non Smoker",
          1: "Smoker"
        };

        smoking_level_valuesConverted.push(smokerMap[smoking_level_values[i]]);
      }
    }

    var latest_blood_pressure_sys_value = blood_pressure_sys_values.find(el => el !== null);
    var latest_blood_pressure_dys_value = blood_pressure_dys_values.find(el => el !== null);
    var latest_blood_glucose_value = blood_glucose_values.find(el => el !== null);
    var latest_oxygen_level_value = oxygen_level_values.find(el => el !== null);
    var latest_body_mass_index_value = body_mass_index_values.find(el => el !== null);
    var latest_airway_peak_flow_value = airway_peak_flow_values.find(el => el !== null);
    var latest_cholesterol_level_value = cholesterol_level_values.find(el => el !== null);
    var latest_body_temperature_value = body_temperature_values.find(el => el !== null);
    var latest_heart_rate_value = heart_rate_values.find(el => el !== null);
    
    var latest_beep_test_value = beep_test_values.find(el => el !== null);
    var latest_beep_test_decimal_value = beep_test_decimal_values.find(el => el !== null);
    var latest_vertical_jump_value = vertical_jump_values.find(el => el !== null);
    var latest_hundred_meter_sprint_value = hundred_meter_sprint_values.find(el => el !== null);
    var latest_reaction_time_value = reaction_time_values.find(el => el !== null);
    
    var latest_sleep_quality_value = sleep_quality_values.find(el => el !== null);
    var latest_stress_level_value = stress_level_values.find(el => el !== null);
    var latest_alcohol_intake_value = alcohol_intake_values.find(el => el !== null);
    var latest_smoking_level_value = smoking_level_values.find(el => el !== null);
    var latest_smoking_level_value_converted = smoking_level_valuesConverted.find(el => el !== undefined);
    var latest_diet_quality_value = diet_quality_values.find(el => el !== null);

    console.log(latest_blood_glucose_value);


    if (latest_blood_pressure_sys_value == null && 
      latest_blood_pressure_dys_value == null &&
      latest_blood_glucose_value == null && 
      latest_oxygen_level_value == null &&
      latest_body_mass_index_value == null && 
      latest_airway_peak_flow_value == null &&
      latest_cholesterol_level_value == null && 
      latest_body_temperature_value == null && 
      latest_heart_rate_value == null){
  
      }
  
    if (healthcheck) {

      var smoking_level_text = "";

      if (healthcheck.smoking_level == 1) {
          var smoking_level_text = "Smoker";
      } else {
        var smoking_level_text = "Non Smoker";
      }

      console.log(healthcheck);

      return (
        <div>
          {/* <GridItem xs={12}>
            <h4 style={metricCardTextAlign} className={classes.cardTitleBlack}>
              Health
            </h4>
          </GridItem> */}
          {this.healthTitle()}
          <GridContainer>
            {this.createCard(
              "Blood Glucose", 
              "/dashboard/metric/blood_glucose", 
              getBloodGlucoseCardColour(latest_blood_glucose_value), 
              latest_blood_glucose_value, 
              "mmol/L" 
            )}
            {this.createCard(
              "Blood Pressure", 
              "/dashboard/metric/bloodpressure", 
              getBloodPressureCardColour(latest_blood_pressure_sys_value,
                latest_blood_pressure_dys_value), 
                latest_blood_pressure_sys_value + "/" + latest_blood_pressure_dys_value, 
              "mmHg" 
            )}
            {this.createCard(
              "Heart Rate", 
              "/dashboard/metric/heart_rate", 
              getHeartRateCardColour(latest_heart_rate_value), 
              latest_heart_rate_value, 
              "BPM" 
            )}
            {this.createCard(
              "Oxygen Level", 
              "/dashboard/metric/oxygen_level", 
              getOxygenLevelCardColour(latest_oxygen_level_value), 
              latest_oxygen_level_value, 
              "mmHg" 
            )}

            {this.createCard(
              "Cholesterol Level", 
              "/dashboard/metric/cholesterol_level", 
              getCholesterolLevelCardColour(latest_cholesterol_level_value), 
              latest_cholesterol_level_value, 
              "mg/dL" 
            )}
            {this.createCard(
              "Body Mass Index", 
              "/dashboard/metric/body_mass_index", 
              getBodyMassIndexCardColour(latest_body_mass_index_value), 
              latest_body_mass_index_value, 
              "kg/m" 
            )}
            {this.createCard(
              "Body Temperature", 
              "/dashboard/metric/body_temperature", 
              getBodyTemperatureCardColour(latest_body_temperature_value), 
              latest_body_temperature_value, 
              "°C" 
            )}
            {this.createCard(
              "Airway Peak Flow", 
              "/dashboard/metric/airway_peak_flow", 
              getAirwayPeakFlowColour(latest_airway_peak_flow_value), 
              latest_airway_peak_flow_value, 
              "L/min" 
            )}
          </GridContainer>

          {/* <Divider light /> */}
          {this.fitnessTitle()}
          

          <GridContainer>
            {/* <GridItem xs={12}>
              <h4
                style={metricCardTextAlign}
                className={classes.cardTitleBlack}
              >
                Fitness
              </h4>
            </GridItem> */}

            {this.createCard(
              "Beep Test", 
              "/dashboard/metric/beep_test", 
              getBeepTestColour(latest_beep_test_value), 
              latest_beep_test_value + "." + latest_beep_test_decimal_value 
            )}
            {this.createCard(
              "Vertical Jump", 
              "/dashboard/metric/vertical_jump", 
              getVerticalJumpColour(latest_vertical_jump_value), 
              latest_vertical_jump_value, 
              "cm" 
            )}
            {this.createCard(
              "100m Sprint", 
              "/dashboard/metric/hundred_meter_sprint", 
              getHundredMeterSprintColour(latest_hundred_meter_sprint_value), 
              latest_hundred_meter_sprint_value, 
              "secs" 
            )}
            {this.createCard(
              "Reaction Time", 
              "/dashboard/metric/reaction_time", 
              getReactionTimeColour(latest_reaction_time_value), 
              latest_reaction_time_value, 
              "secs" 
            )}
          </GridContainer>
          
          {this.lifestyleTitle()}
          {/* <Divider light />

          

          <GridContainer>
            <GridItem xs={12}>
              <h4
                style={metricCardTextAlign}
                className={classes.cardTitleBlack}
              >
                Lifestyle
              </h4>
            </GridItem>
          </GridContainer> */}
            
            <GridContainer>
            {this.createCard(
              "Alcohol Intake", 
              "/dashboard/metric/alcohol_intake", 
              getAlcoholIntakeColour(latest_alcohol_intake_value), 
              latest_alcohol_intake_value, 
              "units per week" 
            )}
            {this.createCard(
              "Sleep Quality", 
              "/dashboard/metric/sleep_quality", 
              getSleepQualityColour(latest_sleep_quality_value), 
              latest_sleep_quality_value, 
              "hours" 
            )}
            {this.createCard(
              "Stress Level", 
              "/dashboard/metric/stress_level", 
              getStressLevelColour(latest_stress_level_value), 
              latest_stress_level_value, 
              "/10" 
            )}
            {this.createCard(
              "Diet Quality", 
              "/dashboard/metric/diet_quality", 
              getDietQualityColour(latest_diet_quality_value), 
              latest_diet_quality_value, 
              "/10" 
            )}
            {this.createCard(
              "Smoking Level", 
              "/dashboard/metric/smoking_level", 
              getSmokingLevelColour(latest_smoking_level_value), 
              latest_smoking_level_value_converted
            )}

            {/* <GridItem xs={12} sm={6} md={3}>
              <Link to={"/dashboard/metric/alcohol_intake"}>
                <Card
                  color={getAlcoholIntakeColour(healthcheck.alcohol_intake)}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Alcohol Intake
                  </h4>
                  <h4 style={metricCardMargin}>
                    {healthcheck.alcohol_intake} units per week
                  </h4>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={2}>
              <Link to={"/dashboard/metric/sleep_quality"}>
                <Card
                  color={getSleepQualityColour(healthcheck.sleep_quality)}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Sleep Quality
                  </h4>
                  <h4 style={metricCardMargin}>
                    {healthcheck.sleep_quality} hours
                  </h4>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={2}>
              <Link to={"/dashboard/metric/stress_level"}>
                <Card
                  color={getStressLevelColour(
                    healthcheck.stress_level
                  )}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Stress Level
                  </h4>
                  <h4 style={metricCardMargin}>
                    {healthcheck.stress_level} /10
                  </h4>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={2}>
              <Link to={"/dashboard/metric/diet_quality"}>
                <Card
                  color={getDietQualityColour(healthcheck.diet_quality)}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Diet Quality
                  </h4>
                  <h4 style={metricCardMargin}>
                    {healthcheck.diet_quality} /10
                  </h4>
                </Card>
              </Link>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to={"/dashboard/metric/smoking_level"}>
                <Card
                  color={getSmokingLevelColour(healthcheck.smoking_level)}
                >
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    Smoking Level
                  </h4>
                  <h4 style={metricCardMargin}>
                    {smoking_level_text}
                  </h4>
                </Card>
              </Link>
            </GridItem> */}
          </GridContainer>

          <Divider light />

              <GridContainer>
                <GridItem xs={12}>
                  <h4
                    style={metricCardTextAlign}
                    className={classes.cardTitleBlack}
                  >
                    History
                  </h4>
                  </GridItem>
              </GridContainer>

              <GridContainer>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Family history
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.family_history}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Medical history
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.medical_history}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Allergies
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.allergies}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                      Previous injuries
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.previous_injuries}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
                
                <GridItem xs={12} >
                  <Card color="primary">
                    <h5 style={metricCardTextAlignBold} className={classes.cardTitleBlack}>
                    Previous surgeries
                    </h5>
                    <CardBody>
                      <h4 style={metricCardTextAlign}>
                      
                      {user.previous_surgeries}                 
                      </h4>
                    </CardBody>
                  </Card>
                </GridItem>
              
              </GridContainer>
        </div>
      );
    } else {
      return (
        <h4 style={title} className={classes.cardTitleBlack}>
          No healthchecks found!
        </h4>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { isLoading, user, healthcheck, error } = this.state;

    const height = healthcheck ? healthcheck.height : user.height;
    const weight = healthcheck ? healthcheck.weight : user.weight;


    if (height) {
      var heightLargeResult = height + "cm";
      var heightSmallResult = this.calculateHeightInFeet(height);
    } else {
      var heightLargeResult = "";
      var heightSmallResult = "";
    }

    if (weight) {
      var weightLargeResult = weight + "kg";
      var weightSmallResult = this.calculateWeightInLbs(weight) + "lbs";

    } else {
      var weightLargeResult = "";
      var weightSmallResult = "";
    }

    // var heightLargeResult = height + "cm";
    // var weightLargeResult = "";

    return (
      <React.Fragment>
        <h4 style={title} className={classes.cardTitleBlack}>
          {user.first_name + " " + user.last_name}
        </h4>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div key={user.email}>
              <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="warning" stats icon>
                      <CardIcon color="warning">
                        <Icon>person</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Gender</p>
                      <h3 className={classes.cardTitle}>{user.gender}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                      <Icon>person</Icon>
                        Biological {user.gender}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="success" stats icon>
                      <CardIcon color="success">
                      <Icon>event</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Age</p>
                      <h3 className={classes.cardTitle}>{user.age}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                      <Icon>calendar_today</Icon>
                        {this.convertDateOfBirth(user.date_of_birth)}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="danger" stats icon>
                      <CardIcon color="danger">
                        <Icon>height</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Height</p>
                      <h3 className={classes.cardTitle}>{heightLargeResult}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                      <Icon>height</Icon>
                        {heightSmallResult}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <Card>
                    <CardHeader color="info" stats icon>
                      <CardIcon color="info">
                      <Icon>monitor_weight</Icon>
                      </CardIcon>
                      <p className={classes.cardCategory}>Weight</p>
                      <h3 className={classes.cardTitle}>{weightLargeResult}</h3>
                    </CardHeader>
                    <CardFooter stats>
                      <div className={classes.stats}>
                      <Icon>monitor_weight</Icon>
                        {weightSmallResult}
                      </div>
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>

              <Divider light />

              {this.renderNextAppointment()}

              {/* <Divider light /> */}

              {this.renderMetrics()}
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

// exports.getUser = this.getUser;


DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);

