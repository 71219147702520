import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter, Redirect } from "react-router-dom";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { deserializeDate, serializeDate } from "libs/dates.js";
import FormHelperText from '@material-ui/core/FormHelperText';

const center = {
  textAlign: "center"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};
const submitButton = {
  width: "20%",
  marginLeft: "40%"
};
const selector = {
  minWidth: "150",
  width: "100%"
};

const styles = () => ({
  timeButtonGroup: {
    marginTop: "30px",
    display: "block"
  },
  timeButton: {
    borderRadius: 4,
    "&:not(:first-child)": {
      borderRadius: 4,
      border: "1px solid rgba(0, 0, 0, 0.12)",
      margin: 5
    },
    "&:not(:last-child)": {
      borderRadius: 4,
      margin: 5
    }
  }
});

class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      select: {
        practitioner: ""
      },
      selectedDate: new Date(),
      alignedTime: "",
      currentBookings: [],
      existingBookings: [],
      submittedBooking: false,
      isLoading: true,
      number_of_players: 0,
      number_of_players_one: "",
      number_of_players_two: "",
      number_of_players_three: "",
      number_of_slots: 0,
      fields: {},
      errors: {},
      formIsValid: true
    };

  }

  setSelectedDateToAfterTommorow(){
    const { selectedDate } = this.state;
    var nextDay = new Date();

    selectedDate.setDate(nextDay.getDate() + 2);
    console.log(selectedDate);
  }

  getUser(userID) {
    axios
      .get(`/api/users/getByID/${userID}`)
      .then(response => {
        this.setState({ user: response.data });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  getCurrentBookings(userID) {
    axios
      .get(`/api/bookings/getAllByUser/${userID}`)
      .then(response => {
        this.setState({
          currentBookings: response.data.bookings
        });
      })
      .catch(error => this.setState({ error }));
  }

  getBookingTimes(date) {
    let serializedDate = serializeDate(date);
    axios
      .get("/api/bookings/getAllByDate/" + serializedDate)
      .then(response => {
        this.setState({
          existingBookings: response.data.bookings
        });
      })
      .catch(error => this.setState({ error }));
  }

  getPractitioners() {
    axios
      .get("/api/users/getByType/practitioner")
      .then(response => {
        this.setState({
          practitioners: response.data.users,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error }));
  }

  componentDidMount() {
    const { selectedDate } = this.state;
    let userID = localStorage.getItem("userID");
    this.getUser(userID);
    this.setSelectedDateToAfterTommorow();
    this.getCurrentBookings(userID);
    this.getBookingTimes(selectedDate);
    this.getPractitioners();
  }

  handleDate = date => {
    this.setState({ selectedDate: date, alignedTime: "" });
    this.getBookingTimes(date);
  };

  handleTime = (event, time) => {
    
    console.log(time);

    this.setState({ 
      alignedTime: time,
     });
     console.log(time);
  };

  handleSelect = event => {
    const { select, number_of_slots } = this.state;
    select[event.target.name] = event.target.value;
    // number_of_slots = event.target.value;
    this.setState({ select, number_of_slots: event.target.value });

    // console.log(number_of_slots);
  };

  handleValidation(){
    const { fields, alignedTime } = this.state;
    let formIsValid = true;

    let errors = {};

    if (10 > fields["number_of_players"] || fields["number_of_players"] > 30) {
      formIsValid = false;
      errors["number_of_players"] = "Please enter a number between 10 and 30";
      console.log(errors);
    }

    if (1 > fields["number_of_players_one"] || fields["number_of_players_one"] > 10) {
      formIsValid = false;
      errors["number_of_players_one"] = "Please enter a number between 1 and 10";
      console.log(errors);
    }

    if (1 > fields["number_of_players_two"] || fields["number_of_players_two"] > 10) {
      formIsValid = false;
      errors["number_of_players_two"] = "Please enter a number between 1 and 10";
      console.log(errors);
    }

    if (1 > fields["number_of_players_three"] || fields["number_of_players_three"] > 10) {
      formIsValid = false;
      errors["number_of_players_three"] = "Please enter a number between 1 and 10";
      console.log(errors);
    }

    if(!fields["number_of_players_one"] && !fields["number_of_players_two"] && !fields["number_of_players_three"]
    || !alignedTime.includes("19:00") && !alignedTime.includes("14:00") && !alignedTime.includes("09:00")){
      formIsValid = false;
      errors["below_slots"] = "Please ensure a slot is selected and the number of players is complete.";
    }

    // if(fields["number_of_players_one"]){
    //   formIsValid = false;
    //   errors["below_slots"] = "Please ensure a slot is selected and the number of players is complete.";
    // }
  
    this.setState({errors: errors});

   return formIsValid;
  }

  handleChange = event => {

  const { fields, number_of_slots } = this.state;
  
  fields[event.target.name] = event.target.value;        

  this.setState({ number_of_slots: event.target.value, fields });

  console.log(fields);
  console.log(number_of_slots);
  };

  handleFirstSlotChange = event => {

    const { fields, number_of_players_one } = this.state;

    console.log(event.target.value);

    fields[event.target.name] = event.target.value;        

    this.setState({ number_of_players_one: event.target.value, fields });
  
    console.log(fields);
    console.log(number_of_players_one);
  };

  handleSecondSlotChange = event => {

    const { fields, number_of_players_two } = this.state;

    console.log(event.target.value);

    fields[event.target.name] = event.target.value;        
      
    this.setState({ number_of_players_two: event.target.value, fields });

    console.log(fields);
    console.log(number_of_players_two);
  };

  handleThirdSlotChange = event => {

    const { fields, number_of_players_three } = this.state;

    console.log(event.target.value);

    fields[event.target.name] = event.target.value;        
      
    this.setState({ number_of_players_three: event.target.value, fields });
  
    console.log(fields);
    console.log(number_of_players_three);
  };

  handleSubmit = event => {

    this.handleValidation();

    console.log(this.handleValidation());

    const {
      alignedTime,     
      selectedDate,
      number_of_players_one,
      number_of_players_two,
      number_of_players_three,
      user
    } = this.state;

    // if(!number_of_players_three && alignedTime.includes("19:00") || 
    //         !number_of_players_two && alignedTime.includes("14:00") || 
    //           !number_of_players_one && alignedTime.includes("09:00")
    //           ){
    //             alert("Please ensure a slot is selected and the number of players is complete.")
    //           }

    if(this.handleValidation()){
      alert("Booking submitted")

      let data = {};
      data.user_id = localStorage.getItem("userID");
      data.organisation_id = user.organisation_id;
      data.date = serializeDate(selectedDate);

      if(number_of_players_one && alignedTime.includes("09:00")){

        data.number_of_players = number_of_players_one;
        data.time = "09:00";

        axios
        .post(
          "/api/bookings/add",
          { booking: data },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(response => {
          // console.log(response);
          // this.setState({ submittedBooking: true });
        })
        .catch(function(error) {
          // console.log(error.response);
        });

      } else {
        console.log("No populated number_of_players_one field or 09:00 time in alignedTime")
      }

      let data2 = {};

      data2.user_id = localStorage.getItem("userID");
      data2.organisation_id = user.organisation_id;
      data2.date = serializeDate(selectedDate);

      if(number_of_players_two && alignedTime.includes("14:00")){

        data2.number_of_players = number_of_players_two;
        data2.time = "14:00";

        console.log(data2);

        axios
        .post(
          "/api/bookings/add",
          { booking: data2 },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(response => {
          console.log(response);
          // this.setState({ submittedBooking: true });
        })
        .catch(function(error) {
          console.log(error.response);
        });

      } else {
        console.log("No populated number_of_players_two field or 14:00 time in alignedTime")
      }

      let data3 = {};

      data3.user_id = localStorage.getItem("userID");
      data3.organisation_id = user.organisation_id;
      data3.date = serializeDate(selectedDate);

      if(number_of_players_three && alignedTime.includes("19:00")){

        data3.number_of_players = number_of_players_three;
        data3.time = "19:00";

        console.log(data3);

        axios
        .post(
          "/api/bookings/add",
          { booking: data3 },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(response => {
          console.log(response);
          // this.setState({ submittedBooking: true });
        })
        .catch(function(error) {
          console.log(error.response);
        });

        } else {
        console.log("No populated number_of_players_three field or 19:00 time in alignedTime")
        }

        if(number_of_players_three && alignedTime.includes("19:00") || 
            number_of_players_two && alignedTime.includes("14:00") || 
              number_of_players_one && alignedTime.includes("09:00")
              ){
          this.setState({ submittedBooking: true });
        }

        



  
      // axios
      //   .post(
      //     "/api/bookings/add",
      //     { booking: data },
      //     { headers: { "Content-Type": "application/json" } }
      //   )
      //   .then(response => {
      //     console.log(response);
      //     this.setState({ submittedBooking: true });
      //   })
      //   .catch(function(error) {
      //     console.log(error.response);
      //   });

    }else{
  
        alert("Form has errors.")
        event.preventDefault();
    }
    // event.preventDefault();

    
  };

  genCurrentBookings() {
    const { classes } = this.props;
    const { currentBookings } = this.state;
    let items = [];
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    if (currentBookings) {
      for (let i = 0; i < currentBookings.length; i++) {
        items.push(
          <div key={i}>
            <h4 className={classes.cardTitle}>
              {deserializeDate(currentBookings[i].date).toLocaleDateString(
                "en-US",
                options
              ) +
                " @ " +
                currentBookings[i].time 
                +
                " for " + 
                currentBookings[i].number_of_players
                +
                " players."}
            </h4>
          </div>
        );
      }
    }
    return items;
  }

  genNoOfBookingSlots(){

    let items = [];
  }

  isExistingBooking(time) {
    const { existingBookings } = this.state;
    for (let i = 0; i < existingBookings.length; i++) {
      if (existingBookings[i].time === time) {
        return true;
      }
    }
    return false;
  }

  genPractitioners() {
    const { practitioners } = this.state;
    let items = [];
    if (practitioners) {
      for (let i = 0; i < practitioners.length; i++) {
        items.push(
          <MenuItem value={i} key={i}>
            {practitioners[i].firstName + " " + practitioners[i].lastName}
          </MenuItem>
        );
      }
    }
    return items;
  }

  genPractitionerSelect() {
    const { classes } = this.props;
    const { select } = this.state;
    return (
      <FormControl
        style={selector}
        variant="filled"
        className={classes.formControl}
      >
        <InputLabel id="practitioner_select_label">Practitioner</InputLabel>
        <Select
          labelId="practitioner_select"
          id="practitioner_select"
          name="practitioner"
          value={select.practitioner}
          onChange={this.handleSelect}
          style={selector}
        >
          {this.genPractitioners()}
        </Select>
      </FormControl>
    );
  }

  generateTimeButtons = () => {
    const { classes } = this.props;
    const { alignedTime } = this.state;
    const start = 9;
    const end = 17;

    let times = [];
    for (let i = start; i <= end; i++) {
      let time = `${i < 10 ? "0" : ""}${i}:00`;
      let timeHalf = `${i < 10 ? "0" : ""}${i}:30`;
      if (!this.isExistingBooking(time)) {
        times.push(
          <ToggleButton className={classes.timeButton} value={time} key={time}>
            {time}
          </ToggleButton>
        );
      }
      if (!this.isExistingBooking(timeHalf)) {
        times.push(
          <ToggleButton
            className={classes.timeButton}
            value={timeHalf}
            key={timeHalf}
          >
            {timeHalf}
          </ToggleButton>
        );
      }
    }

    if (times.length === 0) {
      times.push(
        <h3>
          Sorry, there are no appointments available. Please try a different
          date.
        </h3>
      );
    }

    return (
      <ToggleButtonGroup
        className={classes.timeButtonGroup}
        value={alignedTime}
        exclusive
        onChange={this.handleTime}
        aria-label="time"
        orientation="vertical"
      >
        {times}
      </ToggleButtonGroup>
    );

    console.log(times);
  };

  // setSlotsMap(){

  // }

  generateSlotButtons = () => {
    const { classes } = this.props;
    const { alignedTime, selectedDate, number_of_players_one, number_of_players_two, number_of_players_three } = this.state;
    const start = 9;
    const end = 19;
  
    let slots = [];

    var dayOfTheWeek = selectedDate.getDay();
    console.log(dayOfTheWeek);

    // var today = new Date();


    // console.log(today.localeCompare(selectedDate));
    // console.log(selectedDate.toDateString());
    
    for (let i = start; i <= end; i++) {
      let time = `${i < 10 ? "0" : ""}${i}:00`;
      
      if (!this.isExistingBooking(time)) {

        // var showAllSlots = false;

        // if(selectedDate == today){
        //   var showAllSlots = true;
        //   console.log("gh: showAllSlots is true");
        // }

        // console.log(showAllSlots);
        if (i === 9 && (dayOfTheWeek === 0 || dayOfTheWeek === 6)){
          slots.push(
            <ToggleButton className={classes.timeButton} value={time} key={time}>
              {time}
              <br />
            <TextField 
            id="number_of_players_one" 
            label=" Number of players" 
            name="number_of_players_one" 
            value={number_of_players_one}
            onChange={this.handleFirstSlotChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ 
              type: 'number',
              inputmode: 'numeric', 
            }}
            />
            <span style={{color: "red"}}>{this.state.errors["number_of_players_one"]}</span>
            
            </ToggleButton>
          );

          // slotsMap[time].push(

          // );

        }

        if (i === 14 && (dayOfTheWeek === 0 || dayOfTheWeek === 6)){
          slots.push(
            <ToggleButton className={classes.timeButton} value={time} key={time}>
              {time}
              <br />
              <TextField 
              id="number_of_players_two" 
              label=" Number of players" 
              name="number_of_players_two" 
              value={number_of_players_two}
              onChange={this.handleSecondSlotChange}
              InputLabelProps={{ shrink: true }}
              inputProps={{ 
                type: 'number',
                inputmode: 'numeric', 
              }}
              />
              <span style={{color: "red", shrink: true}}>{this.state.errors["number_of_players_two"]}</span>

            </ToggleButton>
          );
        }

        if (i === 19){
          slots.push(
            <ToggleButton className={classes.timeButton} value={time} key={time}>
              {time}
              <br />
            <TextField 
            id="number_of_players_three" 
            label=" Number of players" 
            name="number_of_players_three" 
            value={number_of_players_three}
            onChange={this.handleThirdSlotChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{ 
              type: 'number',
              inputmode: 'numeric', 
            }}
            />
            <span style={{color: "red"}}>{this.state.errors["number_of_players_three"]}</span>            
            </ToggleButton>
          );
        }

      }
    }

    if (slots.length === 0 ) {
      slots.push(
        <h3>
          Sorry, there are no appointments available. Please try a different
          date.
        </h3>
      );
    }

    return (
      <ToggleButtonGroup
        className={classes.timeButtonGroup}
        value={alignedTime}
        // exclusive
        onChange={this.handleTime}
        aria-label="time"
        orientation="vertical"
      >
        {slots}
      </ToggleButtonGroup>
      
    );
  };

  render() {
    const { classes } = this.props;
    const { isLoading, selectedDate, submittedBooking, currentBookings, number_of_players, number_of_slots,
       number_of_players_one, number_of_players_two, number_of_players_three } = this.state;

    if (submittedBooking) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    var last5currentBookings = this.genCurrentBookings();

    var nextDay = new Date();

    nextDay.setDate(nextDay.getDate() + 2);
    console.log(nextDay);  

    return (
      <div>
        {!isLoading ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h4 style={title} className={classes.cardTitleBlack}>
                Book Appointment
              </h4><Card style={center}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <h4 className={classes.cardTitle}>Current Bookings</h4>
                    </GridItem>
                    <GridItem xs={12} sm={3}></GridItem>
                    <GridItem xs={12} sm={6}>
                      {last5currentBookings.slice(Math.max(last5currentBookings.length - 5, 0))}
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12}>
                      <br />
                      <br />
                  </GridItem>
                  </GridContainer>
                  <h4 className={classes.cardTitle}>Pick a Date</h4>
                  <GridContainer>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <GridItem xs={12}>
                        <KeyboardDatePicker
                          value={selectedDate}
                          required
                          onChange={this.handleDate}
                          disablePast="True"
                          format="dd/MM/yyyy"
                          minDate={nextDay}
                        />
                      </GridItem>
                      {/* <GridItem xs={12}>{this.generateTimeButtons()}</GridItem> */}
                      <GridItem xs={12}>
                        <h4 className={classes.cardTitle}>Please highlight your desired slots and enter the number of players for each slot</h4>
                      </GridItem>
                      <GridItem xs={12}>{this.generateSlotButtons()}</GridItem>
                    </MuiPickersUtilsProvider>
                  </GridContainer>
                  <span style={{color: "red"}}>{this.state.errors["below_slots"]}</span>                                  
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={this.handleSubmit}
                    style={submitButton}
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <h3>Loading...</h3>
        )}
      </div>
    );
  }
}

Booking.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(withRouter(Booking));
