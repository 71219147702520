import axios from 'axios';
// jest.mock('./getFuncs');

export const getUser = (userID) => {

  var userObject = axios
    .get(`/api/users/getByID/${userID}`)
    .then(response => {
      return response.data;
    });
  return userObject;
};

export const getLatestHealthCheck = (userID) => {

  var healthcheckObject = axios
    .get(`/api/healthchecks/getLatestHealthCheck/${userID}`)
    .then(response => {
      return response.data;
    });
  return healthcheckObject;
};

export const getNextAppointment = (appointment) => {

  var nextAppointmentObject = axios
    .get(`/api/bookings/get/${appointment}`)
    .then(response => {
      return response.data;
    });
  return nextAppointmentObject;
};

export const getCurrentBookings = (userID) => {

  var bookingsObject = axios
    .get(`/api/bookings/getAllByUser/${userID}`)
    .then(response => {
      return response.data;
    });
  return bookingsObject;
};

export const getOrganisation = (orgID) => {

  var organisationObject = axios
    .get(`/api/organisations/get/${orgID}`)
    .then(response => {
      return response.data;
    });
  return organisationObject;
};

export const getPlayers = (orgID) => {

  var playersObject = axios
    .get(`/api/users/getByOrg/${orgID}`)
    .then(response => {
      return response.data;
    });
  return playersObject;
};

export const getOrganisations = () => {

  var organisationsObject = axios
    .get(`/api/organisations/all`)
    .then(response => {
      return response.data;
    });
  return organisationsObject;
};

export const getPractitioners = () => {

  var practitionersObject = axios
    .get(`/api/users/getByType/${"Practitioner"}`)
    .then(response => {
      return response.data;
    });
  return practitionersObject;
};

export const checkForAHealthCheckToday = (userID) => {

  var todayCheckObject = axios
    .get(`/api/healthchecks/checkForAHealthCheckToday/${userID}`)
    .then(response => {
      return response.data;
    });
  return todayCheckObject;
};

export const getPlayersByOrg = (organisation) => {

  var orgPlayersObject = axios
    .get(`/api/users/getByOrg/${organisation}`)
    .then(response => {
      return response.data;
    });
  return orgPlayersObject;
};

// checkForAHealthCheckToday(userID) {
//   axios
//     .get(`/api/healthchecks/checkForAHealthCheckToday/${userID}`)
//     .then(response => {
//       var checkBoxBoolean  = response.data;
//       this.setState({
//         isLoading: false
//       });
//       this.addToPlayersCheckListArray(checkBoxBoolean ,userID);
//     })
//     .catch(error => this.setState({ error, isLoading: false }));
// }

// getPlayersByOrg(organisation) {
//   axios
//     .get("/api/users/getByOrg/" + organisation)
//     .then(response => {
//       this.setState({
//         players: response.data,
//       });

//       this.setCheckBoxBooleanMap();

//     })
//     .catch(error => this.setState({ error, isLoading: false }));
// }

// exports.getUser = getUser;
// exports.getLatestHealthCheck = getLatestHealthCheck;
// exports.getNextAppointment = getNextAppointment;
// exports.getCurrentBookings = getCurrentBookings;
// exports.getOrganisation = getOrganisation;
// exports.getPlayers = getPlayers;
// exports.getOrganisations = getOrganisations;
// exports.getPractitioners = getPractitioners;
// exports.checkForAHealthCheckToday = checkForAHealthCheckToday;
// exports.getPlayersByOrg = getPlayersByOrg;