import React from "react";
import axios from "axios";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import CardHeader from "components/Card/CardHeader";
import MenuItem from "@material-ui/core/MenuItem";

import { getUser, getOrganisations, getPractitioners  }  from './getFuncs';


const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const metricCardTextAlignLeft = {
  textAlign: "left",
  fontSize: "1.2em",
  lineHeight: "0.1em"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};

class AdminDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      organisations: [],
      practitioners: [],
      user: []
    };
  }

  // getUser(userID) {
  //   axios
  //     .get(`/api/users/getByID/${userID}`)
  //     .then(response => {
  //       this.setState({ user: response.data, isLoading: false });
  //       this.getOrganisations();
  //       this.getPractitioners();

  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  // getOrganisations() {
  //   axios
  //     .get("/api/organisations/all")
  //     .then(response => {
  //       this.setState({
  //         organisations: response.data.organisations,
  //         isLoading: false
  //       });
  //     })
  //     .catch(error => this.setState({ error }));
  // }

  genOrgs() {
    const { organisations } = this.state;
    console.log(organisations);
    let items = [];
    for (let i = 0; i < organisations.length; i++) {
      items.push(
        <MenuItem value={organisations[i]._id} key={organisations[i]._id}>
          {organisations[i].name}
        </MenuItem>
      );
    }
    return items;
  }

  // getPractitioners() {
  //   axios
  //     .get(`/b/users/getByType/${"Practitioner"}`)
  //     .then(response => {
  //       this.setState({
  //         practitioners: response.data.users,
  //         isLoading: false
  //       });
  //     })
  //     .catch(error => this.setState({ error }));
  // }

  genPracts() {
    const { practitioners } = this.state;
    console.log(practitioners);
    let items = [];
    for (let i = 0; i < practitioners.length; i++) {
      items.push(
        <MenuItem value={practitioners[i]._id} key={practitioners[i]._id}>
          {practitioners[i].first_name + " " + practitioners[i].last_name}
        </MenuItem>
      );
    }
    return items;
  }

  componentDidMount() {
    let userID = localStorage.getItem("userID");
    // this.getUser(userID);

    getUser(userID)
    .then(userObjects => {
       
      this.setState({ user: userObjects });
      console.log(userObjects);

      getOrganisations()
      .then(organisationsObject => { 
        
        this.setState({ 
          organisations: organisationsObject.organisations });
        console.log(organisationsObject);
      });

      getPractitioners()
      .then(practitionersObject => { 
        
        this.setState({ 
          practitioners: practitionersObject.users });
        console.log(practitionersObject);
      });


    });
  }

  render() {
    const { classes } = this.props;
    const { isLoading, user, orgs } = this.state;

    console.log(this.genOrgs());

    return (
      <React.Fragment>
        <div>
          {!isLoading ? (
            <div>
              <h4 style={title} className={classes.cardTitleBlack}>
                {user.first_name + " " + user.last_name}
              </h4>
              <GridContainer>
                <GridItem xs={6}>
                  <Card color="primary">
                    <CardHeader>List of Organisations</CardHeader>
                    <CardBody>
                      {/* <h4 style={metricCardTextAlign}>Lorem Ipsum</h4> */}
                      {this.genOrgs()}
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={6}>
                  <Card color="primary">
                    <CardHeader>List of Practitioners</CardHeader>
                    <CardBody>
                      {/* <h4 style={metricCardTextAlign}>Lorem Ipsum</h4> */}
                      {this.genPracts()}
                    </CardBody>
                  </Card>
                </GridItem>
                
              </GridContainer>
              {/* <List>
                {orgs.map((value) => {
                  for (let i = 0; i < orgs.length; i++) {
    
                    this.state.checkBoxBooleanValue = todayCheckBooleanArray[value._id];
    
                    const labelId = `checkbox-list-label-${value}`;
    
                      return (
                        <GridContainer>
                          <ListItem dense >
                            <ListItemIcon>
                              <Checkbox id={labelId}
                                edge="start"
                                checked={todayCheckBooleanArray[value._id] == true}
                                disableRipple
                                disabled
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.first_name} ${value.last_name}`} />
                          </ListItem>
                        </GridContainer>
                      );
                  }
                })}
              </List> */}
          </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>

      </React.Fragment>
    );
  }
}

AdminDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(AdminDashboardPage)
);
