import React from "react";
import axios from "axios";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { hexToRgb, blackColor } from "assets/jss/material-dashboard-react.js";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { deserializeDate, serializeDate } from "libs/dates.js";
import { getUser, getCurrentBookings, getOrganisation, getPlayers  }  from './getFuncs';


const circleRed = {
  width: "25px",
  height: "25px",
  background: "#d44",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#fff"
};
const circleOrange = {
  width: "25px",
  height: "25px",
  background: "#FFCC00",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#fff"
};
const circleGreen = {
  width: "25px",
  height: "25px",
  background: "#33CC00",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#fff"
};
const circleGrey = {
  width: "25px",
  height: "25px",
  background: "#ccc",
  borderRadius: "50%",
  textAlign: "center",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  color: "#fff"
};

const cardIconCustom = {
  float: "left",
  maxWidth: "130px",
  maxHeight: "130px",
  margin: "auto 0",
  transform: "translateY(11%)",
  marginRight: "10px",
  borderRadius: "50%",
  overflow: "hidden",
  padding: "0",
  boxShadow:
    "0 16px 38px -24px rgba(" +
    hexToRgb(blackColor) +
    ", 0.56), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
};
const cardIconTeaser = {
  float: "left",
  maxWidth: "130px",
  maxHeight: "130px",
  margin: "auto 0",
  transform: "translateY(11%)",
  marginRight: "10px",
  borderRadius: "50%",
  overflow: "hidden",
  padding: "0",
  boxShadow:
    "0 16px 38px -24px rgba(" +
    hexToRgb(blackColor) +
    ", 0.56), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  opacity: "0.6"
};
const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const metricCardTextAlignLeft = {
  textAlign: "left",
  fontSize: "1.2em",
  lineHeight: "0.1em"
};
const formAlignRight = {
  float: "right",
};
const cardOverlay = {
  background: "rgba(0,0,0,0.1)"
  // width: "80%",
  // marginLeft: "2%",
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};

const center = {
  textAlign: "center"
};

class CoachDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      sort: "red",
      inviteLink: "app.totalhealthcheckuk.com",
      user: [],
      organisation: [],
      currentBookings: [],
      players: []
    };

    // let userID = localStorage.getItem("userID");
    // this.getUser(userID);
    // this.getCurrentBookings(userID);

  }

  // getUser(userID) {
  //   axios
  //     .get(`/api/users/getByID/${userID}`)
  //     .then(response => {
  //       console.log(response);
  //       this.setState({ user: response.data });
  //       this.getOrganisation(response.data.organisation_id);
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  getCurrentBookings(userID) {
    axios
      .get(`/api/bookings/getAllByUser/${userID}`)
      .then(response => {
        this.setState({
          currentBookings: response.data.bookings
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  // getOrganisation(orgID) {
  //   axios
  //     .get(`/api/organisations/get/${orgID}`)
  //     .then(response => {
  //       console.log(response);

  //       this.setState({ organisation: response.data });
  //       this.getPlayers(response.data._id);
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  // getPlayers(orgID) {
  //   axios
  //     .get(`/api/users/getByOrg/${orgID}`)
  //     .then(response => {
  //       console.log(response);
  //       this.setState({ players: response.data, isLoading: false });
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  genCurrentBookings() {
    const { classes } = this.props;
    const { currentBookings } = this.state;
    let items = [];
    let options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    function compare( a, b ){
      if (a.date < b.date ){
        return -1;
      }
      if (a.date > b.date ){
        return 1;
      }
      return 0;
    }

    currentBookings.sort( compare );

    if (currentBookings) {
      for (let i = 0; i < currentBookings.length; i++) {
        items.push(
          <div key={i}>
            <h4 className={classes.cardTitle}>
              {(i+1) + ". " + deserializeDate(currentBookings[i].date).toLocaleDateString(
                "en-US",
                options
              ) +
                " - " +
                currentBookings[i].time 
                +
                " for " + 
                currentBookings[i].number_of_players
                +
                " players."}
            </h4>
          </div>
        );
      }
    }
    return items;
  }

 

  createPlayerCard(player) {
    const { classes } = this.props;
    return (
      <Link to={`/dashboard/player/${player._id}`}>
        <Card>
          <CardBody>
            <CardAvatar profile style={cardIconCustom}>
              <img src={player.image} alt={player.name}></img>
            </CardAvatar>
            <GridContainer>
              <GridItem xs={12} sm={6} md={12}>
                <p className={classes.cardCategory}>Name</p>
                <h3 className={classes.cardTitle}>
                  {player.first_name + " " + player.last_name}
                </h3>
              </GridItem>
              {/* <GridItem xs={12} sm={6} md={12}>
                <p className={classes.cardCategory}>Position</p>
                <h3 className={classes.cardTitle}>{player.position ? player.position : "Unknown"}</h3>
              </GridItem> */}
              {/* <GridItem xs={12} sm={6} md={3}>
                <p className={classes.cardCategory}>Height</p>
                <h3 className={classes.cardTitle}>{player.height}cm</h3>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <p className={classes.cardCategory}>Weight</p>
                <h3 className={classes.cardTitle}>{player.weight}kg</h3>
              </GridItem> */}
              {/* <GridItem xs={12} sm={6} md={3}>
                <p className={classes.cardCategory}>Age</p>
                <h3 className={classes.cardTitle}>{player.age}</h3>
              </GridItem> */}
            </GridContainer>
          </CardBody>
          <CardFooter stats>
            <GridContainer>
              <GridItem xs={1} sm={1} md={1}>
                <div style={circleRed}>{player.unhealthy_score}</div>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <div style={circleOrange}>{player.okay_score}</div>
              </GridItem>
              <GridItem xs={1} sm={1} md={1}>
                <div style={circleGreen}>{player.healthy_score}</div>
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      </Link>
    );
  }

  createPlayerCards(players) {
    let playerCards = [];
    for (let i = 0; i < players.length; i++) {
      playerCards.push(
        <GridItem xs={12} sm={6} md={6} key={i}>
          {this.createPlayerCard(players[i])}
        </GridItem>
      );
    }
    return playerCards;
  }

  createTeaserPlayerCard() {
    return (
      <Card style={cardOverlay}>
        <CardBody>
          <CardAvatar profile style={cardIconTeaser}>
            <img src="/default-user.jpg" alt="default-user"></img>
          </CardAvatar>
        </CardBody>
        <CardFooter stats>
          <GridContainer>
            <GridItem xs={1} sm={1} md={1}>
              <div style={circleGrey}>0</div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div style={circleGrey}>0</div>
            </GridItem>
            <GridItem xs={1} sm={1} md={1}>
              <div style={circleGrey}>0</div>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }

  createTeaserPlayerCards() {
    let playerCards = [];
    for (let i = 0; i < 4; i++) {
      playerCards.push(
        <GridItem xs={12} sm={6} md={6} key={i}>
          {this.createTeaserPlayerCard()}
        </GridItem>
      );
    }
    return playerCards;
  }

  compareRed(a, b) {
    if (a.unhealthy_score > b.unhealthy_score) {
      return -1;
    }
    if (a.unhealthy_score < b.unhealthy_score) {
      return 1;
    }
    return 0;
  }

  compareOrange(a, b) {
    if (a.okay_score > b.okay_score) {
      return -1;
    }
    if (a.okay_score < b.okay_score) {
      return 1;
    }
    return 0;
  }

  compareGreen(a, b) {
    if (a.healthy_score > b.healthy_score) {
      return -1;
    }
    if (a.healthy_score < b.healthy_score) {
      return 1;
    }
    return 0;
  }

  handleChange = event => {
    this.setState({ sort: event.target.value });
  };

  componentDidMount() {
    // const { healthcheck, user, nextAppointment } = this.state;
    let userID = localStorage.getItem("userID");

    this.getCurrentBookings(userID);


    let userObjects = [];
    let healthcheckObjects = [];
    let nextAppointmentObjects = [];
    let bookingsObjects = [];
    let organisationObjects = [];
    
    // let userObjectFinal = [];
    // let healthcheckObjectFinal = [];
    // let nextAppointmentObjectFinal = [];

    async function getCurrentUser(userID) {
      try {
        await getUser(userID)
          .then(userObject => {

            userObjects = userObject;
            return userObjects;

          }).then(userObjects => {            
            
            return userObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return userObjects;
    };

    async function getUsersCurrentBookings(userID) {
      try {
        await getCurrentBookings(userID)
          .then(bookingsObject => {

            bookingsObjects = bookingsObject;
            console.log(bookingsObject);
            return bookingsObjects;

          }).then(bookingsObjects => {

            return bookingsObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return bookingsObjects;
    };

    async function getCurrentUsersOrganisation(userID) {
      try {
        await getOrganisation(userID)
          .then(userObject => {

            organisationObjects = userObject;
            return organisationObjects;

          }).then(userObjects => {            
            
            return organisationObjects;
          }); 
      } catch (error) {
        this.setState({ error, isLoading: false });
        // console.log(error);
      }
      return organisationObjects;
    };

    getCurrentBookings(userID)
    .then(bookingsObjects => {
      this.setState({ currentBookings: bookingsObjects.bookings, isLoading: false });
      console.log(bookingsObjects);
      
    });

    getUser(userID)
    .then(userObjects => {
       
      this.setState({ user: userObjects });
      console.log(userObjects);

      getOrganisation(userObjects.organisation_id)
      .then(organisationObjects => { 
        
        this.setState({ organisation: organisationObjects });
        console.log(organisationObjects);

        getPlayers(organisationObjects._id)
        .then(playersObject => {
        this.setState({ players: playersObject });
        console.log(playersObject);
          
        });
      });
    });
    
  };


  render() {
    const { classes } = this.props;
    const { isLoading, sort, inviteLink, organisation, players } = this.state;

    let newLink = inviteLink + "/signup/";

    let sortFunction = this.compareRed;
    switch (sort) {
      case "red":
        sortFunction = this.compareRed;
        break;
      case "orange":
        sortFunction = this.compareOrange;
        break;
      case "green":
        sortFunction = this.compareGreen;
        break;
      default:
        sortFunction = this.compareRed;
    }
    players.sort(sortFunction);

    let playerCards = null;
    if(!players || players.length === 0){
      playerCards = this.createTeaserPlayerCards();
    } else {
      playerCards = this.createPlayerCards(players);
    }

    var last5currentBookings = this.genCurrentBookings();

    console.log(last5currentBookings);

    if (last5currentBookings.length === 0){
      var listOfBookings = "You have no upcoming bookings. Please visit the Book Appointment page to make a booking.";
    } else {
      var listOfBookings = last5currentBookings.slice(Math.max(last5currentBookings.length - 5, 0));
    }

    console.log(playerCards);
    console.log(players);
    console.log(players.length);


    if (players.length === 0){
      var noOfRegdPlayers = 0;

    } else {
      var noOfRegdPlayers = players.length;

    }
    console.log(noOfRegdPlayers);

    var playersCardText = noOfRegdPlayers + "/" + (organisation.member_limit-1);

    console.log(playersCardText);


    return (
      <React.Fragment>
        <div>
          {!isLoading ? (
            <div>
              <GridContainer>
              <GridItem xs={12} >
                <h4 style={title} className={classes.cardTitleBlack}>
                  {organisation.name}
                </h4>
                </GridItem>   
                <GridItem xs={12} >
                    <Card style={center} color="primary">
                      <CardHeader>
                        <h4 className={classes.cardTitle}>
                          Your Bookings
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <h4 style={metricCardTextAlign}>
                          {listOfBookings}
                        </h4>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card style={center} color="primary">                   
                      <CardHeader>
                      <h4 className={classes.cardTitle}>
                        Sign up link:
                      </h4>
                      </CardHeader>
                      <CardBody>
                      
                      <h4 style={metricCardTextAlign}>
                        {newLink + organisation.invite_link}
                      </h4>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12}>
                    <Card color="primary">
                      <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="center"
                      >
                        <GridItem xs={3}>
                          <h4 style={title} className={classes.cardTitleBlack}>
                            {playersCardText}
                          </h4>
                        </GridItem>
                        <GridItem xs={5}>
                          <h4 style={title} className={classes.cardTitleBlack}>
                            Players
                          </h4>
                        </GridItem>
                        <GridItem xs={4}>
                          <FormControl
                            style={formAlignRight}
                            className={classes.formControl}
                          >
                            <InputLabel id="sortSelectLabel">Sort by</InputLabel>
                            <Select
                              labelId="sortSelect"
                              id="sortSelect"
                              value={sort}
                              onChange={this.handleChange}
                            >
                              <MenuItem value="red">Unhealthy</MenuItem>
                              <MenuItem value="orange">Average</MenuItem>
                              <MenuItem value="green">Healthy</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        {playerCards}
                      </Grid>
                    </Card>
                  </GridItem>
              </GridContainer>
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

CoachDashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(CoachDashboardPage)
);
