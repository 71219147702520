export function serializeDate(date) {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
}

export function deserializeDate(date) {
  let parts = date.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function stringifyDate(date) {
  return (
    date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
  );
}