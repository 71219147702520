import React from "react";
import axios from "axios";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { withRouter, Link, Redirect } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      deleted: false,
      cancelled: false
    };
  }

  delete = () => {
    let id = localStorage.getItem("userID");
    axios
      .delete("/api/users/delete/" + id)
      .then(() => {
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("expiration", -1);
        localStorage.setItem("userID", null);
        this.setState({ deleted: true });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  };

  render() {
    const { isLoading, error, deleted } = this.state;

    if (deleted) {
      return <Redirect to={{ pathname: "/" }} />;
    }

    return (
      <React.Fragment>
        {error ? <p>{error.message}</p> : null}
        <div>
          {!isLoading ? (
            <div>
              <Card profile>
                <CardBody profile>
                  <h4>Are you sure you want to delete your account?</h4>
                  <Button color="danger" onClick={this.delete}>
                    Delete
                  </Button>
                  <br></br>
                  <Link to="/dashboard">
                    <Button color="success">Cancel</Button>
                  </Link>
                </CardBody>
              </Card>
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

DashboardPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(DashboardPage)
);
