import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { withRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import logo from "assets/img/THC_Logo_Compact.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://totalhealthcheck.org/">
        THC
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#62c5c4"
  },
  logoImage: {
    margin: theme.spacing(1)
  },
  img: {
    maxHeight: "150px"
  }
});

const title = {
  textAlign: "center",
  fontSize: "2em",
  padding: "0px",
  margin: "0px"
};
const subtitle = {
  textAlign: "center",
  fontSize: "1.5em",
  color: "#777",
  padding: "0px",
  margin: "0px"
};

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      shouldRedirect: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ shouldRedirect: true });
    }, 5000);
  }

  render() {
    const { classes } = this.props;
    const { isLoading, shouldRedirect } = this.state;

    if (shouldRedirect) {
      return <Redirect to={{ pathname: "/login" }} />;
    }

    return (
      <React.Fragment>
        <div>
          {!isLoading ? (
            <Container component="main" maxWidth="sm">
              <Helmet>
                <title>Redirecting... | TotalHealthCheck</title>
              </Helmet>
              <CssBaseline />
              <div className={classes.paper}>
                <div className={classes.logoImage}>
                  <img src={logo} alt="logo" className={classes.img} />
                </div>
              </div>
              <h2 style={title} className={classes.cardTitleBlack}>
                You have signed up successfully!<br></br>Redirecting to login...
              </h2>
              <br></br>
              <h4 style={subtitle} className={classes.cardTitleBlack}>
                Click <a href="/login">here</a> if redirect does not occur
                within 5 seconds
              </h4>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.any,
  match: PropTypes.any
};

export default withStyles(styles, { withTheme: true })(withRouter(SignUp));
