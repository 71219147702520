const auth = {
  isAuthenticated() {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      let expiration = localStorage.getItem("expiration");
      let d = new Date();
      let currentTime = d.getTime();
      if (expiration > 0 && currentTime < expiration) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
};

export default auth;
