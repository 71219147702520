import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { withStyles } from "@material-ui/core";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { ValidatorForm } from "react-material-ui-form-validator";
import TextField from "@material-ui/core/TextField";
import { v4 as uuidv4 } from "uuid";
import Typography from "@material-ui/core/Typography";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { deserializeDate, serializeDate } from "libs/dates.js";


const metricCardTextAlign = {
  textAlign: "center",
  fontSize: "1.3em",
  lineHeight: "1.4em"
};
const title = {
  textAlign: "center",
  fontSize: "2em"
};

const styles = () => ({
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  form: {
    width: "100%" // Fix IE 11 issue.
  },
  errorMessage: {
    textAlign: "center",
    color: "#ff0033"
  },
  successMessage: {
    textAlign: "center",
    color: "#00E676"
  }
});

class AddOrganisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      org: {},
      submitted: false,
      isLoading: false,
      error: null,
      selectedDate: new Date(),

    };
  }

  handleDate = date => {
    this.setState({ selectedDate: date});
  };

  handleChange = event => {
    const { org } = this.state;
    org[event.target.name] = event.target.value;
    this.setState({ org });
  };

  handleSubmit = event => {
    event.preventDefault();
    const data = this.state.org;
    const { selectedDate } = this.state;
    data.date = serializeDate(selectedDate);

    console.log(selectedDate)

    axios
      .post(
        "/api/organisations/add",
        { organisation: data },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(response => {
        this.addCoach(response.data);
      })
      .catch(error => {
        this.setState({ error: error.response });
      });
  };

  addCoach(org) {
    const data = this.state.org;
    let coach = {};
    coach.account_type = "Coach";
    coach.first_name = org.name;
    coach.last_name = "Coach";
    coach.email = data.email;
    coach.organisation_id = org._id;
    coach.password = uuidv4();
    coach.date_of_birth = data.date;

    console.log(coach);

    axios
      .post(
        "/api/users/register",
        { user: coach },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(response => {
        if (response.status === 201) {
          this.setState({ submitted: true });
        }
      })
      .catch(error => {
        this.setState({ error: error.response });
      });
  }

  render() {
    const { classes } = this.props;
    const { error, org, submitted, selectedDate } = this.state;

    let errorMessage = "";
    if (error) {
      errorMessage = (
        <Typography
          className={classes.errorMessage}
          component="h1"
          variant="subtitle1"
        >
          {error.data.error}
        </Typography>
      );
    }

    let submittedMessage = "";
    if (submitted) {
      submittedMessage = (
        <div>
          <Typography
            className={classes.successMessage}
            component="h1"
            variant="subtitle1"
          >
            Organisation {org.name} successfully added.
          </Typography>
          <br></br>
        </div>
      );
    }

    return (
      <ValidatorForm
        ref={ref => (this.form = ref)}
        className={classes.form}
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <h4 style={title} className={classes.cardTitleBlack}>
                  Add Organisation
                </h4>
                {errorMessage}
                {submittedMessage}
                <GridContainer>
                  <GridItem xs={12}>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      Organisation Details
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={12}>
                    <TextField
                      id="filled-full-width"
                      label="Name"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="name"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.name}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Address Line 1"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="address_line_1"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.address_line_1}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Address Line 2"
                      style={{ margin: 2 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="address_line_2"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.address_line_2}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Address Line 3"
                      style={{ margin: 2 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="address_line_3"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.address_line_3}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Town"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="town"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.town}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="County"
                      style={{ margin: 2 }}
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="county"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.county}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Postcode"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="postcode"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.postcode}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Country"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="country"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.country}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Contact Number"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="contact_no"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.contact_no}
                    />
                    <br />
                    <br />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Member Limit (Including coaches)"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="member_limit"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.member_limit}
                    />
                    <br />
                    <br />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <h4
                      style={metricCardTextAlign}
                      className={classes.cardTitleBlack}
                    >
                      Coach Details
                    </h4>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      id="filled-full-width"
                      label="Email"
                      style={{ margin: 2 }}
                      required
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="filled"
                      name="email"
                      autoComplete=""
                      onChange={this.handleChange}
                      value={org.email}
                    />
                    <br />
                    <br />
                  </GridItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <GridItem xs={12} sm={6}>
                      {/* <TextField
                        id="filled-full-width"
                        label="Date Of Birth"
                        style={{ margin: 2 }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="filled"
                        name="date_of_birth"
                        autoComplete=""
                        onChange={this.handleChange}
                        value={org.date_of_birth}
                      /> */}
                      <KeyboardDatePicker
                        label="Date Of Birth"
                        value={selectedDate}
                        required
                        fullWidth
                        onChange={this.handleDate}
                        disableFuture
                        format="dd/MM/yyyy"
                        inputVariant="filled"
                        // minDate={nextDay}
                      />
                      <br />
                      <br />
                    </GridItem>
                  </MuiPickersUtilsProvider>
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </ValidatorForm>
    );
  }
}

AddOrganisation.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(
  withRouter(AddOrganisation)
);
