const green = "success";
const orange = "warning";
const red = "danger";

export function calculateHealthCheckMetricNumbers(healthcheck) {
  let colours = [];

  if (healthcheck.blood_glucose){
    colours.push(getBloodGlucoseCardColour(healthcheck.blood_glucose));
  }

  if (healthcheck.blood_pressure_systolic && healthcheck.blood_pressure_diastolic){
    colours.push(getBloodPressureCardColour(healthcheck.blood_pressure_systolic,
        healthcheck.blood_pressure_diastolic)
    );
  }
  
  if (healthcheck.heart_rate){
    colours.push(getHeartRateCardColour(healthcheck.heart_rate));
  }
  if (healthcheck.oxygen_level){
    colours.push(getOxygenLevelCardColour(healthcheck.oxygen_level));
  }
  if (healthcheck.airway_peak_flow){
    colours.push(getAirwayPeakFlowColour(healthcheck.airway_peak_flow));
  }
  if (healthcheck.cholesterol_level){
    colours.push(getCholesterolLevelCardColour(healthcheck.cholesterol_level));
  }
  if (healthcheck.body_mass_index){
    colours.push(getBodyMassIndexCardColour(healthcheck.body_mass_index));
  }
  if (healthcheck.body_temperature){
    colours.push(getBodyTemperatureCardColour(healthcheck.body_temperature));
  }
  if (healthcheck.beep_test){
    colours.push(getBeepTestColour(healthcheck.beep_test));
  }
  if (healthcheck.vertical_jump){
    colours.push(getVerticalJumpColour(healthcheck.vertical_jump));
  }
  if (healthcheck.hundred_meter_sprint){
    colours.push(getHundredMeterSprintColour(healthcheck.hundred_meter_sprint));
  }
  if (healthcheck.reaction_time){
    colours.push(getReactionTimeColour(healthcheck.reaction_time));
  }
  if (healthcheck.alcohol_intake){
    colours.push(getAlcoholIntakeColour(healthcheck.alcohol_intake));
  }
  if (healthcheck.sleep_quality){
    colours.push(getSleepQualityColour(healthcheck.sleep_quality));
  }
  if (healthcheck.diet_quality){
    colours.push(getDietQualityColour(healthcheck.diet_quality));
  }
  if (healthcheck.smoking_level){
    colours.push(getSmokingLevelColour(healthcheck.smoking_level));  
  }
  if (healthcheck.stress_level){
    colours.push(getStressLevelColour(healthcheck.stress_level));  
  }
  



  let numbers = { success: 0, warning: 0, danger: 0 };
  for (let i = 0; i < colours.length; i++) {
    numbers[colours[i]] = numbers[colours[i]] + 1;
  }
  return {
    green: numbers["success"],
    orange: numbers["warning"],
    red: numbers["danger"]
  };
}

export function getBloodGlucoseCardColour(value) {
  if ((2.6 <= value && value < 4.3) || (8.1 <= value && value <= 21.1)) {
    return red;
  } else if (6.3 <= value && value < 8.1) {
    return orange;
  } else {
    return green;
  }
}

export function getBloodPressureCardColour(valueSYS, valueDYS) {
  if (
    (10 <= valueSYS && valueSYS < 90 && 10 <= valueDYS && valueDYS < 60) ||
    (120 < valueSYS && valueSYS <= 200 && 80 < valueDYS && valueDYS <= 200)
  ) {
    return red;
  } else {
    return green;
  }
}

export function getHeartRateCardColour(value) {
  if ((20 <= value && value <= 40) || (75 <= value && value <= 120)) {
    return red;
  } else if (60 <= value && value < 75) {
    return orange;
  } else {
    return green;
  }
}

export function getOxygenLevelCardColour(value) {
  if (value < 95) {
    return red;
  } else if (93 <= value && value < 94) {
    return orange;
  } else {
    return green;
  }
}

export function getFootPostureIndexCardColour(value) {
  if ((-5 < value && value < 0) || (6 <= value && value < 10)) {
    return red;
  } else {
    return green;
  }
}

export function getCholesterolLevelCardColour(value) {
  if (5 < value && value <= 10) {
    return red;
  } else if (5 <= value && value < 6.5) {
    return orange;
  } else {
    return green;
  }
}

export function getBodyMassIndexCardColour(value) {
  if ((10 <= value && value <= 18.5) || (25 <= value && value <= 200)) {
    return red;
  } else if (18.5 < value && value < 25) {
    return green;
  } else {
    return orange;
  }
}

export function getBodyTemperatureCardColour(value) {
  if (37.5 < value || value < 36.5) {
    return red;
  } else {
    return green;
  }
}

export function getAirwayPeakFlowColour(value) {
  if (value < 400) {
    return red;
  } else if (450 <= value && value < 650) {
    return orange;
  } else {
    return green;
  }
}

export function getBeepTestColour(value) {
  if (1 <= value && value <= 7) {
    return red;
  } else if (7 < value && value <= 11) {
    return orange;
  } else {
    return green;
  }
}

export function getVerticalJumpColour(value) {
  if (1 <= value && value <= 12) {
    return red;
  } else if (12 < value && value <= 24) {
    return orange;
  } else {
    return green;
  }
}

export function getHundredMeterSprintColour(value) {
  if (14 <= value && value <= 20) {
    return red;
  } else if (12 <= value && value < 14) {
    return orange;
  } else {
    return green;
  }
}

export function getReactionTimeColour(value) {
  if (value > 0.2) {
    return red;
  } else if (0.14 < value && value <= 0.2) {
    return orange;
  } else {
    return green;
  }
}

export function getAlcoholIntakeColour(value) {
  if (value > 14) {
    return red;
  } else if (0.1 <= value && value <= 14) {
    return orange;
  } else {
    return green;
  }
}

export function getSleepQualityColour(value) {
  if (value >= 6) {
    return green;
  } else {
    return red;
  }
}

export function getDietQualityColour(value) {
  if (value <= 5) {
    return red;
  } else {
    return green;
  }
}

export function getSmokingLevelColour(value) {
  if (value == 1) {
    return red;
  } else {
    return green;
  }
}

export function getStressLevelColour(value) {
  if (value >= 5) {
    return red;
  } else {
    return green;
  }
}
